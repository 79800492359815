<span *ngIf="withImage">
  <div class="row-simple-button-image">
    <simple-button-image *ngFor="let paymentMethod of paymentMethods"
      (buttonClick)="onPaymentMethodClick(paymentMethod)" [imageURL]="getImageUrl(paymentMethod)"
      [providerName]="getProviderName(paymentMethod)" [iconClass]="getImageClass(paymentMethod)">
    </simple-button-image>
  </div>
</span>
<span *ngIf="!withImage">
  <div class="row-simple-button">
    <simple-button *ngFor="let paymentMethod of paymentMethods" [enabled]="true" [text]="getButtonText(paymentMethod)"
      [cssClass]="getSimpleButtonCSSClass(paymentMethod)" (onClick)="onPaymentMethodClick(paymentMethod)"
      [iconClass]="showIcons? getIconClass(paymentMethod): ''" class="payment-button">
    </simple-button>
  </div>
</span>