import { Injectable } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { AdditionalPropertiesConfigurationService } from '../../configuration/additional-properties-configuration.service';
import { ConfigurationService } from '../../configuration/configuration.service';
import { LoggingService } from '../../logging/logging.service';

@Injectable()
export class ModalService {

  private modals: Map<BsModalRef, any> = new Map<BsModalRef, any>();

  constructor(
    private modalService: BsModalService,
    private loggingService: LoggingService,
    private configurationService: ConfigurationService,
    private additionalPropertiesConfigurationService: AdditionalPropertiesConfigurationService,
  ) {
  }

  public show(baseModalComponent: any, data: any, complete: (data: any) => void): BsModalRef {
    const extraClassName = this.additionalPropertiesConfigurationService.isLiteMode ? 'modal-lite' : '';
    let modalRef = this.modalService.show(
      baseModalComponent,
      {
        animated: false,
        keyboard: false,
        backdrop: true,
        ignoreBackdropClick: true,
        class: `${data?.modalClass || ''} ${this.configurationService?.vuType || ''} ${extraClassName}`,
      });

    const modalData = {
      timeout: null,
    };
    if (data && data.autoCloseTimeout) {
      modalData.timeout = setTimeout(() => {
        this.close(modalRef);
      }, data.autoCloseTimeout);
    }

    this.modals.set(modalRef, modalData);

    this.logModalChangeState(modalRef, 'show');
    const content = modalRef.content as any;

    content.data = data;

    const eventCloseSubscription = content.eventClose.subscribe(
      (eventData) => {
        this.logModalChangeState(modalRef, 'eventClose');
        eventCloseSubscription.unsubscribe();
        if (modalRef) {
          this.modals.delete(modalRef);
          modalRef.hide();
          modalRef = null;
        }
        if (complete) {
          complete(eventData);
        }
      });

    return modalRef;
  }

  public close(modalRef: BsModalRef, params = null): void {
    if (modalRef && modalRef.content) {
      setTimeout(() => {
        this.logModalChangeState(modalRef, 'close');
        modalRef.content.close(params);
      }, 0);
    }
  }

  public closeAll(): void {
    if (this.modals.size === 0) {
      return;
    }

    this.loggingService.info(`ModalService. closeAll. Modal count: ${this.modals.size}`);

    for (const modal of this.modals.keys()) {
      this.close(modal);
    }

    this.modals.clear();
  }

  private logModalChangeState(modalRef: BsModalRef, state: string): void {
    if (!modalRef || !modalRef.content || !modalRef.content.constructor || !modalRef.content.constructor.name) {
      return;
    }

    this.loggingService.info(`ModalService. ${state}. Modal name: ${modalRef.content.constructor.name}`);
  }
}
