export class BaseInfo {
  static createFromAnyArray(array: any): any[] {
    if (!array) {
      return [];
    }
    return array.map(this.createFromAny).filter(item => item != null);
  }

  static createFromAny(object: any): any {
    throw new Error("Method not implemented.");
  }
}
