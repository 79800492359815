import { OrderLineInfo } from './order-line-info';
import { BaseInfo } from './base-info';
import { Money, Product } from 'src/app/lib/lib';

export class OrderInfo extends BaseInfo {
  id: number;
  name: string;
  orderLinesInfo: OrderLineInfo[];
  date: Date;
  cardPaymentAmount: number;

  static createFromAny(object: any): OrderInfo | null {
    if (object === null || object === undefined) {
      return null;
    }
    const order = new OrderInfo();
    order.id = object.id;
    order.name = object.name;
    order.orderLinesInfo = OrderLineInfo.createFromAnyArray(object.lines);
    order.date = object.date ? new Date(object.date) : null;
    order.cardPaymentAmount = object.card_payment_amount;
    return order;
  }

  get amountTotal(): Money {
    return this.orderLinesInfo.reduce((sum, current) => sum.add(current.totalAmount), Money.empty);
  }

  addLine(product: Product, customPrice: Money = null) {
    if (this.orderLinesInfo == null) {
      this.orderLinesInfo = [];
    }
    const orderLineInfo = new OrderLineInfo();
    orderLineInfo.price = customPrice != null ? customPrice : product?.price;
    orderLineInfo.productId = product?.id || 0;
    orderLineInfo.productName = product?.name || '';
    orderLineInfo.quantity = 1;1
    this.orderLinesInfo.push(orderLineInfo);
  }
}
