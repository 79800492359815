import { Component, OnInit, OnDestroy, ViewEncapsulation } from '@angular/core';
import { Subscription } from 'rxjs';
import { VuRole, Configuration } from '../../../lib/lib';
import { ConfigurationService } from '../../../services/configuration/configuration.service';
import { DispatcherService } from '../../../services/dispatcher.service';

@Component({
  moduleId: module.id,
  selector: 'vu-role-simulator',
  templateUrl: 'vu-role-simulator.component.html',
  styleUrls: ['vu-role-simulator.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class VuRoleSimulatorComponent implements OnInit, OnDestroy {
  private subscriptionConfigurationChanged: Subscription;
  configuration = new Configuration();

  constructor(
    protected configurationService: ConfigurationService,
    private dispatcherService: DispatcherService,
  ) {
  }

  ngOnInit() {
    this.subscriptionConfigurationChanged = this.dispatcherService
      .onConfigurationChangedSubscribe((x: Configuration) => this.configuration = Configuration.fromOther(x));

  }

  ngOnDestroy() {
    this.subscriptionConfigurationChanged.unsubscribe();
  }

  onVuRoleChange(event: any) {
    const role = this.configuration.vuRole;
    this.dispatcherService.configurationVuRole(role);
  }

  get vuRoles(): VuRole[] {
    const roles: VuRole[] = [];
    for (const r in VuRole) {
      if (VuRole.hasOwnProperty(r)) {
        roles.push(r as VuRole);
      }
    }
    return roles;
  }
}
