import { Injectable } from '@angular/core';
import { ExternalBaseService } from '../../external-base/services/external-base.service';

@Injectable()
export class ExternalUseService extends ExternalBaseService {

  name: string;

  disableRedirect: boolean;
  cancelOperationURL: string;
  completeOperationURL: string;

  completeOrderId: number;

  inactivityMode: boolean;

  navigateToBasket: () => void;

  protected internalActivate(): void {
  }

  get isEnabled(): boolean {
    return super.isEnabled || window.location.href.includes('store/external-use');
  }

}
