<div *ngIf="!isProduction" class="container entire-width simulator">
  <div class="row">
    <div class="col-lg-12" style="overflow: auto; overflow-y: hidden;">
      <mat-tab-group [selectedIndex]="tabSelectedIndex" (selectedTabChange)="onTabChanged($event)">
        <mat-tab>
          <ng-template mat-tab-label>
            <i [attr.class]="'fa fa-home'"></i>
            <span *ngIf="!shortTab">&nbsp; {{'Home'| translate }}</span>
          </ng-template>
          <div class="tab-content">
            <languages></languages>
            <vu-role-simulator></vu-role-simulator>
            <a type="button" class="btn btn-default" (click)="onResetMachinesClick()">{{ 'Reset Machines' | translate
              }}</a>

            <!-- <bSwitch switch-on-color='warning' switch-on-text="{{'No Cash'| translate }}" switch-handle-width="110" switch-off-text="{{'No Cash'| translate }}"
                            [(ngModel)]="cannotPayCash" (onChangeState)="onCanPayCashChange($event)">
                        </bSwitch> -->
            {{'Tab label'| translate }}:
            <bSwitch switch-on-color='warning' switch-on-text="{{'Hide'| translate }}" switch-handle-width="110"
              switch-off-text="{{'Show'| translate }}" [(ngModel)]="shortTab">
            </bSwitch>
          </div>
        </mat-tab>
        <mat-tab>
          <ng-template mat-tab-label>
            <i [attr.class]="'fa fa-money'"></i>
            <span *ngIf="!shortTab">&nbsp; {{'Payment'| translate }}</span>
          </ng-template>
          <div class="tab-content">
            {{'Pay in'| translate }}:
            <cash-simulator [isEnabled]='cashDevicesState.isCashPayIn'></cash-simulator>
            {{'Pay out'| translate }}:
            <cash-simulator [isEnabled]='cashDevicesState.isCashPayOut' [isPayout]="true"></cash-simulator>
            <span>{{'Paid out'| translate }}: </span>
            <a type="button" class="btn btn-default" [ngClass]="{disabled : !cashDevicesState.isCashPayOut}"
              (click)="onPaidoutClick()">{{ 'Money' | translate }}</a>
            <a type="button" class="btn btn-default" [ngClass]="{disabled : !cashDevicesState.isCashPayOut}"
              (click)="onReturnChangeFinishedClick()">{{ 'Change' |translate }}</a>
            <span>{{'BNA Validation'| translate }}: </span>
            <a type="button" class="btn btn-default" (click)="onBnaValidationStartedClick()">
              {{ 'BNA Validation Started' | translate }}</a>
            <a type="button" class="btn btn-default" (click)="onBnaValidationFinishedClick()">
              {{ 'BNA Validation Finished' | translate }}</a>
          </div>
        </mat-tab>
        <mat-tab>
          <ng-template mat-tab-label>
            <i [attr.class]="'fa fa-credit-card'"></i>
            <span *ngIf="!shortTab">&nbsp; {{'Card terminal'| translate }}</span>
          </ng-template>
          <div class="tab-content">
            <app-credit-card-terminal-simulator></app-credit-card-terminal-simulator>
          </div>
        </mat-tab>
        <mat-tab>
          <ng-template mat-tab-label>
            <i [attr.class]="'fa fa-file-text-o'"></i>
            <span *ngIf="!shortTab">&nbsp; {{'Order'| translate }}</span>
          </ng-template>
          <div class="tab-content">
            <app-order-simulator></app-order-simulator>
          </div>
        </mat-tab>
        <mat-tab>
          <ng-template mat-tab-label>
            <i [attr.class]="'fa fa-print'"></i>
            <span *ngIf="!shortTab">&nbsp; {{'Printer'| translate }}</span>
          </ng-template>
          <div class="tab-content">
            <app-printer-simulator></app-printer-simulator>
          </div>
        </mat-tab>
        <mat-tab>
          <ng-template mat-tab-label>
            <i [attr.class]="'fa fa-barcode'"></i>
            <span *ngIf="!shortTab">&nbsp; {{'Barcode reader'| translate }}</span>
          </ng-template>
          <div class="tab-content">
            <app-barcode-reader-simulator></app-barcode-reader-simulator>
          </div>
        </mat-tab>
        <mat-tab label="{{'Tickets'| translate }}">
          <ng-template mat-tab-label>
            <i [attr.class]="'fa fa-ticket'"></i>
            <span *ngIf="!shortTab">&nbsp; {{'Tickets'| translate }}</span>
          </ng-template>
          <div class="tab-content">
            <app-ticket-simulator></app-ticket-simulator>
          </div>
        </mat-tab>
        <mat-tab>
          <ng-template mat-tab-label>
            <i [attr.class]="'fa fa-chain-broken'"></i>
            <span *ngIf="!shortTab">&nbsp; {{'Out of Order'| translate }}</span>
          </ng-template>
          <div class="tab-content">
            <bSwitch switch-on-color='danger' switch-on-text="{{'No Conn.'| translate }}"
              switch-off-text="{{'No Conn.'| translate }}" (onChangeState)="onNoConnectionChange($event)"
              switch-handle-width="110">
            </bSwitch>
            <bSwitch switch-on-color='danger' switch-on-text="{{'Burglary'| translate }}"
              switch-off-text="{{'Burglary'| translate }}" [(ngModel)]="vuState.isBurglaryMode"
              (onChangeState)="onBurglaryChange($event)" switch-handle-width="110">
            </bSwitch>
            <bSwitch switch-on-color='warning' switch-on-text="{{'Offline'| translate }}"
              switch-off-text="{{'Offline'| translate }}" [(ngModel)]="vuState.isOfflineMode"
              (onChangeState)="onOfflineModeChange($event)" switch-handle-width="110">
            </bSwitch>
            <bSwitch switch-on-color='danger' switch-on-text="{{'Failure'| translate }}"
              switch-off-text="{{'Failure'| translate }}" [(ngModel)]="vuState.isMaintenanceMode"
              (onChangeState)="onMaintenanceModeChange($event)" switch-handle-width="110">
            </bSwitch>
            <a type="button" class="btn btn-default" (click)="onServiceModeClick()">{{'Service Mode'| translate }}</a>
          </div>
        </mat-tab>
        <mat-tab>
          <ng-template mat-tab-label>
            <i [attr.class]="'fa fa-wrench'"></i>
            <span *ngIf="!shortTab">&nbsp; {{'Development'| translate }}</span>
          </ng-template>
          <div class="tab-content">
            <a type="button" class="btn btn-default" [ngClass]="{disabled : simulatorState.isTestRunning}"
              (click)="onTestStartClick()">{{ 'Start Test'| translate }}</a>
            <a type="button" class="btn btn-default" [ngClass]="{disabled : !simulatorState.isTestRunning}"
              (click)="onTestStopClick()">{{ 'Stop Test'| translate }}</a>
            <a type="button" class="btn btn-default" (click)="showTimeoutModal()">{{'Timeout'| translate }}</a>
            <bSwitch switch-on-color='warning' switch-on-text="{{'Production API'| translate }}"
              switch-off-text="{{'Production API'| translate }}" (onChangeState)="onProductionApiChange($event)"
              switch-handle-width="130">
            </bSwitch>
            <bSwitch switch-on-color='warning' switch-on-text="{{'Background'| translate }}"
              switch-off-text="{{'Background'| translate }}" [(ngModel)]="showDemoBackground" switch-handle-width="130">
            </bSwitch>
            <bSwitch switch-on-color='warning' switch-on-text="{{'Lite mode'| translate }}"
              switch-off-text="{{'Lite mode'| translate }}" [(ngModel)]="liteMode" switch-handle-width="130">
            </bSwitch>
            <bSwitch switch-on-color='warning' switch-on-text="{{'Demo mode'| translate }}"
              switch-off-text="{{'Demo mode'| translate }}" [(ngModel)]="demoMode" switch-handle-width="130">
            </bSwitch>
          </div>
        </mat-tab>
        <mat-tab>
          <ng-template mat-tab-label>
            <i [attr.class]="'fa fa-picture-o'"></i>
            <span *ngIf="!shortTab">&nbsp; {{'Screen saver'| translate }}</span>
          </ng-template>
          <div class="tab-content">
            <app-screen-saver-simulator></app-screen-saver-simulator>
          </div>
        </mat-tab>
        <mat-tab>
          <ng-template mat-tab-label>
            <i [attr.class]="'fa fa-exchange'"></i>
            <span *ngIf="!shortTab">&nbsp; {{'Money Exchange'| translate }}</span>
          </ng-template>
          <div class="tab-content">
            <app-money-exchange-simulator></app-money-exchange-simulator>
          </div>
        </mat-tab>
        <mat-tab>
          <ng-template mat-tab-label>
            <i [attr.class]="'fa fa-address-card-o'"></i>
            <span *ngIf="!shortTab">&nbsp; {{'Card Dispenser'| translate }}</span>
          </ng-template>
          <div class="tab-content">
            <app-card-dispenser-simulator></app-card-dispenser-simulator>
          </div>
        </mat-tab>
        <mat-tab>
          <ng-template mat-tab-label>
            <i [attr.class]="'fa fa-xing'"></i>
            <span *ngIf="!shortTab">&nbsp; {{'Turnstile'| translate }}</span>
          </ng-template>
          <div class="tab-content">
            <app-turnstile-simulator></app-turnstile-simulator>
          </div>
        </mat-tab>
        <mat-tab>
          <ng-template mat-tab-label>
            <i [attr.class]="'fa fa-table'"></i>
            <span *ngIf="!shortTab">&nbsp; {{'Touch Tile'| translate }}</span>
          </ng-template>
          <div class="tab-content">
            <app-touch-tile-simulator></app-touch-tile-simulator>
          </div>
        </mat-tab>
        <mat-tab>
          <ng-template mat-tab-label>
            <i [attr.class]="'fa fa-podcast'"></i>
            <span *ngIf="!shortTab">&nbsp; {{'Rfid'| translate }}</span>
          </ng-template>
          <div class="tab-content">
            <app-rfid-simulator></app-rfid-simulator>
          </div>
        </mat-tab>
        <mat-tab>
          <ng-template mat-tab-label>
            <i [attr.class]="'fa fa-braille'"></i>
            <span *ngIf="!shortTab">&nbsp; {{'Display Configuration'| translate }}</span>
          </ng-template>
          <div class="tab-content">
            <app-display-configuration-simulator></app-display-configuration-simulator>
          </div>
        </mat-tab>
        <mat-tab>
          <ng-template mat-tab-label>
            <i [attr.class]="'fa fa-road'"></i>
            <span *ngIf="!shortTab">&nbsp; {{'Gate'| translate }}</span>
          </ng-template>
          <div class="tab-content">
            <app-gate-simulator></app-gate-simulator>
          </div>
        </mat-tab>
        <mat-tab label="{{'Theme' | translate }}">
          <ng-template mat-tab-label>
            <i [attr.class]="'fa fa-paint-brush'"></i>
            <span *ngIf="!shortTab">&nbsp; {{'Theme'| translate }}</span>
          </ng-template>
          <div class="tab-content">
            <app-custom-css-simulator></app-custom-css-simulator>
            <app-logo-update-simulator></app-logo-update-simulator>
          </div>
        </mat-tab>
        <mat-tab>
          <ng-template mat-tab-label>
            <i [attr.class]="'fa fa-building'"></i>
            <span *ngIf="!shortTab">&nbsp; {{'Logistics Request'| translate }}</span>
          </ng-template>
          <div class="tab-content">
            <app-logistics-request-simulator></app-logistics-request-simulator>
          </div>
        </mat-tab>
        <mat-tab>
          <ng-template mat-tab-label>
            <i [attr.class]="'fa fa-stack-overflow'"></i>
            <span *ngIf="!shortTab">&nbsp; {{'Workflow'| translate }}</span>
          </ng-template>
          <div class="tab-content">
            <app-workflow-simulator></app-workflow-simulator>
          </div>
        </mat-tab>
        <mat-tab>
          <ng-template mat-tab-label>
            <i [attr.class]="'fa fa-clock-o'"></i>
            <span *ngIf="!shortTab">&nbsp; {{'Notification'| translate }}</span>
          </ng-template>
          <div class="tab-content">
            <app-notification-message-simulator></app-notification-message-simulator>
          </div>
        </mat-tab>
        <mat-tab>
          <ng-template mat-tab-label>
            <i [attr.class]="'fa fa-th'"></i>
            <span *ngIf="!shortTab">&nbsp; {{'CIOBoard Events'| translate }}</span>
          </ng-template>
          <div class="tab-content">
            <app-cioboard-event-simulator></app-cioboard-event-simulator>
          </div>
        </mat-tab>
      </mat-tab-group>
    </div>
  </div>
</div>
