<div class="modal-header" align="center">
  <div class="modal-title normal-text" >{{ 'Choose a payment method' | translate }}</div>
</div>
<div class="modal-body" align="center">
  <div>
    <app-payment-buttons (paymentClick)="onPaymentClick($event)" [withImage]="true" [showIcons]="false"></app-payment-buttons>
  </div>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-warning pull-right button-base button-medium" (click)="close(false)">
    {{ 'Back' | translate }}
  </button>
</div>
