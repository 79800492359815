import { CardInformation } from 'src/app/modules/recharge-card/models/card-information';
import { Product } from '../product';
import { TicketParameters } from '../ticket/ticket-parameters';

export class ProductValidationInfo {
  product: Product;
  additionalProducts: Product[];
  barcode: string;
  ticketParameters: TicketParameters;
  goBack: boolean;
  cardInformation: CardInformation;

  static createGoBack(): ProductValidationInfo {
    const productValidationInfo = new ProductValidationInfo();
    productValidationInfo.goBack = true;
    return productValidationInfo;
  }

  get priceListId(): number {
    return this.cardInformation?.priceListId || 0;
  }
}
