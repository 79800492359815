import { Injectable } from '@angular/core';
import { MessageModalService } from '../../message-modal/services/message-modal.service';


@Injectable()
export class RfidCardDialogueService {

  constructor(
    private messageModalService: MessageModalService,
  ) {
  }

  public showFailedScanRfidCardDialogue(): void {
    this.messageModalService.showError('Card is not registered in the system. Please scan another one');
  }

  public closeMessageDialogue(): void {
    this.messageModalService.close();
  }
}
