import { CashlessPaymentProviderEventType } from './cashless-payment-provider-event-type';

export class CashlessPaymentProviderEvent {
  eventType: CashlessPaymentProviderEventType;
  eventInfo: string;
  // TODO payment provider

  constructor(eventType: CashlessPaymentProviderEventType, eventInfo: string) {
    this.eventType = eventType;
    this.eventInfo = eventInfo;
  }

  static fromOther(x: CashlessPaymentProviderEvent): CashlessPaymentProviderEvent {
    return new CashlessPaymentProviderEvent(x.eventType, x.eventInfo);
  }

  toString(): string {
    return `${CashlessPaymentProviderEventType[this.eventType]} '${this.eventInfo == null ? '' : this.eventInfo}'`;
  }
}
