import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  moduleId: module.id,
  selector: 'simple-button',
  templateUrl: 'simple-button.component.html',
  styleUrls: ['simple-button.component.scss']
})
export class SimpleButtonComponent {
  @Input() text: string;
  @Input() texts: string[];
  @Input() cssClass = 'btn btn-success button-base back-button';
  @Output() onClick: EventEmitter<any> = new EventEmitter<any>();
  @Input() enabled = true;
  @Input() iconClass = '';

  onButtonClick(): void {
    if (this.enabled) {
      this.onClick.emit();
    }
  }
}
