import { MachineBaseService } from '../machine-base.service';
import { SaleService } from '../../sale.service';
import { MachineLitePostSaleService } from './machine-lite-post-sale.service';
import { Money, Product, MachineInactivitySettings, PaymentMethod } from '../../../lib/lib';
import { MessageType, Message } from '../../message.service';
import { LiteDisplayService } from '../../lite/lite-display.service';
import { LiteTouchTileService } from '../../lite/lite-touch-tile.service';
import { Injectable } from '@angular/core';
import { MachineSaleCashlessService } from '../machine-sale-cashless.service';
import { CashlessTransactionResult } from 'src/app/lib/payment/cashless-payment-transaction-result';
import { CashlessPaymentData } from 'src/app/lib/payment/cashless-payment-data';
import { ModalService } from '../../gui/modal/modal-service';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { PaymentDialogModalComponent } from 'src/app/modules/payment-dialog/components/payment-dialog-component/payment-dialog-component.component';
import { PrintReceiptEnum } from 'src/app/lib/printer/print-receipt-enum';
import { CreditCardTerminalEvent } from 'src/app/lib/credit-card/credit-card-terminal-event';
import { CreditCardTerminalEventType } from 'src/app/lib/credit-card/credit-card-terminal-event-type';
import { Subscription } from 'rxjs';

@Injectable()
export class MachineLiteSaleService extends MachineBaseService {

  private isStopRequested = false;
  private saleService: SaleService;
  private machineLitePostSaleService: MachineLitePostSaleService;
  private product: Product;
  private additionalProducts: Product[];
  private liteDisplayService: LiteDisplayService;
  private liteTouchTileService: LiteTouchTileService;
  private machineSaleCashlessService: MachineSaleCashlessService;
  private modalService: ModalService;
  private modalRef: BsModalRef;

  init(): void {
    this.saleService = this.injector.get(SaleService);
    this.modalService = this.injector.get(ModalService);
    this.liteDisplayService = this.injector.get(LiteDisplayService);
    this.machineLitePostSaleService = this.injector.get(MachineLitePostSaleService);
    this.machineLitePostSaleService.init();
    this.machineLitePostSaleService.eventSwitchedOff.subscribe(() => this.onMachinePostSaleSwitchedOff());
    this.machineSaleCashlessService = this.injector.get(MachineSaleCashlessService);
    this.machineSaleCashlessService.eventSwitchedOff.subscribe(() => this.onMachineSaleCashlessServiceSwitchedOff());
    this.machineSaleCashlessService.evenCashlessTransactionEnd.subscribe(
      (x: CashlessTransactionResult) => this.onEventCashlessTransactionEnd(x));
    super.init();
    this.reloadProduct();
    this.saleService.eventMoneyChanged.subscribe((x: Money) => this.onMoneyChanged(x));
    this.liteDisplayService.eventRightButtonClick.subscribe(() => this.onRightButtonClick());
    this.liteTouchTileService = this.injector.get(LiteTouchTileService);
    this.vuConnection.eventOnInvalidRfidCardInserted.subscribe(() => this.onInvalidRfidCardInserted());
    this.vuConnection.eventCreditCardTerminalEvent.subscribe(
      (x: CreditCardTerminalEvent) => this.onEventCreditCardTerminalEvent(x)
    );
    this.dispatcherService.onConfigurationChangedSubscribe((x) => this._ConfigurationChanged());
    this.vuConnection.eventSvgInvalidMoneyInserted.subscribe((x) => this.invalidMoneyInserted());
    this.vuConnection.eventAllCoinsNotAccepted.subscribe((x) => this.onAllCoinsNotAccepted());
  }

  get isLiteTouchTileMode(): boolean {
    return this.additionalPropertiesConfigurationService.isLiteTouchTileMode;
  }

  async reloadProduct() {
    this.product = await this.vuHttp.getLiteModeProduct();
    const subProductId = this.product.subProductId;
    if (subProductId) {
      this.additionalProducts = await this.vuHttp.getProductsByIds([subProductId]);
    }
  }

  onMachineSaleCashlessServiceSwitchedOff(): void {
  }

  onEventCashlessTransactionEnd(transactionResult: CashlessTransactionResult): void {
    if (!this.isInState('paymentCashless')) {
      return;
    }
    const scope = this;
    this.machineSaleCashlessService.switchOff(() => {
      switch (transactionResult) {
        case CashlessTransactionResult.Canceled:
          if (scope.state === 'paymentCashless') {
            scope.doAsync(() => scope.machine.toIdle(), 'onToIdle');
          }
          break;
        case CashlessTransactionResult.Completed:
          if (scope.state === 'paymentCashless') {
            scope.do(() => scope.router.navigateByUrl('/lite-mode'), 'onEventCashlessTransactionEnd');
            scope.machine.toPostSale();
          }
          break;
        default:
          scope.log.error(`onEventCashlessTransactionEnd.  CashlessTransactionResult not supported: '${transactionResult}'`);
      }
    });
  }

  get machineName(): string {
    return 'Lite Sale Machine';
  }

  protected getTransitions(): any[] {
    return super.getTransitions(
      { name: 'toIdle', from: ['off', 'paymentCash', 'paymentCashless', 'postSale', 'invalidRfidCardInserted'], to: 'idle' },
      { name: 'toPaymentCash', from: ['idle'], to: 'paymentCash' },
      { name: 'toPaymentCashless', from: ['idle'], to: 'paymentCashless' },
      { name: 'toInvalidRfidCardInserted', from: ['idle'], to: 'invalidRfidCardInserted' },
      { name: 'toPostSale', from: ['idle', 'paymentCash', 'paymentCashless'], to: 'postSale' },
    );
  }

  protected getMethods(): any {
    const scope = this;
    return super.getMethods({
      onToOff(event: any, isHardReset = false): void {
        scope.do(() => scope.saleService.closePaymentSession(), 'onToOff');
        scope.isStopRequested = false;
        scope.do(() => scope.liteDisplayService.stop(), 'onToOff');
        scope.hideAvailableCashPopup();
        scope.hideAllCoinsNotAccepted();
      },
      onToIdle(): void {
        scope.do(() => {
          scope.liteDisplayService.start();
          scope.resetOrder();
          scope.router.navigateByUrl('/lite-mode');
        }, 'onToIdle');
      },
      onToPaymentCash(): void {
        scope.do(() => {
          scope.liteDisplayService.activePaymentByCoins();
          if (scope.isLiteTouchTileMode) {
            scope.liteTouchTileService.coinsPayment();
          }
          scope.log.info(`MachineLiteSaleService. Pay Order: ${scope.saleService.order}`);
        }, 'onToPaymentCash')
      },
      onToPaymentCashless(isUseCardPayment: boolean = false): void {
        scope.do(() => {
          scope._selectPaymentMethod(isUseCardPayment)
            .then((payment: PaymentMethod) => {
              const callback = () => {
                scope.liteDisplayService.activePaymentByCard();
                if (scope.isLiteTouchTileMode) {
                  scope.liteTouchTileService.creditCardPayment();
                }
                scope.log.info(`MachineLiteSaleService. Pay Order: ${scope.saleService.order}`);
                scope.machineSaleCashlessService.paymentData = new CashlessPaymentData(payment);
                scope.machineSaleCashlessService.disableNavigation = true;
                scope.doAsync(() => scope.machineSaleCashlessService.machineStart(), 'onToPaymentCashless');
              };

              if (payment == null) {
                scope.do(() => scope.machine.toIdle(), 'onToIdle');
              } else {
                if (scope.saleService.remoteTransaction.isOpened && scope.saleService.order.paymentMethod !== payment) {
                  scope.saleService.order.paymentMethod = payment;
                  scope._closeRemoteTransaction(false, 'Canceling with incomming payment.', callback);
                } else {
                  callback();
                }
              };
            });
        }, 'onToPaymentCashless');
      },
      onToInvalidRfidCardInserted(): void {
        scope.do(() => {
          scope.liteDisplayService.rfidAccessDenied();
        }, 'onToInvalidRfidCardInserted');
        scope.do(() => {
          if (scope.isLiteTouchTileMode) {
            scope.liteTouchTileService.rfidAccessDenied();
          }
        }, 'onToInvalidRfidCardInserted');
      },
      onToPostSale(): void {
        scope.do(() => scope.dispatcherService.cashDevicesStatePayIn(false), 'onToPostSale');
        scope.do(() => scope.machineLitePostSaleService.machineStart(), 'onToPostSale');
      },
    });
  }

  machineStart(): void {
    if (this.isOff) {
      this.machine.toIdle();
    }
  }

  machineStop(): void {
    if (this.isOff) {
      return;
    }
    this.isStopRequested = true;
    this.machineTryToStop();
  }

  private async _ConfigurationChanged(): Promise<void> {
    const lastProduct = this.product;
    await this.reloadProduct();
    if (this.state === 'idle' && this.isProductChanged(lastProduct, this.product)) {
      await this._closeRemoteTransaction(false, 'Configuration changed', () => {
        this.resetOrder();
      });
    }
  }

  private isProductChanged(prevProduct: Product, newProduct: Product): boolean {
    if (prevProduct == null && newProduct == null) {
      return false;
    }

    if (prevProduct == null || newProduct == null) {
      return true;
    }

    return prevProduct.id != newProduct.id || prevProduct.price != newProduct.price;
  }

  private async resetOrder(): Promise<void> {
    this.saleService.resetOrder();
    if (!this.product) {
      return;
    }
    this.saleService.order.isReceipt = this.configurationService.configuration.printOrderReceipt === PrintReceiptEnum.ForcePrint;
    this.saleService.order.addProduct(this.product, 1);
    if (this.additionalProducts) {
      for (const product of this.additionalProducts) {
        this.saleService.order.addProduct(product, 1);
      }
    }
    const defaultPriceListId = this.configurationService.configuration.defaultPriceListId;
    if (defaultPriceListId) {
      await this.saleService.applyPriceList(defaultPriceListId);
    }
    const amountToPay = this.saleService.order.amountTotal;
    await this.saleService.openPaymentSession(PaymentMethod.Cash, amountToPay);
    this.liteDisplayService.reset();
    this.liteDisplayService.updateAmountToPay(amountToPay);
    if (this.isLiteTouchTileMode) {
      this.liteTouchTileService.standby();
    }
  }

  private _closeRemoteTransaction(isToCommit: boolean, context: string, revertPaymentTransactionCallback: any = null): Promise<void> {
    return this.saleService.remoteTransaction.closeRemoteTransaction(
      isToCommit,
      context,
      revertPaymentTransactionCallback == null ? null : revertPaymentTransactionCallback.bind(this)
    );
  }

  machineStopCancel(): void {
    this.isStopRequested = false;
  }

  private machineTryToStop(): void {
    if (this.isStopRequested && !this.isInState('paymentCash', 'postSale')) {
      this.machine.toOff();
    }
  }

  protected onAfterTransition(event: any): void {
    this.doAsync(() => this.machineTryToStop(), 'machineTryToStop');
    super.onAfterTransition(event);
  }

  onMachinePostSaleSwitchedOff(): void {
    if (this.state === 'postSale') {
      this.machine.toIdle();
    }
  }

  protected getMessages(): MessageType[] {
    return super.getMessages(
      MessageType.ButtonBackClicked,
      MessageType.Back,
      MessageType.ToPaymentCash,
      MessageType.ToPaymentCard,
    );
  }

  protected onMessage(message: Message): boolean {
    if (super.onMessage(message)) {
      return true;
    }

    const state = this.state;
    switch (message.messageType) {
      case MessageType.Back:
        this.machine.toOff();
        break;
      case MessageType.ButtonBackClicked:
        if (state === 'idle') {
          this.machine.toPaymentCash();
        } else if (state === 'paymentCash') {
          if (this.saleService.order.amountReceived.value === 0) {
            this.machine.toIdle();
          } else {
            this.saleService.paymentSession.isCancelled = true;
            this.machine.toPostSale();
          }
        } else if (state === 'paymentCashless') {
          this.machine.toIdle();
        }
        break;
      case MessageType.ToPaymentCash:
        this.machine.toPaymentCash();
        break;
      case MessageType.ToPaymentCard:
        this.machine.toPaymentCashless();
        break;
      default:
        break;
    }

    return false;
  }

  protected get timeoutTrackingStates(): string[] {
    return ['paymentCash', 'paymentCashless', 'invalidRfidCardInserted'];
  }

  private onMoneyChanged(money: Money): void {
    switch (this.state) {
      case 'idle':
        this.machine.toPaymentCash();
      case 'paymentCash':
        this.dispatcherService.onUserActivity();
        this.hideAvailableCashPopup();

        const paymentSession = this.saleService.paymentSession;
        if (!paymentSession) {
          this.log.error('MachineSaleShopService. !paymentSession');
          return;
        }

        this.liteDisplayService.updateAmountToPay(paymentSession.amountRemainingToPay);
        if (paymentSession.amountRemainingToPay.value <= 0) {
          this.machine.toPostSale();
        } else {
          this.liteDisplayService.showPayinCoins();
        }
        break;
      default:
        break;
    }
  }

  protected onMachineTimeoutModalCancel(machineName: string): void {
    if (machineName === this.machineName) {
      if (this.state === 'paymentCashless') {
        return;
      }
      if (this.state === 'paymentCash' || this.state === 'invalidRfidCardInserted') {
        if (this.saleService.order.amountReceived.value === 0) {
          this.machine.toIdle();
        } else {
          this.saleService.paymentSession.isCancelled = true;
          this.machine.toPostSale();
        }
        return;
      }
    }
    super.onMachineTimeoutModalCancel(machineName);
  }

  protected getMachineInactivitySettings(state: string): MachineInactivitySettings {
    switch (state) {
      case 'paymentCash':
        return this.getTimeoutSettings(this.additionalPropertiesConfigurationService.timeoutPaymentSec);
      case 'paymentCashless':
        return this.getTimeoutSettings(null);
      case 'invalidRfidCardInserted':
        return this.getTimeoutSettings(this.additionalPropertiesConfigurationService.timeoutInvalidRfidCardInserted);
      default:
        return super.getMachineInactivitySettings(state);
    }
  }

  private getTimeoutSettings(timeoutInSeconds: number): MachineInactivitySettings {
    return new MachineInactivitySettings(timeoutInSeconds * 1000, true);
  }

  onRightButtonClick(): void {
    if (this.state === 'idle') {
      this.machine.toPaymentCashless();
    }
  }

  onInvalidRfidCardInserted(): void {
    if (this.state === 'idle') {
      this.machine.toInvalidRfidCardInserted();
    }
  }

  private _selectPaymentMethod(isUseCardPayment: boolean = false): Promise<PaymentMethod> {
    return new Promise((resolve, reject) => {
      if (this.modalRef || this.liteDisplayService.availableCashlessPayments.length === 0) {
        return resolve(null);
      }

      if (isUseCardPayment) {
        if (this.isCardPaymentAvailable) {
          return resolve(PaymentMethod.PaymentCard);
        } else {
          return resolve(null);
        }
      }

      if (this.liteDisplayService.availableCashlessPayments.length === 1) {
        return resolve(this.liteDisplayService.availableCashlessPayments[0]);
      }

      this.modalRef = this.modalService.show(
        PaymentDialogModalComponent,
        {},
        (payment) => {
          this.modalRef = null;
          this.dispatcherService.onUserActivity();
          if (!payment) {
            return resolve(null);
          }
          return resolve(payment);
        }
      );
    });
  }

  onEventCreditCardTerminalEvent(event: CreditCardTerminalEvent): void {
    switch (this.state) {
      case 'idle':
        if (this.isCardPaymentAvailable && event?.eventType === CreditCardTerminalEventType.CardInserted) {
          this.machine.toPaymentCashless(true);
        }
        break;
      default:
        break;
    }
  }

  get isCardPaymentAvailable(): boolean {
    return this.liteDisplayService.availableCashlessPayments?.some((payment: PaymentMethod) => payment === PaymentMethod.PaymentCard);
  }

  private invalidMoneyInserted(): void {
    if (this.state !== 'idle' && this.state !== 'paymentCash') {
      return;
    }

    this.dispatcherService.onUserActivity();
    this.showAvailableCashPopup();
  }

  private onAllCoinsNotAccepted(): void {
    if (this.state !== 'idle' && this.state !== 'paymentCash') {
      return;
    } this.dispatcherService.onUserActivity();
    this.showAllCoinsNotAccepted();
  }

  private showAvailableCashPopup(): void {
    this.liteDisplayService.showAvailableCashPopup();
  }

  private hideAvailableCashPopup(): void {
    this.liteDisplayService.hideAvailableCashPopup();
  }

  private showAllCoinsNotAccepted(): void {
    this.liteDisplayService.showAllCoinsNotAccepted();
  }

  private hideAllCoinsNotAccepted(): void {
    this.liteDisplayService.hideAllCoinsNotAccepted();
  }
}
