import { Injectable } from '@angular/core';
import { from, Observable, of } from 'rxjs';
import { switchMap, catchError, map } from 'rxjs/operators';
import { ConfigurationService } from 'src/app/services/configuration/configuration.service';
import { VuCommunicationService } from 'src/app/services/vu/vu-communication.service';
import { CardInformation } from '../../recharge-card/models/card-information';

@Injectable()
export class CardDispenserRestoreCardService {

  constructor(
    private configurationService: ConfigurationService,
    private vuCommunicationService: VuCommunicationService,
  ) { }

  checkIsManagerCard(managerCardNumber: string): Observable<boolean> {
    return of(true).pipe(
      switchMap(() => {
        const requestValues = new Map<string, any>();
        requestValues.set('cardNumber', managerCardNumber);

        const paymentControllerUrl = this.configurationService.configuration.odooUrl + '/api/card/validate-manager-card';

        return from(this.vuCommunicationService.ExternalApiService.sendPostRequestOdooJson(paymentControllerUrl, requestValues))
          .pipe(
            catchError(error => {
              return of(false);
            }),
            map(result => {
              return !!result;
            }),
          );
      }),
    );
  }

  getRestoreCardInformation(restoreCardNumber: string, managerCardNumber: string): Observable<CardInformation> {
    return of(true).pipe(
      switchMap(() => {
        const requestValues = new Map<string, any>();
        requestValues.set('cardNumber', restoreCardNumber);
        requestValues.set('managerCardNumber', managerCardNumber);
        requestValues.set('is_card', true);

        const paymentControllerUrl = this.configurationService.configuration.odooUrl + '/api/card/information';

        return from(this.vuCommunicationService.ExternalApiService.sendPostRequestOdooJson(paymentControllerUrl, requestValues))
          .pipe(
            catchError(error => {
              return of(false);
            }),
            map(result => {
              if (!result || !result.name) {
                return null;
              }
              return CardInformation.createFromAny(result);
            }),
          );
      }),
    );
  }
}
