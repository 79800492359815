import { Component, EventEmitter, OnDestroy, OnInit } from '@angular/core';
import { MachineSaleShopService } from '../../../services/machines/machine-sale.service';
import { ConfigurationService } from '../../../services/configuration/configuration.service';
import { StoreHistoryService } from 'src/app/services/store-history.service';
import { DispatcherService } from 'src/app/services/dispatcher.service';
import { switchMap, tap } from 'rxjs/operators';
import { merge, of, Subscription } from 'rxjs';
import { BarcodeReaderService } from 'src/app/services/barcode/barcode-reader.service';
import { CustomShopInformation } from 'src/app/lib/custom-shop/custom-shop.information';

@Component({
  selector: 'app-scan-gift-card-qr-code',
  templateUrl: './scan-gift-card-qr-code.component.html',
  styleUrls: ['./scan-gift-card-qr-code.component.css'],
})
export class ScanGiftCardQRCodeComponent implements OnInit, OnDestroy {

  private isBarcodeLedEnable = false;
  private scanCodeSubscriptions: Subscription;
  private configurationChangedSubscription: Subscription;

  constructor(
    private dispatcherService: DispatcherService,
    private configurationService: ConfigurationService,
    private machineSaleShopService: MachineSaleShopService,
    private storeHistoryService: StoreHistoryService,
    private barcodeReaderService: BarcodeReaderService
  ) {
  }

  ngOnInit(): void {

    const configurationChanged = new EventEmitter<string>();

    this.configurationChangedSubscription = this.dispatcherService.onConfigurationChangedSubscribe(() => {
      configurationChanged.emit();
    });

    this.scanCodeSubscriptions = merge(
      this.machineSaleShopService.eventStateChanged,
      configurationChanged
    ).pipe(
      switchMap(_ => {

        if (!this.isBarcodeLedEnable && this.visible) {
          this.barcodeLedEnable(true);
        }

        if (this.isBarcodeLedEnable && !this.visible) {
          this.barcodeLedEnable(false);
        }

        if (this.visible) {
          return this.barcodeReaderService.barcodeScanned
            .pipe(
              tap(code => {
                this.dispatcherService.onButtonCustomSaleShopClick(
                  new CustomShopInformation(`/gift-card-qr-code?code=${code}`, `/gift-card-qr-code/restore`));
              }),
            );
        }

        return of(_);
      }),
    ).subscribe();
  }

  ngOnDestroy(): void {
    if (this.scanCodeSubscriptions != null) {
      this.scanCodeSubscriptions.unsubscribe();
      this.scanCodeSubscriptions = null;
    }

    if (this.configurationChangedSubscription != null) {
      this.configurationChangedSubscription.unsubscribe();
      this.configurationChangedSubscription = null;
    }
  }

  barcodeLedEnable(value: boolean): void {
    this.isBarcodeLedEnable = value;
    this.barcodeReaderService.barcodeLedEnable(value);
  }

  get isBarcodeReaderInMinConfiguration(): boolean {
    return this.configurationService?.configuration?.originalConfigurationHelper?.getPropertyHelper('minimum_configuration')?.getNumber('count_of_barcode_readers') !== 0;
  }

  get visible(): boolean {
    const state = this.machineSaleShopService.state;
    return state && state === 'idle'
      && this.storeHistoryService.isEmpty
      && this.isBarcodeReaderInMinConfiguration
      && this.configurationService.configuration.rfidCardUpgradeProduct > 0;
  }
}
