import { PrintTaskType } from './print-task-type';

export class PrintTask {
  orderId: number;
  printTaskType: PrintTaskType;
  content: string;
  templateUniqueName: string;

  static fromOther(other: PrintTask): PrintTask {
    const task = new PrintTask(other.orderId, other.printTaskType, other.content);
    task.templateUniqueName = other.templateUniqueName;
    return task;
  }

  constructor(
    orderId: number,
    printTaskType: PrintTaskType,
    content?: string
  ) {
    this.orderId = orderId;
    this.printTaskType = printTaskType;
    this.content = content;
  }

  toString(): string {
    let parts = [];

    if (this.orderId > 0) {
      parts.push(`orderId: '${this.orderId}'.`);
    }

    parts.push(`printTaskType: '${this.printTaskType}'.`);

    if (this.content) {
      parts.push(`content: '${this.content}'.`);
    }

    if (this.templateUniqueName) {
      parts.push(`. templateUniqueName: '${this.templateUniqueName}'.`);
    }

    return parts.join(' ');
  }
}
