import { Injectable } from '@angular/core';

import { DisplayItemTypeEnum } from '../../../../../lib/display/display-item/display-item-type.enum';
import { DisplayItem } from '../../../../../lib/display/display-item/display-item';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { PrintModalComponent } from '../../../../../components/print-modal/print-modal.component';
import { BaseGridWorkflowService } from '../base-grid-workflow.service';
import { PrintTaskResult } from '../../../../../lib/lib';
import { ModalService } from '../../../../gui/modal/modal-service';
import { LanguageService } from '../../../../language.service';

@Injectable()
export class PrintGridWorkflowService extends BaseGridWorkflowService {

  private modalRef: BsModalRef;

  private bsModalService: BsModalService;
  private modalService: ModalService;
  private languageService: LanguageService;

  init(): void {
    super.init();
    this.vuCommunicationService.vuConnection.eventPrintTaskResultReceived.subscribe(
      (x: PrintTaskResult) => this.onPrintTaskResultReceived(x));
    this.bsModalService = this.injector.get(BsModalService);
    this.modalService = this.injector.get(ModalService);
    this.languageService = this.injector.get(LanguageService);
  }

  get displayItemType(): DisplayItemTypeEnum {
    return DisplayItemTypeEnum.Print;
  }

  private onPrintTaskResultReceived(result: PrintTaskResult): void {
    if (!this.displayItem) {
      return;
    }

    this.userActivity();
    if (result.isSuccess) {
      this.closeDialog();
    } else {
      this.showError();
    }
  }

  private closeDialog(): void {
    this.modalService.close(this.modalRef);
  }

  private showError(): void {
    if (this.modalRef && this.modalRef.content) {
      this.modalRef.content.printError = true;
    }
  }

  processAction(displayItem: DisplayItem, context: any): boolean {
    const result = super.processAction(displayItem, context);
    if (!result || !displayItem || this.modalRef) {
      return false;
    }

    this.showPrintModal(displayItem);

    return true;
  }

  showPrintModal(displayItem: DisplayItem): void {
    if (!displayItem) {
      return;
    }

    const templateTypeUniqueName = displayItem.printTemplateTypeUniqueName;
    if (templateTypeUniqueName) {
      const onShownPrintModalSubscribe = this.bsModalService.onShown.subscribe(() => {
        onShownPrintModalSubscribe.unsubscribe();
        this.vuCommunicationService.vuHttp.printByTemplateTypeUniqueName(templateTypeUniqueName, this.languageService.odooLanguageName)
          .catch((error) => {
            this.showError();
          });
      });
    }

    this.modalRef = this.modalService.show(
      PrintModalComponent,
      {
        printError: !templateTypeUniqueName
      },
      () => {
        this.modalRef = null;
        this.dispatcherService.onUserActivity();
      });

    this.dispatcherService.onUserActivity();
  }

  showDisplayItems(displayItems: DisplayItem[], show: boolean, context: any): void {
    this.closeDialog();
    super.showDisplayItems(displayItems, show, context);
  }
}
