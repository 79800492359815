<div class="modal-header">
  <div class="modal-title normal-text pull-left">{{ headerCaption | translate }}</div>
</div>
<div class="modal-body">
  <div class="keyboard">
    <cash-keyboard #cashAmount
      [hidden]="false" [isDisabled]="false" [textLength]="3" [limitLength]="true" [min]="0" [max]="999">
    </cash-keyboard>
  </div>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-warning pull-left button-base button-medium" (click)="cancel()">
    {{ 'Cancel' | translate }}
  </button>
  <button type="button" class="btn btn-success pull-right button-base button-medium" (click)="close()">
    {{ 'OK' | translate }}
  </button>
</div>
