import { Injectable } from '@angular/core';
import { DisplayItemTypeEnum } from '../../../../../lib/display/display-item/display-item-type.enum';
import { DisplayItem } from '../../../../../lib/display/display-item/display-item';
import { Subscription } from 'rxjs';
import { CardDispenserBaseGridWorkflowService } from './card-dispenser-base-grid-workflow.service';

@Injectable()
export class CardDispenserReleaseCardGridWorkflowService extends CardDispenserBaseGridWorkflowService {

  private subscription: Subscription;

  get displayItemType(): DisplayItemTypeEnum {
    return DisplayItemTypeEnum.CardDispenserRelease;
  }

  show(displayItem: DisplayItem, context: any) {
    super.show(displayItem, context);

    if (!this.cardDispenserService.isAvailable) {
      this.showCardDispenserNotAvailable();
      this.navigateToNavigationItem();
      return;
    }

    if (this.cardDispenserService.cardCode) {
      this.subscription = this.cardDispenserService.complete.subscribe(() => {
        this.navigateToNavigationItem();
      });
      this.cardDispenserService.releaseCardCustomer(true);
    } else {
      this.navigateToNavigationItem();
    }

  }

  hide(displayItem: DisplayItem, context: any) {
    super.hide(displayItem, context);
    if (this.subscription) {
      this.subscription.unsubscribe();
      this.subscription = null;
    }
  }
}
