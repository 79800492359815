import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { BaseModalComponent } from 'src/app/components/general/base/base-modal-component';
import { NumberScanModalResult } from 'src/app/lib/scan/number-scan-modal-result';
import { VirtualKeyboardType } from 'src/app/lib/virtual-keyboard/virtual-keyboard-type';
import { BarcodeReaderService } from 'src/app/services/barcode/barcode-reader.service';
import { ConfigurationService } from 'src/app/services/configuration/configuration.service';

@Component({
  selector: 'app-barcode-scan-modal',
  templateUrl: './barcode-scan-modal.component.html',
  styleUrls: ['./barcode-scan-modal.component.css']
})
export class BarcodeScanModalComponent extends BaseModalComponent implements OnInit, OnDestroy {

  private barcodeScannedSubscription: Subscription;
  private ticketInformationInputByVirtualKeyboard = VirtualKeyboardType.AllSymbols;

  constructor(
    private barcodeReaderService: BarcodeReaderService,
    private configurationService: ConfigurationService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.ticketInformationInputByVirtualKeyboard = this.configurationService.ticketInformationInputByVirtualKeyboard;
    this.barcodeScannedSubscription = this.barcodeReaderService.barcodeScanned.subscribe(
      barcode => {
        this.close(new NumberScanModalResult(barcode));
      });
  }

  ngOnDestroy(): void {
    this.unsubscribeBarcodeScannedSubscription();
  }

  unsubscribeBarcodeScannedSubscription(): void {
    if (this.barcodeScannedSubscription) {
      this.barcodeScannedSubscription.unsubscribe();
      this.barcodeScannedSubscription = null;
    }
  }

  get isVirtualKeyboardVisible(): boolean {
    return this.ticketInformationInputByVirtualKeyboard != null;
  }

  get barcodeInputMethodDescription(): string {

    if (this.isVirtualKeyboardVisible) {
        return 'Please scan your ticket or enter the ticket code manually!';
    }

    return 'Please scan your ticket!';
  }
}
