import { Injectable, EventEmitter, Injector } from '@angular/core';
import { ConfigurationService } from '../configuration/configuration.service';
import { KeyboardType } from '../../components/general/on-screen-keyboard/on-screen-keyboard.component';
import { VirtualKeyboardType } from '../../lib/virtual-keyboard/virtual-keyboard-type';
import { AdditionalPropertiesConfigurationService } from '../configuration/additional-properties-configuration.service';

@Injectable()
export class VirtualKeyboardService {

  enteredText: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    private configurationService: ConfigurationService,
    private additionalPropertiesConfigurationService: AdditionalPropertiesConfigurationService,
  ) {
  }

  get keyboardType(): KeyboardType {
    switch (this.configurationService.ticketInformationInputByVirtualKeyboard) {
      case VirtualKeyboardType.AllSymbols:
        return KeyboardType.All;
      case VirtualKeyboardType.CharsOnly:
        return KeyboardType.CharsOnly;
      case VirtualKeyboardType.NumbersOnly:
        return KeyboardType.NumbersOnly;
      default:
        return KeyboardType.None;
    }
  }


  get ticketLength(): number {
    return this.additionalPropertiesConfigurationService.ticketLength;
  }

  get separatorPosition(): number {
    return this.additionalPropertiesConfigurationService.separatorPosition;
  }

  onEnteredText(text: string) {
    this.enteredText.emit(text);
  }
}
