import { Component } from '@angular/core';
import { BaseModalComponent } from '../../general/base/base-modal-component';

@Component({
  selector: 'app-message-modal',
  templateUrl: './message-modal.component.html',
  styleUrls: ['./message-modal.component.css']
})
export class MessageModalComponent extends BaseModalComponent {
  get messages(): string[] {
    return this.data && this.data.messages ? this.data.messages : [];
  }
}
