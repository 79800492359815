import { Injectable } from '@angular/core';

@Injectable()
export class ExternalPaymentRequestSimulatorService {

  constructor(
  ) {
  }

  getOrderInfo(orderId: number): any {
    return {
      properties: {
        sale_order_id: orderId,
      },
      order_lines: [
        {
          product_id: 1,
          quantity: 1,
          price: 10,
          properties: {
            order_line_id: 1,
            booking_date: 'booking_date',
            booking_time_schedule_slot_id: 10
          }
        },
        {
          product_id: 2,
          quantity: 2,
          price: 1 * orderId,
          properties: {
            order_line_id: 2,
            booking_date: 'booking_date',
            booking_time_schedule_slot_id: 20
          }
        }
      ],
      back_url: '/store/external-payment?orderId=1&url=%2Fexternal-payment%2F&paymentType=cash'
    };
  }
}
