import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { KeyboardType } from 'src/app/components/general/on-screen-keyboard/on-screen-keyboard.component';
import { VirtualKeyboardType } from 'src/app/lib/virtual-keyboard/virtual-keyboard-type';
import { BarcodeReaderService } from 'src/app/services/barcode/barcode-reader.service';
import { AdditionalPropertiesConfigurationService } from 'src/app/services/configuration/additional-properties-configuration.service';
import { ConfigurationService } from 'src/app/services/configuration/configuration.service';
import { DispatcherService } from 'src/app/services/dispatcher.service';

@Component({
  selector: 'app-barcode-scan',
  templateUrl: './barcode-scan.component.html',
  styleUrls: ['./barcode-scan.component.css']
})
export class BarcodeScanComponent implements OnInit, OnDestroy {

  @Output()
  barcodeLedEnabledChange = new EventEmitter<boolean>();

  isDisabled = false;
  barcodeLedEnabled = false;

  constructor(
    private dispatcherService: DispatcherService,
    private configurationService: ConfigurationService,
    private additionalPropertiesConfigurationService: AdditionalPropertiesConfigurationService,
    private barcodeReaderService: BarcodeReaderService,
  ) { }

  ngOnInit(): void {
    if (this.isBarcodeReaderAvailable) {
      this.barcodeLedEnable(true);
      this.barcodeLedEnabled = true;
    } else {
      this.barcodeLedEnabled = false;
    }
  }

  ngOnDestroy(): void {
    if (this.barcodeLedEnabled) {
      this.barcodeLedEnable(false);
    }
  }

  private get isBarcodeReaderAvailable(): boolean {
    return this.configurationService.ticketInformationInputByBarcodeReader;
  }

  private barcodeLedEnable(value: boolean): void {
    this.barcodeReaderService.barcodeLedEnable(value);
    this.barcodeLedEnabledChange.emit(value);
  }

  get keyboardType(): KeyboardType {

    switch (this.configurationService.ticketInformationInputByVirtualKeyboard) {
      case VirtualKeyboardType.AllSymbols:
        return KeyboardType.All;
      case VirtualKeyboardType.CharsOnly:
        return KeyboardType.CharsOnly;
      case VirtualKeyboardType.NumbersOnly:
        return KeyboardType.NumbersOnly;
      default:
        return KeyboardType.None;
    }
  }

  get ticketLength(): number {
    return this.additionalPropertiesConfigurationService.ticketLength;
  }

  get separatorPosition(): number {
    return this.additionalPropertiesConfigurationService.separatorPosition;
  }

  onEnteredText(text: string): void {
    this.barcodeReaderService.onBarcodeRead(text);
    this.isDisabled = true;
  }

  onTextChanged(): void {
    this.dispatcherService.onUserActivity();
  }

  get isVirtualKeyboardVisible(): boolean {
    return this.keyboardType !== KeyboardType.None;
  }
}
