import { PrintTask } from './print-task';
import { PrinterActionsEnum } from './printer-actions-enum';

export class PrintTaskResult {
  printTask: PrintTask;
  isSuccess: boolean;
  error: string;
  printerAction: PrinterActionsEnum;

  static fromOther(other: PrintTaskResult): PrintTaskResult {
    return new PrintTaskResult(PrintTask.fromOther(other.printTask),
      other.isSuccess,
      other.error,
      other.printerAction
      );
  }

  constructor(
    task: PrintTask,
    isSuccess: boolean,
    error = '',
    printerAction = PrinterActionsEnum.None,
  ) {
    this.printTask = task;
    this.isSuccess = isSuccess;
    this.error = error;
    this.printerAction = printerAction as PrinterActionsEnum;
  }

  toString(): string {
    return `${this.printTask}. isSuccess: '${this.isSuccess}'. error: '${this.error}'. printerAction: '${this.printerAction}'`;
  }
}
