export class Teaser {
  items: TeaserType[] = [];

  static fromOther(other: Teaser): Teaser {
    return Object.assign(new Teaser(), other);
  }

  addItem(item: TeaserType) {
    if (this.indexOf(item) < 0) {
      this.items.push(item);
    }
  }

  deleteItem(item: TeaserType) {
    let index = this.indexOf(item);
    if (index > -1) {
      this.items.splice(index, 1);
    }
  }

  indexOf(item: TeaserType): number {
    return this.items.indexOf(item, 0);
  }
}

export enum TeaserType {
  TakeChange = 'Take the change!',
  TakeMoney = 'Take the money!',
  ReturnMoney = 'Take the money!',
  TakeCard = 'Take the card!',
  TakeTicket = 'Take the ticket!',
  TakeTickets = 'Take the tickets!',
  TakeReceipt = 'Take the receipt!',
  RevertCardTerminalTransaction = 'Transaction was canceled!',
  Thanks = 'Thank you!',
  DontForgetTicket  = 'Please take your ticket!',
}

