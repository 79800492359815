export enum PostSaleTypeEnum {
  PrintCreditCardReceipt = 'PrintCreditCardReceipt',
  WaitingForMoney = 'WaitingForMoney',
  ProducingRfidCard = 'ProducingRfidCard',
  SaveOrder = 'SaveOrder',
  CheckExitRole = 'CheckExitRole',
  PrintTickets = 'PrintTickets',
  PrintReceipt = 'PrintReceipt',
  ReadRfidCard = 'ReadRfidCard',
  WriteRfidCard = 'WriteRfidCard',
  PrintRFIDCardReceipt = 'PrintRFIDCardReceipt',
  ReleasingRfidCard = 'ReleasingRfidCard',
  WaitingForRfidCardRemoval = 'WaitingForRfidCardRemoval',
  OpenGate = 'OpenGate',
  Thanks = 'Thanks',
}
