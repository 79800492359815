import { DictionaryHelper } from "./dictionary-helper";

export class ScreenSaverConfiguration {
  timeout: number;
  timeFrom: number;
  timeTo: number;
  mode: string;
  message: string;
  imageShowTimeout: number;
  imagesIds: string[];
  disableLntBoard: boolean;

  private internalProperties: any;
  private internalPropertiesDictionaryHelper: DictionaryHelper;

  static fromJson(other: any): ScreenSaverConfiguration {
    const screenSaverConfiguration = new ScreenSaverConfiguration();
    screenSaverConfiguration.timeout = other.timeout;
    screenSaverConfiguration.timeFrom = other.timeFrom;
    screenSaverConfiguration.timeTo = other.timeTo;
    screenSaverConfiguration.mode = other.mode;
    screenSaverConfiguration.message = other.message;
    screenSaverConfiguration.imageShowTimeout = other.imageShowTimeout;
    screenSaverConfiguration.imagesIds = other.imagesIds;
    screenSaverConfiguration.disableLntBoard = other.disableLntBoard;
    screenSaverConfiguration.properties = other.properties;
    return screenSaverConfiguration;
  }

  get properties(): any {
    return this.internalProperties;
  }

  set properties(value) {
    this.internalProperties = value;
    this.internalPropertiesDictionaryHelper = new DictionaryHelper(this.internalProperties);
  }

  get propertiesHelper(): DictionaryHelper {
    return this.internalPropertiesDictionaryHelper;
  }

  get hideScreenSaverInOutOfOrderMode(): boolean {
    return this.propertiesHelper?.getBoolean('hide_screen_saver_in_out_of_order_mode');
  }

  get disableScreen(): boolean {
    return this.propertiesHelper?.getBoolean('disable_screen');
  }
}
