import { BluecodeEventType } from './bluecode-event-type';

export class BluecodeEvent {
  eventType: BluecodeEventType;
  checkinCode: string;

  constructor(eventType: BluecodeEventType, checkinCode: string) {
    this.eventType = eventType;
    this.checkinCode = checkinCode;
  }

  static fromOther(x: any): BluecodeEvent {
    return new BluecodeEvent(x.eventType, x.checkinCode);
  }

  toString(): string {
    return `${BluecodeEventType[this.eventType]} '${this.checkinCode == null ? '' : this.checkinCode}'`
  }
}
