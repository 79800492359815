import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { ConfigurationService } from '../../../services/configuration/configuration.service';
import { SaleService } from '../../../services/sale.service';
import { LanguageService } from 'src/app/services/language.service';

@Component({
  selector: 'receipt-toggle',
  templateUrl: './receipt-toggle.component.html',
  styleUrls: ['./receipt-toggle.component.scss']
})
export class ReceiptToggleComponent implements OnInit {

  @Input()
  cssClass: string = '';


  isReceiptChecked: boolean = false;

  constructor(
    private configurationService: ConfigurationService,
    private saleService: SaleService,
    private languageService: LanguageService,
  ) {
  }

  ngOnInit(): void {
    this.isReceiptChecked = this.getDefaultValue();
    this.updateOrderReceiptCheckedState();
  }

  getDefaultValue(): boolean {
    return this.configurationService?.configuration?.orderReceiptByDefault ?? false;
  }

  updateOrderReceiptCheckedState(): void {
    if (!this.saleService || !this.saleService.order) {
      return;
    }

    this.saleService.order.isReceipt = this.isReceiptChecked;
  }

  get slideToggleText(): string {
    return this.configurationService?.configuration?.originalConfigurationHelper?.getTranslatedString(this.language, 'print_order_receipt_button_text') || 'Invoice receipt';
  }

  onToggleChange(): void {
    this.isReceiptChecked = !this.isReceiptChecked;
    this.updateOrderReceiptCheckedState();
  }

  get language(): string {
    return this.languageService.odooLanguageName;
  }
}
