<label [attr.for]="'workflowType'">{{ ('Add Workflow Step' | translate) + ':' }}</label>
<select id="workflowType" [(ngModel)]="selectedWorkflowType" (change)="onWorkflowStepChange($event)">
  <option *ngFor="let value of keys(workflowStepType)" [ngValue]="workflowStepType[value]">{{ workflowStepType[value] }}</option>
</select>
<label [attr.for]="'workflowState'">{{ ('Set Workflow State' | translate) + ':' }}</label>
<select id="workflowState" [(ngModel)]="selectedWorkflowState" (change)="onWorkflowLastStepStateSet($event)">
  <option *ngFor="let value of keys(workflowStepState)" [ngValue]="workflowStepState[value]">{{ workflowStepState[value] }}</option>
</select>
<a type="button" class="btn btn-default" (click)="onWorkflowLastStepUpdate()">{{'Update Last Workflow Step' | translate }}</a>
<a type="button" class="btn btn-default" (click)="onWorkflowDeleteLastStep()">{{'Delete Last Workflow Step' | translate }}</a>
<a type="button" class="btn btn-default" (click)="onWorkflowReset()">{{'Reset Workflow Step' | translate }}</a>
