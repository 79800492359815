import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SimpleButtonComponent } from '../../components/general/simple-button/simple-button.component';
import { VUTranslateModule } from '../vu-translate/vu-translate.module';
import { CountButtonComponent } from '../../components/general/count-button/count-button.component';
import { VirtualKeyboardComponent } from '../../components/general/virtual-keyboard/virtual-keyboard.component';
import { OnScreenKeyboardComponent } from '../../components/general/on-screen-keyboard/on-screen-keyboard.component';
import { FormsModule } from '@angular/forms';
import { MessageComponent } from '../../components/general/message/message.component';
import { PinKeyboardComponent } from '../../components/general/pin-keyboard/pin-keyboard.component';
import { CashKeyboardComponent } from '../../components/general/cash-keyboard/cash-keyboard.component';
import { VirtualKeypadComponent } from '../../components/general/virtual-keypad/virtual-keypad.component';
import { TicketStatusComponent } from '../../components/ticket/ticket-status/ticket-status.component';
import { SimpleButtonImageComponent } from 'src/app/components/general/simple-button-image/simple-button-image.component';
import { TeaserComponent } from 'src/app/components/general/teaser/teaser.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { InputCustomPriceModalComponent } from 'src/app/components/general/input-custom-price-modal/input-custom-price-modal.component';
import { DateTimeComponent } from 'src/app/components/general/date-time/date-time.component';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { TimepickerModule } from 'ngx-bootstrap/timepicker';
import { SpinnerBallsComponent } from 'src/app/components/general/spinner-balls/spinner-balls.component';
@NgModule({
  declarations: [
    SimpleButtonComponent,
    SimpleButtonImageComponent,
    CountButtonComponent,
    VirtualKeyboardComponent,
    VirtualKeypadComponent,
    OnScreenKeyboardComponent,
    MessageComponent,
    PinKeyboardComponent,
    CashKeyboardComponent,
    TicketStatusComponent,
    TeaserComponent,
    InputCustomPriceModalComponent,
    DateTimeComponent,
    SpinnerBallsComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    VUTranslateModule,
    MatProgressSpinnerModule,
    TimepickerModule.forRoot(),
    BsDatepickerModule.forRoot(),
  ],
  exports: [
    SimpleButtonComponent,
    CountButtonComponent,
    VirtualKeyboardComponent,
    VirtualKeypadComponent,
    OnScreenKeyboardComponent,
    MessageComponent,
    PinKeyboardComponent,
    CashKeyboardComponent,
    TicketStatusComponent,
    SimpleButtonImageComponent,
    TeaserComponent,
    InputCustomPriceModalComponent,
    DateTimeComponent,
    SpinnerBallsComponent,
  ]
})
export class ComponentsModule { }
