import { Component, Input, Output, EventEmitter } from '@angular/core';

const LAYOUT = [
  {
    "row": [
      { "value": "7", "action": "char" },
      { "value": "8", "action": "char" },
      { "value": "9", "action": "char" },
    ]
  },
  {
    "row": [
      { "value": "4", "action": "char" },
      { "value": "5", "action": "char" },
      { "value": "6", "action": "char" },
    ]
  },
  {
    "row": [
      { "value": "1", "action": "char" },
      { "value": "2", "action": "char" },
      { "value": "3", "action": "char" },
    ]
  },
  {
    "row": [
      { "value": "←", "action": "del" },
      { "value": "0", "action": "char" },
      { "value": "OK", "action": "enter" },
    ]
  },
];

@Component({
  selector: 'pin-keyboard',
  templateUrl: './pin-keyboard.component.html',
  styleUrls: ['./pin-keyboard.component.css']
})
export class PinKeyboardComponent {

  layout: any[] = LAYOUT;
  textValue: string = '';
  @Input() isDisabled = false;
  @Input() limitLength = false;
  @Output() onEnteredText: EventEmitter<string> = new EventEmitter<string>();
  @Output() onTextChanged: EventEmitter<string> = new EventEmitter<string>();

  @Input()
  textLength: number;

  @Input()
  separatorPosition: number;

  get _textLength(): number {
    if (this.textLength) {
      return this.textLength;
    }

    const separatorCount = 3;
    return 16 + separatorCount;
  }

  checkTextLength(length: number): boolean {
    return length == this._textLength;
  }

  checkTextLengthLimit(length: number):boolean {
    return this.limitLength && this.textLength && length > this.textLength;
  }

  keyPress(key) {
    const value = key.value;
    const action = key.action;
    switch (action) {
      case 'char':
      case 'number':
      case 'zero':
      case 'space':
        const newCharLength = 1;
        if (this.checkTextLength(this.textValue.length + newCharLength)) {
          this.textValue = `${this.textValue}${value.toUpperCase()}`
          this.onEnteredText.emit(this.textValue);
          break;
        }

        if (this.checkTextLengthLimit(this.textValue.length + newCharLength)){
          break;
        }

        if (this.separatorPosition && this.separatorPosition != 0) {
          if ((this.textValue.length + 2) % this.separatorPosition == 0)
            this.textValue = `${this.textValue}${value.toUpperCase()}-`;
          else {
            if ((this.textValue.length + 1) % this.separatorPosition == 0)
              this.textValue = `${this.textValue}-${value.toUpperCase()}`
            else
              this.textValue = `${this.textValue}${value.toUpperCase()}`
          }
        } else {
          this.textValue = `${this.textValue}${value.toUpperCase()}`
        }
        this.onTextChanged.emit(this.textValue);
        break;
      case 'del':
        let l = this.textValue.length;
        if (l > 0) {
          this.textValue = this.textValue.substr(0, l - 1)
        }

        if (this.checkTextLength(this.textValue.length)) {
          this.onEnteredText.emit(this.textValue);
          break;
        }
        if (l != this.textValue.length) {
          this.onTextChanged.emit(this.textValue);
        }
        break;
      case 'enter':
          this.onEnteredText.emit(this.textValue);
        break;
      default:
        break;
    }
  }

  setText(value:string): void {
    this.textValue = value;
    this.onTextChanged.emit(value);
  }
}
