import { Injectable, Injector } from "@angular/core";
import { VuCommunicationService } from "../vu/vu-communication.service";
import { IVuHttp } from "../vu/http/vu-http.interface";

@Injectable()
export class ScreenService {
  vuHttp: IVuHttp;

  constructor(
    injector: Injector,
  ) {
    const vuCommunicationService = injector.get(VuCommunicationService);
    this.vuHttp = vuCommunicationService.vuHttp;
  }

  switchScreen(enabled: boolean) {
    this.vuHttp.switchScreen(enabled);
  }
}
