import { GlobalSettings } from 'src/app/lib/global-settings';
import { Money } from 'src/app/lib/money/money';

export class PurchasedProductInformation {
  id: number;
  name: string;
  price: Money;
  quantity: number;

  static createFromAny(data: any): PurchasedProductInformation {
    const purchasedProductInformation = new PurchasedProductInformation();
    if (data) {
      purchasedProductInformation.name = data.product_name || '';
      purchasedProductInformation.price = data.price != null ? new Money(data.price, GlobalSettings.getCurrencyCode()) : Money.empty;

      purchasedProductInformation.id = data.product_id || 0;
      purchasedProductInformation.quantity = data.quantity || 0;
    }

    return purchasedProductInformation;
  }

  static createFromAnyArray(array: any): PurchasedProductInformation[] {
    if (!array) {
      return [];
    }
    return array.map(this.createFromAny).filter(item => item != null);
  }

  get totalAmount(): Money {
    return new Money(this.price.value * this.quantity, this.price.currencyCode);
  }
}
