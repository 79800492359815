import { Component, OnInit, OnDestroy } from '@angular/core';
import { Money, CashType } from '../../../lib/lib';
import { MoneyExchangeStateService } from '../../../services/money-exchange/money-exchange-state.service';
import { DispatcherService } from '../../../services/dispatcher.service';
import { Subscription } from 'rxjs';
import { VuCommunicationService } from '../../../services/vu/vu-communication.service';

@Component({
  selector: 'app-accepting-cash-info',
  templateUrl: './accepting-cash-info.component.html',
  styleUrls: ['./accepting-cash-info.component.css']
})
export class AcceptingCashInfoComponent implements OnInit, OnDestroy {

  canCashBeAccepted = true;
  private backButtonVisible: boolean;
  private isBackButtonEnabled: boolean;
  private bnaValidationStartedSubscription: Subscription;
  private bnaValidationFinishedSubscription: Subscription;

  constructor(
    private moneyExchangeStateService: MoneyExchangeStateService,
    private dispatcherService: DispatcherService,
    private vuCommunicationService: VuCommunicationService,
  ) {
  }

  ngOnInit() {
    this.dispatcherService.pageHeaderSet('Money Exchange');
    this.backButtonVisible = this.dispatcherService.isBackButtonVisible;
    this.dispatcherService.isBackButtonVisible = true;
    this.isBackButtonEnabled = this.dispatcherService.isBackButtonEnabled;
    this.bnaValidationStartedSubscription = this.vuCommunicationService.vuConnection.eventBnaValidationStarted
    .subscribe(
      _ => this.onBnaValidationStarted()
    );
    this.bnaValidationFinishedSubscription = this.vuCommunicationService.vuConnection.eventBnaValidationFinished
    .subscribe(
      _ => this.onBnaValidationFinished()
    );
  }

  ngOnDestroy() {
    this.dispatcherService.pageHeaderSet();
    this.dispatcherService.isBackButtonVisible = this.backButtonVisible;
    this.dispatcherService.isBackButtonEnabled = this.isBackButtonEnabled;
    this.bnaValidationStartedSubscription.unsubscribe();
    this.bnaValidationFinishedSubscription.unsubscribe();
  }

  onBnaValidationStarted(): void {
    this.dispatcherService.isBackButtonEnabled = false;
    this.dispatcherService.onUserActivity();
  }

  onBnaValidationFinished(): void {
    this.dispatcherService.isBackButtonEnabled = true;
  }

  get acceptedCoins(): Money[] {
    return this.moneyExchangeStateService.acceptedCash ? this.moneyExchangeStateService.acceptedCash.coins : [];
  }

  get acceptedBanknotes(): Money[] {
    return this.moneyExchangeStateService.acceptedCash ? this.moneyExchangeStateService.acceptedCash.banknotes : [];
  }

  get cashTypeCoin(): CashType {
    return CashType.Coin;
  }

  get cashTypeBanknote(): CashType {
    return CashType.Banknote;
  }
}
