import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PrintXmlPreviewComponent } from './components/print-xml-preview/print-xml-preview.component';


@NgModule({
  declarations: [
    PrintXmlPreviewComponent,
  ],
  imports: [
    CommonModule,
  ],
  exports: [
    PrintXmlPreviewComponent,
  ]
})
export class PrintModule { }
