import { MachineBaseService } from './machine-base.service';
import { ScreenSaverService } from '../screen-saver.service';
import { ModalService } from '../gui/modal/modal-service';
import { Injectable } from '@angular/core';

@Injectable()
export class MachineScreenSaverService extends MachineBaseService {
  private screenSaverService: ScreenSaverService;
  private modalService: ModalService;

  init(): void {
    this.screenSaverService = this.injector.get(ScreenSaverService);
    super.init();
    this.screenSaverService.eventShowScreenSaverStart.subscribe((x: any) => this.onShowScreenSaverStart());
    this.modalService = this.injector.get(ModalService);
  }

  get machineName(): string {
    return 'Screen Saver Machine';
  }

  protected getTransitions(): any[] {
    return super.getTransitions(
      { name: 'toIdle', from: ['off'], to: 'idle' },
      { name: 'toShow', from: ['idle'], to: 'show' },
    );
  }

  protected getMethods(): any {
    const scope = this;
    return super.getMethods({
      onToOff: (event: any, isHardReset = false) => {
        scope.screenSaverService.stop();
      },
      onToIdle: () => {
        scope.screenSaverService.start();
      },
      onToShow: () => {
        scope.do(() => {
          scope.modalService.closeAll();
          scope.router.navigateByUrl('/screen-saver');
        }, 'onToShow');
      },
    });
  }

  machineStart(): void {
    if (this.isOff) {
      this.machine.toIdle();
    }
  }

  machineStop(): void {
    if (!this.isOff) {
      this.machine.toOff();
    }
  }

  protected get timeoutTrackingStates(): string[] {
    return [];
  }

  private onShowScreenSaverStart(): void {
    this.machine.toShow();
  }
}
