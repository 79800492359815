<a type="button" class="btn btn-default" [ngClass]="{disabled : !shopState.isOrderSaving}"
  (click)="onOrderSaveClick(true)">
  {{ 'Order OK' | translate }}</a>
<a type="button" class="btn btn-default" [ngClass]="{disabled : !shopState.isOrderSaving}"
  (click)="onOrderSaveClick(false)">
  {{ 'Order Not OK' | translate }}</a>
{{ 'Refund Payment Action:'}}
<select [(ngModel)]="configuration.refundPaymentAction" (change)="onRefundPaymentActionChange($event)">
  <option *ngFor="let l of refundPaymentActions" [ngValue]="l">{{l}}</option>
</select>
