import { Component, OnInit } from '@angular/core';
import { ScreenSaverService } from '../../../services/screen-saver.service';
import { ScreenSaverConfiguration } from '../../../lib/screen-saver-configuration';
import { VuCommunicationService } from '../../../services/vu/vu-communication.service';
import { ConfigurationService } from '../../../services/configuration/configuration.service';

@Component({
  selector: 'app-screen-saver-simulator',
  templateUrl: './screen-saver-simulator.component.html',
  styleUrls: ['./screen-saver-simulator.component.css']
})
export class ScreenSaverSimulatorComponent implements OnInit {

  constructor(
    private screenSaverService: ScreenSaverService,
    private vuCommunicationService: VuCommunicationService,
    private configurationService: ConfigurationService,
  ) {
  }

  ngOnInit(): void {
  }

  public get configuration(): ScreenSaverConfiguration {
    return this.screenSaverService.configuration;
  }

  get timeouts(): number[] {
    return Array.apply(null, { length: 101 }).map(Number.call, Number);
  }

  onTimeoutChange(event: any): void {
    this._updateScreenSaverConfiguration();
  }

  onModeChange(event: any): void {
    this._updateScreenSaverConfiguration();
  }

  onImageShowTimeoutChange(event: any): void {
    this._updateScreenSaverConfiguration();
  }

  onTimeFromChange(event: any): void {
    this._updateScreenSaverConfiguration();
  }

  onTimeToChange(event: any): void {
    this._updateScreenSaverConfiguration();
  }

  _updateScreenSaverConfiguration(): void {
    const configuration = this.screenSaverService.configuration;
    this.vuCommunicationService.vuHttpSimulator.setScreenSaverConfiguration(configuration);
    this.vuCommunicationService.vuConnectionSimulator.configurationChanged(this.configurationService.configuration);
    this.vuCommunicationService.vuHttp.getVuConfiguration().then(
      result => {
        this.vuCommunicationService.vuConnectionSimulator.vuConfigurationChanged(result);
      }
    );
  }
}
