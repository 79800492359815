import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { GlobalSettings } from '../lib/global-settings';

@Pipe({
  name: 'localeDate',
  pure: false,
})
export class LocaleDatePipe extends DatePipe implements PipeTransform {

  transform(value: any, format?: string, timezone?: string, locale?: string): string {
    if (!locale) {
      locale = GlobalSettings.getCurrentLocale();
    }
    return super.transform(value, format, timezone, locale);
  }
}
