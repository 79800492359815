import { Component, OnInit } from '@angular/core';
import { TicketSimulatorService } from '../../../services/ticket/ticket-simulator.service';
import { BarcodeReaderSimulatorService } from '../../../services/barcode/barckode-reader-simulator.service';

@Component({
  selector: 'app-ticket-simulator',
  templateUrl: './ticket-simulator.component.html',
  styleUrls: ['./ticket-simulator.component.css']
})
export class TicketSimulatorComponent implements OnInit {

  constructor(
    private ticketSimulatorService: TicketSimulatorService,
    private barcodeReaderSimulatorService: BarcodeReaderSimulatorService,
  ) {
  }

  ngOnInit() {
  }

  onTicketValidEnterClick() {
    const ticket = this.ticketSimulatorService.generateTicketValidEnter();
    this.barcodeReaderSimulatorService.barcodeRead(ticket.code);
  }

  onTicketValidExitClick() {
    const ticket = this.ticketSimulatorService.generateTicketValidExit();
    this.barcodeReaderSimulatorService.barcodeRead(ticket.code);
  }

  onTicketValidTodayUsedClick() {
    const ticket = this.ticketSimulatorService.generateTicketValidTodayUsed();
    this.barcodeReaderSimulatorService.barcodeRead(ticket.code);
  }

  onTicketInvalidUsedClick() {
    const ticket = this.ticketSimulatorService.generateTicketInvalidUsed();
    this.barcodeReaderSimulatorService.barcodeRead(ticket.code);
  }

  onTicketInvalidClosedClick() {
    const ticket = this.ticketSimulatorService.generateTicketInvalidClosed();
    this.barcodeReaderSimulatorService.barcodeRead(ticket.code);
  }

  onTicketInvalidOvertimeClick() {
    const ticket = this.ticketSimulatorService.generateTicketInvalidOvertime();
    this.barcodeReaderSimulatorService.barcodeRead(ticket.code);
  }

  onTicketUnknownClick() {
    const ticket = this.ticketSimulatorService.generateTicketUnknown();
    this.barcodeReaderSimulatorService.barcodeRead(ticket.code);
  }

  onTicketStaffClick() {
    const ticket = this.ticketSimulatorService.generateTicketStaff();
    this.barcodeReaderSimulatorService.barcodeRead(ticket.code);
  }

  onTicketValidTimeClick() {
    const ticket = this.ticketSimulatorService.generateTicketValidTime();
    this.barcodeReaderSimulatorService.barcodeRead(ticket.code);
  }

  onTicketInvalidTimeClick() {
    const ticket = this.ticketSimulatorService.generateTicketInvalidTime();
    this.barcodeReaderSimulatorService.barcodeRead(ticket.code);
  }

  onTicketValidOutOfScheduleClick() {
    const ticket = this.ticketSimulatorService.generateTicketValidOutOfSchedule();
    this.barcodeReaderSimulatorService.barcodeRead(ticket.code);
  }

  onTicketInvalidRandomProductTicketClick() {
    var ticket = this.ticketSimulatorService.generateTicketInvalidRandomProductTicket();
    this.barcodeReaderSimulatorService.barcodeRead(ticket.code);
  }

  onVirtualTicketValidClick() {
    var ticket = this.ticketSimulatorService.generateVirtualTicketValid();
    this.barcodeReaderSimulatorService.barcodeRead(ticket.code);
  }

  onVirtualTicketInvalidClick() {
    var ticket = this.ticketSimulatorService.generateVirtualTicketInvalid();
    this.barcodeReaderSimulatorService.barcodeRead(ticket.code);
  }

  onLogisticsTicketClick(){
    let ticket = this.ticketSimulatorService.generateTicketInvalidOvertime();
    this.barcodeReaderSimulatorService.barcodeRead(ticket.code);
  }

  onTicketFromToValidClick() {
    const ticket = this.ticketSimulatorService.generateTicketFromToValid();
    this.barcodeReaderSimulatorService.barcodeRead(ticket.code);
  }

  onTicketFromToInvalidClick() {
    const ticket = this.ticketSimulatorService.generateTicketFromToInvalid();
    this.barcodeReaderSimulatorService.barcodeRead(ticket.code);
  }

  onTicketFromToDisabledClick() {
    const ticket = this.ticketSimulatorService.generateTicketFromToDisabled();
    this.barcodeReaderSimulatorService.barcodeRead(ticket.code);
  }
}
