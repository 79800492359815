import { Ticket, TicketUse, MachineInactivitySettings, } from '../../lib/lib';
import { MachineBaseService } from './machine-base.service';
import { Message, MessageType } from '../message.service';
import { BarcodeReaderService } from '../barcode/barcode-reader.service';
import { TicketService } from '../ticket/ticket.service';
import { RfidCardReaderService } from '../rfid-card/rfid-card-reader.service';
import { VirtualKeyboardService } from '../virtual-keyboard/virtual-keyboard.service';
import { ShopRedirectService } from '../shop-redirect.service';
import { Injectable } from '@angular/core';

enum EventType {
  BarcodeRead,
  TicketScanned,
  RfidRead,
}

@Injectable()
export class MachineTicketService extends MachineBaseService {
  private ticketService: TicketService;
  private virtualKeyboardService: VirtualKeyboardService;
  private shopRedirectService: ShopRedirectService;
  private barcodeReaderService: BarcodeReaderService;
  private rfidCardReaderService: RfidCardReaderService;
  private lastEvent = EventType.BarcodeRead;
  isTicketOvertimePayment: boolean;
  init(): void {
    super.init();
    this.virtualKeyboardService = this.injector.get(VirtualKeyboardService);
    this.virtualKeyboardService.enteredText.subscribe((x: string) => this.onVirtualKeyboardEnteredText(x));
    this.barcodeReaderService = this.injector.get(BarcodeReaderService);
    this.barcodeReaderService.barcodeScanned.subscribe((x: string) => this.onBarcodeScanned(x));
    this.ticketService = this.injector.get(TicketService);
    this.rfidCardReaderService = this.injector.get(RfidCardReaderService);
    this.rfidCardReaderService.rfidCardScanned.subscribe((x: string) => this.onRfidScanned(x));
    this.shopRedirectService = this.injector.get(ShopRedirectService);
  }

  get machineName(): string {
    return 'Ticket Machine';
  }

  protected getTransitions(): any[] {
    return super.getTransitions(
      { name: 'toPending', from: ['off', 'waitForTicket', 'ticketLoading', 'ticketUse'], to: 'pending' },
      { name: 'toWaitForTicket', from: ['off', 'ticketInfo'], to: 'waitForTicket' },
      { name: 'toTicketLoading', from: ['off', 'pending', 'waitForTicket', 'ticketInfo', 'ticketUse'], to: 'ticketLoading' },
      { name: 'toTicketInfo', from: ['pending', 'waitForTicket', 'ticketLoading', 'ticketUse'], to: 'ticketInfo' },
      { name: 'toTicketUse', from: ['ticketInfo'], to: 'ticketUse' },
    );
  }

  protected getMethods(): any {
    const scope = this;
    return super.getMethods({
      onToWaitForTicket(): void {
        scope.dispatcherService.ticketReset();
        scope.isTicketOvertimePayment = false;
        scope.router.navigateByUrl('/ticket/wait-for');
      },
      onToTicketLoading(): void {
        scope.router.navigateByUrl('/ticket/loading');
      },
      onToPending(): void {
        scope.isTicketOvertimePayment = false;
        scope.eventPending.emit();
      },
      onToTicketInfo(): void {
        scope.router.navigateByUrl('/ticket/info');
      },
      onToTicketUse(event: any, ticket: Ticket): void {
        scope.dispatcherService.ticketUseInfoTicket(ticket);
        if (ticket != null) {
          scope.vuHttp.getTicketUse(ticket.code)
            .then((x: TicketUse[]) => scope.dispatcherService.ticketUseInfoUse(x));
        }
        scope.router.navigateByUrl('/ticket/use');
      },
    });
  }

  machineStart(withoutTransition = false): void {
    if (this.isInState('off')) {
      this.machine.toWaitForTicket();
    } else if (this.isInState('pending')) {
      switch (this.lastEvent) {
        case EventType.BarcodeRead:
          this.machine.toTicketLoading();
          break;
        case EventType.TicketScanned:
          this.machine.toTicketInfo();
          break;
          case EventType.RfidRead:
            this.machine.toTicketLoading();
            break;
      }
    }
  }

  protected getMessages(): MessageType[] {
    return super.getMessages(
      MessageType.ButtonBackClicked,
      MessageType.Back,
      MessageType.TicketOvertimePayment,
      MessageType.TicketUse,
    );
  }

  protected onMessage(message: Message): boolean {
    if (super.onMessage(message)) {
      return true;
    }

    switch (message.messageType) {
      case MessageType.ButtonBackClicked:
        if (this.isInState('ticketUse')) {
          this.machine.toTicketInfo();
        } else if (this.isInState('ticketInfo')) {
          this.machine.toWaitForTicket();
        } else if (this.isInState('waitForTicket')) {

          if (this.shopRedirectService.tryRedirectToShop()) {
            return;
          }

          this.machine.toOff();
        }
        break;
      case MessageType.TicketOvertimePayment:
        this.isTicketOvertimePayment = true;
        this.machine.toOff();
        break;
      case MessageType.TicketUse:
        this.machine.toTicketUse(message.info as Ticket);
        break;
      default:
        break;
    }
    return false;
  }

  protected onMachineTimeoutModalCancel(machineName: string): void {
    if (machineName === this.machineName) {
      this.shopRedirectService.tryRedirectToShop();
    }
    super.onMachineTimeoutModalCancel(machineName);
  }

  onTicketScanned(ticket: Ticket): void {
    this.lastEvent = EventType.TicketScanned;
    this.dispatcherService.ticketUpdate(ticket);
    if (this.isInState('off')) {
      this.machine.toPending();
    } else if (this.isInState('waitForTicket', 'ticketLoading', 'ticketUse')) {
      this.machine.toTicketInfo();
    }
  }

  get ignoreBarcodeReaderScanOnMainPage(): boolean {
    return this.additionalPropertiesConfigurationService.ignoreBarcodeReaderScanOnMainPage;
  }

  onVirtualKeyboardEnteredText(text: string): void {
    if (this.isInState('off')) {
      return;
    }

    this.getTicketInfo(text);
  }

  onBarcodeScanned(barcode: string): void {
    if (!this.configurationService.ticketInformationInputByBarcodeReader) {
      return;
    }

    if (this.ignoreBarcodeReaderScanOnMainPage && this.isInState('off')) {
      return;
    }

    this.getTicketInfo(barcode);
  }

  protected getTicketInfo(barcode: string): void {
    this.dispatcherService.barcode = barcode;
    this.lastEvent = EventType.BarcodeRead;
    if (this.isInState('off')) {
      this.machine.toPending();
    } else if (this.isInState('waitForTicket', 'ticketUse', 'ticketInfo')) {
      this.machine.toTicketLoading();
    }
    this.ticketService.getTicketInfo(barcode).subscribe(ticket => { this.onTicketScanned(ticket); });
  }

  get ignoreRfidReaderScanOnMainPage(): boolean {
    return this.additionalPropertiesConfigurationService.ignoreRfidReaderScanOnMainPage;
  }

  onRfidScanned(barcode: string) {
    if (!this.configurationService.ticketInformationInputByRFIDReader) {
      return;
    }

    if (this.ignoreRfidReaderScanOnMainPage && this.isInState('off')) {
      return;
    }

    this.getTicketInfo(barcode);
  }

  protected get timeoutTrackingStates(): string[] {
    return this.configurationService.isRoleExit ?
      [
        'ticketInfo',
        'ticketLoading',
        'ticketUse'
      ] :
      [
        'waitForTicket',
        'ticketInfo',
        'ticketLoading',
        'ticketUse'
      ];
  }

  protected getMachineInactivitySettings(state: string): MachineInactivitySettings {
    switch (state) {
      case 'waitForTicket':
      case 'ticketLoading':
        return new MachineInactivitySettings(120000, false);
      case 'ticketInfo':
      case 'ticketUse':
        return new MachineInactivitySettings(20000, false);
      default:
        return super.getMachineInactivitySettings(state);
    }
  }
}
