import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { CovidService } from './services/covid.service';
import { CovidCheckComponent } from './components/covid-check/covid-check.component';
import { AccessGuardService } from './services/access-guard.service';
import { MachineAccessGuardService } from 'src/app/services/machines/machine-guard-access.service';
import { WorkflowModule } from '../workflow/workflow.module';
import { SharedModule } from '../shared/shared.module';
import { ComponentsModule } from '../components/components.module';


@NgModule({
  declarations: [
    CovidCheckComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    SharedModule,
    ComponentsModule,
    WorkflowModule,
  ],
  exports: [
    CovidCheckComponent,
  ],
  providers: [
    CovidService,
    AccessGuardService,
    MachineAccessGuardService,
  ]
})
export class AccessGuardModule { }
