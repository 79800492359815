import { Injectable } from '@angular/core';
import { ICashlessPaymentProvider } from 'src/app/lib/payment/cashless-payment-provider.interface';
import { SaleService } from '../../sale.service';
import { LoggingService } from '../../logging/logging.service';
import { Subject } from 'rxjs';
import { PaymentMessageStore } from 'src/app/lib/payment/payment-message-store';
import { CashlessPaymentProviderEventType } from 'src/app/lib/payment/cashless-payment-provider-event-type';
import { CashlessPaymentProviderEvent } from 'src/app/lib/payment/cashless-payment-provider-event';
import { VuCommunicationService } from '../../vu/vu-communication.service';


@Injectable()
export abstract class CashlessBaseProviderService implements ICashlessPaymentProvider {

  paymentData: any;

  eventCashlessPaymentProviderEvent = new Subject<CashlessPaymentProviderEvent>();

  protected cashlessPaymentMessageStore: PaymentMessageStore;

  constructor(
    protected log: LoggingService,
    protected saleService: SaleService,
    protected vuCommunicationService: VuCommunicationService,
  ) {
    this.cashlessPaymentMessageStore = this.saleService.cashlessPaymentMessageStore;
    if (!this.cashlessPaymentMessageStore) {
      this.log.error(
        'this.saleService.creditCardPaymentMessage must be initialized!'
      );
    }
  }

  get name(): string {
    return CashlessBaseProviderService.name;
  }

  init(): void {
  }

  transactionBegin(): void {
    this.cashlessPaymentMessageStore.reset();
    this._subscribeEvents();
  }

  transactionEnd(): void {
    this._unsubscribeEvents();
  }

  protected _subscribeEvents(): void {
    this.log.info(`BaseProviderService _subscribeEvents`);
  }

  protected _unsubscribeEvents(): void {
    this.log.info(`BaseProviderService _unsubscribeEvents`);
  }

  protected raiseTextEvent(message: string): void {
    this.raiseCashlessEvent(CashlessPaymentProviderEventType.TextChanged, message);
  }

  protected raiseCashlessEvent(eventType: CashlessPaymentProviderEventType, eventInfo?: string): void {
    this.eventCashlessPaymentProviderEvent.next(new CashlessPaymentProviderEvent(eventType, eventInfo));
  }
}
