export class FreeProductInformation {
  id: number;
  name: string;

  usedCount: number;
  quantity: number;

  static createFromAny(data: any): FreeProductInformation {
    const freeProductInformation = new FreeProductInformation();
    if (data) {
      freeProductInformation.id = data.product_id || 0;
      freeProductInformation.name = data.product_name || '';
      freeProductInformation.quantity = data.quantity || 0;
      freeProductInformation.usedCount = data.used_count || 0;
    }

    return freeProductInformation;
  }


  static createFromAnyArray(array: any): FreeProductInformation[] {
    if (!array) {
      return [];
    }
    return array.map(this.createFromAny).filter(item => item != null);
  }

  get availableCount(): number {
    return this.quantity - this.usedCount;
  }

}
