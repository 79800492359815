import { Injectable } from '@angular/core';
import { DisplayItemTypeEnum } from '../../../../../lib/display/display-item/display-item-type.enum';
import { DisplayItem } from '../../../../../lib/display/display-item/display-item';
import { BaseGridWorkflowService } from '../../base/base-grid-workflow.service';
import { BarcodeReaderService } from '../../../../barcode/barcode-reader.service';
import { Subscription } from 'rxjs';

@Injectable()
export class TicketGridWorkflowService extends BaseGridWorkflowService {

  subscription: Subscription;
  private barcodeReaderService: BarcodeReaderService;
  private lastBarcode: string;
  private blockDuplicateScan = 1500;

  init(
  ): void {
    super.init();
    this.barcodeReaderService = this.injector.get(BarcodeReaderService);
  }

  get displayItemType(): DisplayItemTypeEnum {
    return DisplayItemTypeEnum.Ticket;
  }

  show(displayItem: DisplayItem, context: any): void {
    super.show(displayItem, context);
    this.enableBarcodeScanned();
  }

  hide(displayItem: DisplayItem, context: any): void {
    super.hide(displayItem, context);
    this.disabledBarcodeScanned();
  }

  hideAll(): void {
    super.hideAll();
    this.disabledBarcodeScanned();
  }

  get isEnableBarcodeScanned(): boolean {
    return this.subscription != null;
  }

  enableBarcodeScanned(): void {
    if (this.isEnableBarcodeScanned) {
      return;
    }
    this.subscription = this.barcodeReaderService.barcodeScanned.subscribe((x: string) => this.onBarcodeScanned(x));
    this.barcodeLedEnable(true);
  }

  disabledBarcodeScanned(): void {
    if (!this.isEnableBarcodeScanned) {
      return;
    }
    this.barcodeLedEnable(false);
    if (this.subscription) {
      this.subscription.unsubscribe();
      this.subscription = null;
    }
  }

  private barcodeLedEnable(value: boolean): void {
    this.barcodeReaderService.barcodeLedEnable(value);
  }

  onBarcodeScanned(code: string): void {
    this.userActivity();
    if (!this.context) {
      return;
    }

    // avoid duplicate quick scan
    if (this.lastBarcode && this.lastBarcode === code) {
      this.loggingService.warning(`TicketGridWorkflowService. Duplicate barcode scan blocked. code '${code}', blocking timeout ${this.blockDuplicateScan}`);
      return;
    }

    this.lastBarcode = code;
    const self = this;

    setTimeout(
      () => self.lastBarcode = null,
      this.blockDuplicateScan
    );

    this.displayGridWorkflowService.updateContext({
      barcode: code
    });

    this.navigateToUrlOrNavigationItem(this.displayItem);
  }

  fillExternalApiRequestData(requestData: Map<string, any>, context: any): void {
    if (context.barcode) {
      requestData.set('barcode', context.barcode);
    }
  }
}
