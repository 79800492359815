import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { MatTabsModule } from '@angular/material/tabs';
import { JwBootstrapSwitchNg2Module } from 'jw-bootstrap-switch-ng2';
import { SharedModule } from '../shared/shared.module';
import { VUTranslateModule } from '../vu-translate/vu-translate.module';
import { ComponentsModule } from '../components/components.module';
import { LanguagesModule } from '../languages/Languages.module';
import { SimulatorComponent } from '../../components/simulation/simulator/simulator.component';
import { CashSimulatorComponent } from '../../components/simulation/cash-simulator/cash-simulator.component';
import { VuRoleSimulatorComponent } from '../../components/simulation/vu-role-simulator/vu-role-simulator.component';
import { ScreenSaverSimulatorComponent } from '../../components/simulation/screen-saver-simulator/screen-saver-simulator.component';
import { TurnstileSimulatorComponent } from '../../components/simulation/turnstile-simulator/turnstile-simulator.component';
import { PrinterSimulatorComponent } from '../../components/simulation/printer-simulator/printer-simulator.component';
import { TouchTileSimulatorComponent } from '../../components/simulation/touch-tile-simulator/touch-tile-simulator.component';
import { RfidSimulatorComponent } from '../../components/simulation/rfid-simulator/rfid-simulator.component';
import { CreditCardTerminalSimulatorComponent } from '../../components/simulation/credit-card-terminal-simulator/credit-card-terminal-simulator.component';
import { BarcodeReaderSimulatorComponent } from '../../components/simulation/barcode-reader-simulator/barcode-reader-simulator.component';
import { TicketSimulatorComponent } from '../../components/simulation/ticket-simulator/ticket-simulator.component';
import { DisplayConfigurationSimulatorComponent } from '../../components/simulation/display-configuration-simulator/display-configuration-simulator.component';
import { GateSimulatorComponent } from '../../components/simulation/gate-simulator/gate-simulator.component';
import { CustomCssSimulatorComponent } from '../../components/simulation/custom-css-simulator/custom-css-simulator.component';
import { LogoUpdateSimulatorComponent } from '../../components/simulation/logo-update-simulator/logo-update-simulator.component';
import { LogisticsRequestSimulatorComponent } from '../../components/simulation/logistics-request-simulator/logistics-request-simulator.component';
import { WorkflowSimulatorComponent } from '../../components/simulation/workflow-simulator/workflow-simulator.component';
import { NotificationMessageSimulatorComponent } from '../../components/simulation/notification-message-simulator/notification-message-simulator.component';
import { CioboardEventSimulatorComponent } from '../../components/simulation/cioboard-event-simulator/cioboard-event-simulator.component';
import { FloatTimeComponent } from '../../components/general/float-time/float-time.component';
import { CardDispenserSimulatorComponent } from '../../components/simulation/card-dispenser-simulator/card-dispenser-simulator.component';
import { MoneyExchangeSimulatorComponent } from '../../components/simulation/money-exchange-simulator/money-exchange-simulator.component';
import { OrderSimulatorComponent } from 'src/app/components/simulation/order-simulator/order-simulator.component';


@NgModule({
  declarations: [
    SimulatorComponent,
    CashSimulatorComponent,
    VuRoleSimulatorComponent,
    ScreenSaverSimulatorComponent,
    TurnstileSimulatorComponent,
    PrinterSimulatorComponent,
    TouchTileSimulatorComponent,
    RfidSimulatorComponent,
    CreditCardTerminalSimulatorComponent,
    BarcodeReaderSimulatorComponent,
    TicketSimulatorComponent,
    DisplayConfigurationSimulatorComponent,
    GateSimulatorComponent,
    CustomCssSimulatorComponent,
    LogoUpdateSimulatorComponent,
    LogisticsRequestSimulatorComponent,
    WorkflowSimulatorComponent,
    NotificationMessageSimulatorComponent,
    CioboardEventSimulatorComponent,
    FloatTimeComponent,
    CardDispenserSimulatorComponent,
    MoneyExchangeSimulatorComponent,
    OrderSimulatorComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    SharedModule,
    VUTranslateModule,
    ComponentsModule,
    MatTabsModule,
    LanguagesModule,
    JwBootstrapSwitchNg2Module,
  ],
  exports: [
    SimulatorComponent,
  ]
})
export class SimulatorModule { }
