import { MachineBaseService } from './machine-base.service';
import { MachinePostSaleService } from './machine-post-sale.service';
import { Money, PaymentMethod, Configuration, VisualItem, MachineInactivitySettings } from '../../lib/lib';
import { SaleService } from '../sale.service';
import { MessageType, Message } from '../message.service';
import { StoreHistoryService } from '../store-history.service';
import { OneLineArticleSaleMode } from '../../lib/one-line-article-sale-mode';
import { MachineScreenSaverService } from './machine-screen-saver.service';
import { ModalService } from '../gui/modal/modal-service';
import { ExternalPaymentService } from '../../modules/external-payment/services/external-payment.service';
import { ShopRedirectService } from '../shop-redirect.service';
import { Injectable } from '@angular/core';
import { ExternalUseService } from 'src/app/modules/external-base/services/external-use.service';
import { MachineSaleCashlessService } from './machine-sale-cashless.service';
import { CashlessTransactionResult } from 'src/app/lib/payment/cashless-payment-transaction-result';
import { CashlessPaymentData } from 'src/app/lib/payment/cashless-payment-data';
import { CardDispenserService } from '../card-dispenser/card-dispenser.service';
import { CustomShopInformation } from 'src/app/lib/custom-shop/custom-shop.information';
import { PaymentMethodUtils } from 'src/app/lib/payment/payment-method-utils';
import { AccessGuardService } from 'src/app/modules/access-guard/services/access-guard.service';
import { PrintReceiptEnum } from 'src/app/lib/printer/print-receipt-enum';

@Injectable()
export class MachineSaleShopService extends MachineBaseService {
  private isStopRequested = false;
  private saleService: SaleService;
  private machinePostSaleService: MachinePostSaleService;
  private machineSaleCashlessService: MachineSaleCashlessService;
  private storeHistoryService: StoreHistoryService;
  private machineScreenSaverService: MachineScreenSaverService;
  private modalService: ModalService;
  private externalPaymentService: ExternalPaymentService;
  private externalUseService: ExternalUseService;
  private shopRedirectService: ShopRedirectService;
  private cardDispenserService: CardDispenserService;
  private accessGuardService: AccessGuardService;

  customStore: CustomShopInformation;

  init(): void {
    this.saleService = this.injector.get(SaleService);

    this.machineSaleCashlessService = this.injector.get(MachineSaleCashlessService);
    this.machineSaleCashlessService.eventSwitchedOff.subscribe(() => this.onMachineSaleCashlessServiceSwitchedOff());
    this.machineSaleCashlessService.evenCashlessTransactionEnd.subscribe(
      (x: CashlessTransactionResult) => this.onEventCashlessTransactionEnd(x));

    this.machinePostSaleService = this.injector.get(MachinePostSaleService);
    this.machinePostSaleService.eventSwitchedOff.subscribe(() => this.onMachinePostSaleSwitchedOff());

    this.storeHistoryService = this.injector.get(StoreHistoryService);

    this.accessGuardService = this.injector.get(AccessGuardService);

    super.init();
    this.externalPaymentService = this.injector.get(ExternalPaymentService);
    this.externalUseService = this.injector.get(ExternalUseService);
    this.saleService.eventMoneyChanged.subscribe((x: Money) => this.onMoneyChanged(x));

    this.dispatcherService.onConfigurationChangedSubscribe(x => this.onConfigurationChanged(x));
    this.dispatcherService.eventUserActivity.subscribe(() => this.onUserActivity());

    this.machineScreenSaverService = this.injector.get(MachineScreenSaverService);
    this.modalService = this.injector.get(ModalService);

    this.shopRedirectService = this.injector.get(ShopRedirectService);
    this.cardDispenserService = this.injector.get(CardDispenserService);
  }

  get machineName(): string {
    return 'Sale Machine';
  }

  protected getTransitions(): any[] {
    return super.getTransitions(
      { name: 'toIdle', from: ['off', 'basket', 'displayMode', 'customShop'], to: 'idle' },
      { name: 'toCustomShop', from: ['off', 'basket', 'idle', 'postSale', 'paymentCashless'], to: 'customShop' },
      { name: 'toDisplayMode', from: ['idle', 'displayModePostSale', 'displayModeSaleCashlessService', 'postSale', 'paymentCashless'], to: 'displayMode' },
      { name: 'toBasket', from: ['off', 'idle', 'displayMode', 'customShop', 'postSale', 'paymentCashless'], to: 'basket' },
      { name: 'toPaymentCash', from: ['basket', 'idle', 'displayMode', 'customShop'], to: 'paymentCash' },
      { name: 'toPaymentCashless', from: ['basket', 'idle', 'displayMode', 'customShop'], to: 'paymentCashless' },
      { name: 'toPostSale', from: ['paymentCash', 'paymentCashless'], to: 'postSale' },
      {
        name: 'toDisplayModePostSale', from: [
          'displayMode',
          'paymentCash',
          'paymentCashless',
          'displayModeSaleCashlessService',
        ], to: 'displayModePostSale'
      },
      { name: 'toDisplayModeSaleCashlessService', from: ['basket', 'idle', 'displayMode'], to: 'displayModeSaleCashlessService' },
    );
  }

  protected getMethods(): any {
    const scope = this;
    return super.getMethods({
      onToOff: (event: any, isHardReset = false) => {
        if (this.cardDispenserService.isHasTemporaryCard) {
          this.cardDispenserService.stopTakeCardFromCustomer();
        }
        const abortForExternalUseService = scope.saleService.paymentSession.isCancelled && scope.externalUseService.isEnabled;
        scope.saleService.closePaymentSession();
        scope.isStopRequested = false;

        if (scope.customStore && scope.cardDispenserService.inTransaction) {
          scope.cardDispenserService.stopTakeCardFromCustomer();
        }

        scope.customStore = null;
        scope.storeHistoryService.reset();
        if (this.externalPaymentService.isEnabled) {
          this.externalPaymentService.deactivate();
        }

        this.accessGuardService.reset();

        scope.do(() => {
          if (this.externalPaymentService.isInitializeInProgress || this.externalUseService.isInitializeInProgress) {
            return;
          }

          if (abortForExternalUseService) {
            return;
          }

          if (this.shopRedirectService.tryRedirectToShop()) {
            return;
          }

          scope.router.navigate([scope.storeUrl, { displayMode: 'MainScreen' }]);
        }, 'onToIdle');
      },
      onToIdle: () => {
        this.dispatcherService.onUserActivity();
        if (this.externalUseService.isEnabled) {
          scope.doAsync(() => {
            scope.machine.toDisplayMode();
          }, 'onToIdle');
          return;
        }
        if (this.externalPaymentService.isEnabled) {
          this.externalPaymentService.activate();
          scope.doAsync(() => {
            scope.machine.toDisplayMode();
          }, 'onToIdle');
          return;
        }
        if (scope.saleService.order.isPrefilledOrder) {
          scope.doAsync(() => scope.machine.toBasket(), 'onToIdle');
          return;
        }
        if (!scope.customStore || !scope.customStore.url) {
          scope.do(() => scope.router.navigateByUrl(scope.storeUrl), 'onToIdle');
        }
        if (scope.configurationService.showArticlesOnMainPage) {
          scope.doAsync(() => {
            if (scope.storeHistoryService.isEmpty) {
              scope.machineScreenSaverService.machineStart();
            }
          }, 'onToIdle');
        } else {
          if (this.gridMode) {
            scope.doAsync(() => {
              scope.machine.toDisplayMode();
            }, 'onToIdle');
          }
        }

        if (scope.customStore?.url) {
          scope.doAsync(() => {
            scope.machine.toCustomShop(scope.customStore.url);
          }, 'onToIdle');
        }
      },
      onToBasket: () => {
        scope.doAsync(() => {
          scope.machineScreenSaverService.machineStop();
        }, 'onToPostSale');
        scope.do(() => scope.router.navigateByUrl('/basket'), 'onToBasket');
      },
      onToPaymentCash: () => {
        scope.saleService.order.isReceipt = this.configurationService.configuration.printOrderReceipt === PrintReceiptEnum.ForcePrint;
        if (scope.saleService.order.isTotalAmountZero) {
          if (scope.saleService.order.amountGiftPartial) {
            scope.saleService.openPaymentSessionWithFloatingAmount(
              PaymentMethod.Cash, new Money(0, 'EUR'), scope.saleService.order.amountTotal);
          }
          scope.doAsync(() => scope.machine.toPostSale(), 'onToPaymentCash');
          return;
        }
        scope.doAsync(() => {
          scope.machineScreenSaverService.machineStop();
        }, 'onToPaymentCash');
        scope.saleService.openPaymentSession(PaymentMethod.Cash, scope.saleService.order.amountTotal);
        scope.do(() => scope.router.navigateByUrl('/payment-cash'), 'onToPaymentCash');
        scope.log.info(`MachineSaleShopService. Pay Order: ${scope.saleService.order}`);
      },
      onToPaymentCashless: (event: any, cashlessPaymentData: CashlessPaymentData) => {
        scope.doAsync(() => {
          scope.machineScreenSaverService.machineStop();
        }, 'onToPaymentCashless');
        scope.saleService.order.isReceipt = this.configurationService.configuration.printOrderReceipt === PrintReceiptEnum.ForcePrint;
        scope.machineSaleCashlessService.paymentData = cashlessPaymentData;
        scope.doAsync(() => scope.machineSaleCashlessService.machineStart(), 'onToPaymentCashless');
      },
      onToPostSale: () => {
        scope.doAsync(() => {
          scope.machineScreenSaverService.machineStop();
        }, 'onToPostSale');
        scope.dispatcherService.cashDevicesStatePayIn(false);
        scope.machinePostSaleService.externalPayment = this.isExternalPaymentEnabled;
        scope.do(() => scope.machinePostSaleService.machineStart(), 'onToPostSale');
      },
      onToDisplayModePostSale: (event: any, abortPayment: boolean = false) => {
        scope.doAsync(() => {
          scope.machineScreenSaverService.machineStop();
        }, 'onToDisplayModePostSale');
        scope.dispatcherService.cashDevicesStatePayIn(false);
        scope.machinePostSaleService.disableNavigation = true;
        scope.saleService.paymentSession.isCancelled = abortPayment;
        scope.do(() => scope.machinePostSaleService.machineStart(), 'onToDisplayModePostSale');
      },
      onToDisplayModeSaleCashlessService: (event: any, abortCashlessPayment: boolean = false) => {
        scope.machineSaleCashlessService.disableNavigation = true;
        scope.saleService.paymentSession.isCancelled = abortCashlessPayment;
        scope.machineSaleCashlessService.paymentData = new CashlessPaymentData(PaymentMethod.PaymentCard);
        scope.do(() => scope.machineSaleCashlessService.machineStart(), 'onToDisplayModeSaleCashlessService');
      },
      onToCustomShop: (event: any, customShopUrl: string) => {
        scope.do(() => scope.router.navigateByUrl(customShopUrl), 'onToCustomShop');
        scope.log.info(`MachineSaleShopService. Show custom shop: ${customShopUrl}`);
      },
    });
  }

  get isExternalPaymentEnabled(): boolean {
    return this.externalPaymentService.isEnabled || this.externalUseService.isEnabled;
  }

  get storeUrl(): string {
    if (this.gridMode) {
      return '/grid-visual-items/store';
    } else {
      return '/visual-items/store';
    }
  }

  get gridMode(): boolean {
    return this.configurationService && this.configurationService.configuration
      && !!this.configurationService.configuration.displayConfigurationId;
  }

  machineStart(): void {
    if (this.isOff) {
      this.machine.toIdle();
    }
  }

  machineStop(): void {
    if (this.isOff) {
      return;
    }
    this.isStopRequested = true;
    this.machineTryToStop();
  }

  machineStopCancel(): void {
    this.isStopRequested = false;
  }

  navigateToCustomShop(customShopUrl: CustomShopInformation): void {
    this.customStore = customShopUrl;
    if (customShopUrl?.url) {
      this.machine.toCustomShop(customShopUrl.url);
    }
    this.machineScreenSaverService.machineStart();
  }

  private machineTryToStop(): void {
    if (this.isStopRequested
      &&
      !this.isInState('paymentCash', 'postSale', 'paymentCashless', 'displayModePostSale', 'displayModeSaleCashlessService')
      &&
      !this.isDisplayModePaymentStarted
    ) {
      this.machine.toOff();
    }
  }

  private get isDisplayModePaymentStarted(): boolean {
    return this.isInState('displayMode')
      && !!this.saleService.paymentSession
      && this.saleService.paymentSession.isSessionOpen;
  }

  protected onAfterTransition(event: any): void {
    this.doAsync(() => this.machineTryToStop(), 'onAfterTransition');
    super.onAfterTransition(event);
  }

  onMachinePostSaleSwitchedOff(): void {
    if (this.saleService.paymentSession.isCancelled && this.externalUseService.isEnabled) {
      this.externalUseNavigateToCancelOperation();
      return;
    }

    if (this.shopRedirectService.tryRedirectToExternalBackUrl(this.saleService.paymentSession.isCancelled)) {
      return;
    }

    if (this.state === 'postSale') {
      if (this.machinePostSaleService.isBackToHomeAfterSale) {
        if (this.externalUseService.isEnabled && this.externalUseService.completeOperationURL) {
          this.machine.toDisplayMode();
          this.externalUseService.completeOrderId = this.saleService?.order?.id;
          this.saleService.closePaymentSession();
          this.router.navigateByUrl(this.externalUseService.completeOperationURL);
          return;
        }
        this.machine.toOff();
      } else {
        if (this.saleService.buyOrderInOneClick) {
          this.saleService.closePaymentSession();
          this.machine.toOff();
        } else {
          if (this.customStore?.returnUrl && this.customStore?.skipBasket) {
            this.machine.toCustomShop(this.customStore.returnUrl);
          } else {
            this.machine.toBasket();
          }
        }
      }
    }

    if (this.state === 'displayModePostSale') {
      this.machine.toDisplayMode();
      this.dispatcherService.displayModePaymentComplete(!this.saleService.paymentSession.isCancelled);
      if (!this.saleService.paymentSession.isCancelled) {
        this.saleService.closePaymentSession();
      }
    }
  }

  externalUseNavigateToCancelOperation(): void {
    if (this.externalUseService.cancelOperationURL) {
      this.machine.toDisplayMode();
      this.router.navigateByUrl(this.externalUseService.cancelOperationURL);
    } else {
      this.machine.toOff();
    }
  }

  onMachineSaleCashlessServiceSwitchedOff(): void {
  }

  onEventCashlessTransactionEnd(transactionResult: CashlessTransactionResult): void {
    if (!this.isInState('paymentCashless', 'displayModeSaleCashlessService')) {
      return;
    }
    const scope = this;
    this.machineSaleCashlessService.switchOff(() => {
      switch (transactionResult) {
        case CashlessTransactionResult.Canceled:
          if (this.externalUseService.isEnabled) {
            this.externalUseNavigateToCancelOperation();
            return;
          }

          if (this.shopRedirectService.tryRedirectToExternalBackUrl(true)) {
            return;
          }
          if (scope.state === 'displayModeSaleCashlessService') {
            scope.machine.toDisplayMode();
            scope.dispatcherService.displayModeCashlessPaymentComplete(false);
          } else {
            if (scope.saleService.buyOrderInOneClick) {
              scope.machine.toOff();
            } else {
              if (this.customStore?.returnUrl && this.customStore?.skipBasket) {
                this.machine.toCustomShop(this.customStore.returnUrl);
              } else {
                scope.machine.toBasket();
                scope.enableBackButton(true);
              }
            }
          }
          break;
        case CashlessTransactionResult.Completed:
          if (scope.state === 'displayModeSaleCashlessService') {
            scope.machine.toDisplayMode();
            scope.dispatcherService.displayModeCashlessPaymentComplete(true);
          } else {
            scope.machine.toPostSale();
          }
          break;
        default:
          scope.log.error(`onEventCashlessTransactionEnd.  CashlessTransactionResult not supported: '${transactionResult}'`);
      }
    });
  }

  protected getMessages(): MessageType[] {
    return super.getMessages(
      MessageType.ButtonBackClicked,
      MessageType.Back,
      MessageType.VisualItemLeafSelected,
      MessageType.ToPaymentCash,
      MessageType.ToPaymentCard,
      MessageType.ToPaymentBluecode,
      MessageType.ToPaymentGiftCard,
      MessageType.DisplayModeScreenUpdate,
      MessageType.ToBasket,
      MessageType.ToDisplayModePayment,
      MessageType.ToDisplayModeCashlessPayment,
      MessageType.ExternalUseCompleted,
      MessageType.AddProductsComplete,
      MessageType.ReloadInactivityTracking,
    );
  }

  protected onMessage(message: Message): boolean {
    if (super.onMessage(message)) {
      return true;
    }

    const state = this.state;
    switch (message.messageType) {
      case MessageType.Back:
        if (state === 'customShop') {
          this.customStore = null;
          this.machine.toIdle();
          return;
        }
        this.machine.toOff();
        break;
      case MessageType.ButtonBackClicked:
        if (state === 'paymentCash') {
          this.saleService.paymentSession.isCancelled = true;
          this.machine.toPostSale();
        } else if (state === 'basket') {
          if (this.cardDispenserService.isHasTemporaryCard) {
            this.cardDispenserService.stopTakeCardFromCustomer();
          }
          if (this.isExitRole) {
            this.machine.toOff();
          } else {
            if (this.configurationService.configuration.oneLineArticleSaleMode !== OneLineArticleSaleMode.Disabled) {
              this.saleService.closePaymentSession();
            }

            if (this.customStore?.returnUrl) {
              this.machine.toCustomShop(this.customStore.returnUrl);
            } else {
              this.machine.toIdle();
            }
          }
        }
        break;
      case MessageType.VisualItemLeafSelected:
        const visualItem = message.info as VisualItem;
        if (visualItem?.product?.buyInOneClick) {
          break;
        }
        if (state === 'idle' || state === 'displayMode') {
          if (this.externalUseService.isEnabled && this.externalUseService.navigateToBasket) {
            this.externalUseService.navigateToBasket();
            return;
          }

          this.machine.toBasket();
        }
        break;
      case MessageType.AddProductsComplete:
        if (state === 'idle') {
          this.toPaymentByJournal(this.saleService.buyOrderInOneClickJournal);
        }
        break;
      case MessageType.ToPaymentCash:
        if (state === 'basket' || this.isExternalPaymentEnabled || message.info?.force || this.customStore?.skipBasket) {
          this.machine.toPaymentCash();
        }
        break;
      case MessageType.ToPaymentCard:
        if (state === 'basket' || this.isExternalPaymentEnabled || message.info?.force || this.customStore?.skipBasket) {
          this.machine.toPaymentCashless(new CashlessPaymentData(PaymentMethod.PaymentCard));
        }
        break;
      case MessageType.ToPaymentBluecode:
        if (state === 'basket' || this.isExternalPaymentEnabled || message.info?.force || this.customStore?.skipBasket) {
          this.machine.toPaymentCashless(new CashlessPaymentData(PaymentMethod.Bluecode));
        }
        break;
      case MessageType.ToPaymentGiftCard:
        if (state === 'basket' || this.isExternalPaymentEnabled || message.info?.force || this.customStore?.skipBasket) {
          this.machine.toPaymentCashless(new CashlessPaymentData(PaymentMethod.GiftCard, message.info));
        }
        break;
      case MessageType.DisplayModeScreenUpdate:
        if (this.isExternalPaymentEnabled) {
          break;
        }
        switch (state) {
          case 'idle':
            if (!this.storeHistoryService.isEmpty) {
              this.machine.toDisplayMode();
            }
            break;
          case 'displayMode':
            if (this.storeHistoryService.isEmpty) {
              this.modalService.closeAll();
              this.machine.toIdle();
            }
            break;
        }
        break;
      case MessageType.ToBasket:
        if (state === 'idle' || state === 'customShop') {
          this.machine.toBasket();
        }
        break;
      case MessageType.ToDisplayModePayment:
        if (state === 'idle' || state === 'displayMode') {
          const abortPayment = message.info as boolean;
          this.machine.toDisplayModePostSale(abortPayment);
        }
        break;
      case MessageType.ToDisplayModeCashlessPayment:
        if (state === 'idle' || state === 'displayMode') {
          const abortCashlessPayment = message.info as boolean;
          this.machine.toDisplayModeSaleCashlessService(abortCashlessPayment);
        }
        break;
      case MessageType.ExternalUseCompleted:
        if (state === 'displayMode' && this.externalUseService.isEnabled) {
          this.machine.toOff();
        }
        break;
      case MessageType.ReloadInactivityTracking:
        this.reloadInactivityTracking(message.info as MachineInactivitySettings);
        break;
      default:
        break;
    }

    return false;
  }

  protected get timeoutTrackingStates(): string[] {
    const result = ['basket', 'paymentCash', 'customShop'];
    if (!this.configurationService.showArticlesOnMainPage || this.accessGuardService.isEnabled) {
      result.push('idle');
    }
    if (this.externalUseService?.inactivityMode) {
      result.push('displayMode');
    }
    return result;
  }

  private onMoneyChanged(money: Money): void {
    if (this.state !== 'paymentCash') {
      return;
    }

    this.dispatcherService.onUserActivity();

    const paymentSession = this.saleService.paymentSession;
    if (!paymentSession) {
      this.log.error('MachineSaleShopService. !paymentSession');
      return;
    }

    if (paymentSession.amountRemainingToPay.value <= 0) {
      this.doAsync(() => this.machine.toPostSale(), 'onMoneyChanged');
      this.storeHistoryService.reset();
    }
  }

  protected onMachineTimeoutModalCancel(machineName: string): void {
    if (machineName === this.machineName && (this.state === 'paymentCash')) {
      this.saleService.paymentSession.isCancelled = true;
      this.machine.toPostSale();
      return;
    }
    super.onMachineTimeoutModalCancel(machineName);
  }

  private toPaymentByJournal(journalName: string): boolean {
    const paymentMethod = PaymentMethodUtils.journalNameToPaymentMethod(journalName);
    if (paymentMethod == null) {
      return false;
    }
    switch (paymentMethod) {
      case PaymentMethod.Cash:
        this.machine.toPaymentCash();
        return true;
      case PaymentMethod.PaymentCard:
      case PaymentMethod.Bluecode:
      case PaymentMethod.GiftCard:
        this.machine.toPaymentCashless(new CashlessPaymentData(paymentMethod));
        return true;
      default:
        return false;
    }
  }

  private enableBackButton(isEnabled: boolean): void {
    this.dispatcherService.isBackButtonEnabled = isEnabled;
  }

  onConfigurationChanged(configuration: Configuration): void {
    if (this.isExternalPaymentEnabled || this.customStore) {
      return;
    }

    if (this.state === 'idle' || this.state === 'displayMode') {

      if (!configuration.displayConfigurationId) {
        this.router.navigateByUrl('/visual-items/store');
      } else {
        this.router.navigateByUrl('/grid-visual-items/store');
      }

      this.storeHistoryService.checkAndRequestVisualItemsUpdate(configuration);
    }
  }

  private onUserActivity(): void {
    this.machineScreenSaverService.machineStop();
    if (
      (this.state === 'idle' || this.state === 'displayMode' || this.state === 'customShop')
      &&
      this.configurationService.showArticlesOnMainPage && this.storeHistoryService.isEmpty) {
      this.machineScreenSaverService.machineStart();
    }
  }

  protected getMachineInactivitySettings(state: string): MachineInactivitySettings {
    switch (state) {
      default:
        return super.getMachineInactivitySettings(state);
    }
  }
}
