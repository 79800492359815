import { DictionaryHelper } from '../../dictionary-helper';
import { DisplayItem } from '../display-item/display-item';
import { DisplayConfigurationPageCaptionEnum } from './display-configuration-page-caption.enum';
import { Product } from '../../product';
import { DisplayItemTree } from '../display-item/display-item-tree';
import { VuCommunicationService } from '../../../services/vu/vu-communication.service';
import { DisplayConfigurationExtendedDisplayEnum } from './display-configuration-extended-display.enum';
import { TranslatePropertyHelper } from '../../languages/translation-properties-helper';


export class DisplayConfiguration {
  private _columnsCount: number = null;
  private _rowCount: number = null;
  private _dictionaryHelper: DictionaryHelper;
  properties: any;
  private _displayItemTree: DisplayItemTree;
  private _extendedDisplay: DisplayConfigurationExtendedDisplayEnum = null;
  private translatePropertyHelper: TranslatePropertyHelper;
  private _pageCaptionType: DisplayConfigurationPageCaptionEnum = null;

  static fromOther(properties: any): DisplayConfiguration {
    if (properties == null) {
      return null;
    }
    return new DisplayConfiguration(properties);
  }

  constructor(
    properties: {},
  ) {
    this._dictionaryHelper = new DictionaryHelper(properties);
    this.properties = properties;
    this.translatePropertyHelper = new TranslatePropertyHelper(properties ? properties['translations'] : null);
    this._displayItemTree = DisplayItemTree.build(
      this._dictionaryHelper.getProperty('items'),
      this._dictionaryHelper.getProperty('items_tree'),
      this._dictionaryHelper.getProperty('additional_items_tree')
    );
  }

  toString(): string {
    const m = `properties: ${this._dictionaryHelper.properties}. `;
    return m;
  }

  get columnsCount(): number {
    if (this._columnsCount != null) {
      return this._columnsCount;
    }
    return this._dictionaryHelper.getNumber('columns_count');
  }

  set columnsCount(value: number) {
    this._columnsCount = value;
  }

  get rowCount(): number {
    if (this._rowCount != null) {
      return this._rowCount;
    }
    return this._dictionaryHelper.getNumber('row_count');
  }

  set rowCount(value: number) {
    this._rowCount = value;
  }

  get name(): string {
    return this._dictionaryHelper.getString('name');
  }

  translateName(language: string): string {
    return this.translatePropertyHelper.getTranslationByFieldName(language, 'name', this.name);
  }

  get pageCaptionType(): DisplayConfigurationPageCaptionEnum {
    if (this._pageCaptionType == null) {
      const pageCaptionType = this._dictionaryHelper.getString('page_caption_type');
      return DisplayConfigurationPageCaptionEnum[pageCaptionType] || DisplayConfigurationPageCaptionEnum.Automatic;
    }

    return this._pageCaptionType;
  }

  set pageCaptionType(value: DisplayConfigurationPageCaptionEnum) {
    this._pageCaptionType = value;
  }

  get items(): DisplayItem[] {
    return this._displayItemTree.nodes;
  }

  get allItems(): DisplayItem[] {
    return this._displayItemTree.items;
  }

  get itemsIds(): number[] {
    return this._displayItemTree.itemsIds;
  }

  loadProducts(vuCommunicationService: VuCommunicationService): Promise<any> {
    return this._displayItemTree.fillProducts(vuCommunicationService);
  }

  get backgroundId(): number {
    return this._dictionaryHelper.getNumber('background');
  }

  set extendedDisplay(value: DisplayConfigurationExtendedDisplayEnum) {
    this._extendedDisplay = value;
  }

  get extendedDisplay(): DisplayConfigurationExtendedDisplayEnum {
    if (this._extendedDisplay == null) {
      const extendedDisplay = this._dictionaryHelper.getString('extended_display');
      this._extendedDisplay = DisplayConfigurationExtendedDisplayEnum[extendedDisplay] || DisplayConfigurationExtendedDisplayEnum.None;
    }
    return this._extendedDisplay;
  }
}
