import { IVuHttp } from '../services/vu/http/vu-http.interface';
import { ILog } from '../services/logging/log.interface';
import { Money } from './lib';
import { PaymentMethod } from './payment/payment-method';
import { Observable, of } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';

export class RemotePaymentTransaction {
  private log: ILog;
  private vuHttp: IVuHttp;
  private isTransactionOpen = false;
  private internalIsFloatingAmount: boolean;

  constructor(vuHttp: IVuHttp, log: ILog) {
    this.vuHttp = vuHttp;
    this.log = log;
  }

  // tslint:disable-next-line:max-line-length
  openRemoteTransaction(paymentMethod: PaymentMethod, amount: Money = null, isFloatingAmount = false, giftName: string = null, giftAmount: Money = null): Promise<void> {

    const str = PaymentMethod[paymentMethod];
    this.internalIsFloatingAmount = isFloatingAmount;
    this.log.info(`openRemoteTransaction. paymentMethod:  '${str}'. amount: '${amount}'. isFloatingAmount: '${isFloatingAmount}'`);
    this.isTransactionOpen = true;
    if (amount == null) {
      this.log.info('openRemoteTransaction. No need to open');
      return Promise.resolve();
    }

    return this.vuHttp.beginPaymentTransaction(amount, paymentMethod, giftName, giftAmount);
  }

  closeRemoteTransaction(isToCommit: boolean, context: string, callback: any = null, force: boolean = false, allowRefundAngular: boolean = false): Promise<void> {
    // TODO: Refactor to return a promise
    const safeCallback = () => {
      if (callback) {
        callback();
      }
    };

    this.log.info(`closeRemoteTransaction. isToCommit: '${isToCommit}'. context: '${context}'.`);
    if (!this.isTransactionOpen) {
      this.log.warning(`closeRemoteTransaction. Already closed`);
      safeCallback();
      return;
    }
    this.isTransactionOpen = false;
    if (isToCommit) {
      return this.vuHttp.commitPaymentTransaction(this.isFloatingAmount || force).then(() => {
        safeCallback();
      });
    } else {
      return this.revertPaymentTransaction(allowRefundAngular, callback);
    }
  }

  private revertPaymentTransaction(allowRefundAngular: boolean, callback: any): Promise<void> {
    const func = isError => {
      const isCallbackDefined = callback !== null;
      this.log.info(
        `revertPaymentTransaction. Finished. isError: '${isError}'. isCallbackDefined: '${isCallbackDefined}'`
      );
      if (isCallbackDefined) {
        callback();
      }
    };

    this.log.info('revertPaymentTransaction. Started.');

    return this.vuHttp
      .revertPaymentTransaction(allowRefundAngular)
      .then(x => func(false))
      .catch(x => func(true));
  }

  reset(): void {
    this.isTransactionOpen = false;
  }

  appendTransactionInfo(info: string): void {
    this.vuHttp
      .appendTransactionInfo(info);
  }

  get isOpened(): boolean {
    return this.isTransactionOpen;
  }

  get isFloatingAmount(): boolean {
    return this.internalIsFloatingAmount;
  }

  resetRemoteTransaction(): Observable<boolean> {
    return of(true)
      .pipe(
        tap(()=> {
          this.log.info('resetRemoteTransaction. Started.');
        }),
        switchMap(() => {
          this.reset();
          return this.vuHttp.resetRemoteTransaction();
        }),
        tap(()=> {
          this.log.info('resetRemoteTransaction. Finish.');
        }),
      );
  }
}
