import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { Subscription } from 'rxjs';
import { RfidCardReaderService } from 'src/app/services/rfid-card/rfid-card-reader.service';

@Component({
  selector: 'app-rfid-card-scan',
  templateUrl: './rfid-card-scan.component.html',
  styleUrls: ['./rfid-card-scan.component.css']
})
export class RfidCardScanComponent implements OnInit, OnDestroy {

  @Output()
  rfidCardScanned = new EventEmitter<string>();

  private subscription: Subscription;

  constructor(
    private rfidCardReaderService: RfidCardReaderService,
  ) {
  }

  ngOnInit(): void {
    this.subscription = this.rfidCardReaderService.rfidCardScanned.subscribe((barcode) => this.onRfidCardRead(barcode));
    this.rfidCardReaderService.rfidCardReaderEnable(true);
  }

  ngOnDestroy(): void {
    this.rfidCardReaderService.rfidCardReaderEnable(false);
    if (this.subscription) {
      this.subscription.unsubscribe();
      this.subscription = null;
    }
  }

  private onRfidCardRead(barcode: string): void {
    this.rfidCardScanned.emit(barcode);
  }
}
