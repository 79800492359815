import { Injectable } from '@angular/core';
import { DisplayItemTypeEnum } from '../../../../../lib/display/display-item/display-item-type.enum';
import { DisplayItem } from '../../../../../lib/display/display-item/display-item';
import { Subscription } from 'rxjs';
import { CardDispenserActionResult } from '../../../../../lib/rfid-card/card-dispenser-action-result';
import { CardDispenserBaseGridWorkflowService } from './card-dispenser-base-grid-workflow.service';
import { RfidCardData } from 'src/app/lib/card-dispenser/rfid-card-data';
import { ConfigurationService } from 'src/app/services/configuration/configuration.service';

@Injectable()
export class CardDispenserGridWorkflowService extends CardDispenserBaseGridWorkflowService {

  private subscriptionCardRead: Subscription;

  private subscriptionAvailabilityChanged: Subscription;

  private subscriptionReadCardData: Subscription;

  private requestRfidCardData: RfidCardData;

  private lastReadCardEventDateTime: Date;

  private configurationService: ConfigurationService;

  get displayItemType(): DisplayItemTypeEnum {
    return DisplayItemTypeEnum.CardDispenser;
  }

  init(
  ) {
    super.init();
    this.configurationService = this.injector.get(ConfigurationService);
  }

  show(displayItem: DisplayItem, context: any): void {
    super.show(displayItem, context);

    const requestRfidCardData = this.configurationService?.configuration?.originalConfigurationHelper?.getString('smart_card_data_to_read');
    if (requestRfidCardData) {
      var rfidCardData = new RfidCardData();
      rfidCardData.data = requestRfidCardData;
      this.requestRfidCardData = rfidCardData;
    }
    this.enableRfidCardScanned();
  }

  hide(displayItem: DisplayItem, context: any): void {
    if (!this.cardDispenserService.isShowWaitRemoveCardDialog) {
      this.disabledRfidCardScanned();
    }
    super.hide(displayItem, context);
  }

  hideAll(): void {
    super.hideAll();
    this.disabledRfidCardScanned();
    this.requestRfidCardData = null;
  }

  reset(): void {
    super.reset();
    if (this.cardDispenserService.cardCode) {
      this.cardDispenserService.releaseCardCustomer(true);
    } else if (this.cardDispenserService.inTransaction) {
      this.cardDispenserService.stopTransaction();
    }
  }

  get isEnableRfidCardScanned(): boolean {
    return this.subscriptionCardRead != null;
  }

  enableRfidCardScanned() {
    if (this.isEnableRfidCardScanned && this.cardDispenserService.isShowWaitRemoveCardDialog) {
      return;
    }

    if (!this.cardDispenserService.isAvailable) {
      this.loggingService.warning('CardDispenserGridWorkflowService. enableRfidCardScanned. Card dispenser not available');
      this.showCardDispenserNotAvailableAndBackToParentPage();
      return;
    }

    this.subscriptionCardRead = this.cardDispenserService.cardRead.subscribe((x: CardDispenserActionResult) => this.onCardRead(x));
    this.subscriptionAvailabilityChanged = this.cardDispenserService.availabilityChanged.subscribe(
      (x: CardDispenserActionResult) => this.onAvailabilityChanged(x));
    this.cardDispenserTakeCard(true);
    this.subscriptionReadCardData = this.cardDispenserService.readCardDataCompleted.subscribe(
      (x: CardDispenserActionResult) => this.onReadCardDataCompleted(x)
    );
  }

  disabledRfidCardScanned() {
    if (!this.isEnableRfidCardScanned) {
      return;
    }

    this.cardDispenserTakeCard(false);

    if (this.subscriptionCardRead) {
      this.subscriptionCardRead.unsubscribe();
      this.subscriptionCardRead = null;
    }

    if (this.subscriptionAvailabilityChanged) {
      this.subscriptionAvailabilityChanged.unsubscribe();
      this.subscriptionAvailabilityChanged = null;
    }

    if (this.subscriptionReadCardData) {
      this.subscriptionReadCardData.unsubscribe();
      this.subscriptionReadCardData = null;
    }
  }

  private cardDispenserTakeCard(value: boolean) {
    if (!this.cardDispenserService.isAvailable) {
      return;
    }

    try {
      if (value) {
        this.cardDispenserService.takeCardFromCustomer().catch(() => {
          this.loggingService.warning('CardDispenserGridWorkflowService. cardDispenserTakeCard. takeCardFromCustomer not started');
        });
      } else {
        if (!this.cardDispenserService.cardCode) {
          this.cardDispenserService.stopTakeCardFromCustomer();
        }
      }
    } catch {
      this.loggingService.warning('CardDispenserGridWorkflowService. cardDispenserTakeCard. Card already taken');
    }
  }

  showErrorMessage(title: string, error: any): void {
    super.showErrorMessage(title, error);
    if (this.cardDispenserService.cardCode && this.cardDispenserService.isAvailable) {
      this.cardDispenserService.releaseCardCustomerAndContinueTakingCardFromCustomer();
    }
  }

  onCardRead(cardDispenserActionResult: CardDispenserActionResult): void {
    this.userActivity();
    if (!this.context || !cardDispenserActionResult || cardDispenserActionResult.isFailed || !cardDispenserActionResult.barcode) {
      return;
    }
    this.displayGridWorkflowService.updateContext({
      rfidCardCode: cardDispenserActionResult.barcode
    });

    if (this.requestRfidCardData) {
      this.readCardData();
    } else {
      this.navigateToUrlOrNavigationItem(this.displayItem);
    }
  }

  onAvailabilityChanged(cardDispenserActionResult: CardDispenserActionResult): void {
    if (!cardDispenserActionResult.isAvailable) {
      this.showCardDispenserNotAvailableAndBackToParentPage();
    }
  }

  fillExternalApiRequestData(requestData: Map<string, any>, context: any) {
    if (context.rfidCardCode) {
      requestData.set('barcode', context.rfidCardCode);
    }
    if (context.responseRfidCardData) {
      requestData.set('responseRfidCardData', context.responseRfidCardData?.data || '');
    }
  }

  onReadCardDataCompleted(cardDispenserActionResult: CardDispenserActionResult): void {
    this.userActivity();
    this.loadingSpinnerService.hide();
    if (!cardDispenserActionResult.isAvailable || !cardDispenserActionResult.cardJsonData) {
      this.showCardDispenserNotReadSectorData();
      return;
    }

    if (this.lastReadCardEventDateTime === cardDispenserActionResult.eventDateTime) {
      return;
    }

    if (!isNaN(cardDispenserActionResult.eventDateTime.getDate())) {
      this.lastReadCardEventDateTime = cardDispenserActionResult.eventDateTime;
    }

    this.displayGridWorkflowService.updateContext({
      responseRfidCardData: RfidCardData.createFromAny(cardDispenserActionResult.cardJsonData),
    });

    this.navigateToUrlOrNavigationItem(this.displayItem);
  }

  readCardData(): void {
    this.loadingSpinnerService.show();
    this.cardDispenserService.readRfidCardData(this.requestRfidCardData)
      .then(
        () => { },
        () => {
          this.showCardDispenserNotReadSectorData();
          this.loadingSpinnerService.hide();
        })
      .catch(() => {
        this.showCardDispenserNotReadSectorData();
        this.loadingSpinnerService.hide();
      });
  }

}
