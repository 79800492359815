import { Subject } from 'rxjs';
import { Component } from '@angular/core';
import { BaseModalComponent } from '../base/base-modal-component';

@Component({
  moduleId: module.id,
  selector: 'timeout-modal',
  templateUrl: 'timeout-modal.component.html',
  styleUrls: ['timeout-modal.component.scss'],
})
export class TimeoutModalComponent extends BaseModalComponent {
  private timer: any;
  timeout: number;
  eventClose: Subject<boolean> = new Subject();

  startTimer(timeout: number) {
    this.timeout = timeout;
    let scope = this;
    this.timer = setInterval(() => {
      scope.timeout -= 1;
      if (scope.timeout <= 0) {
        scope.close(false);
      }
    }, 1000);
  }

  close(isContinue: boolean) {
    if (this.timer) {
      clearInterval(this.timer);
    }
    this.timer = null;
    super.close(isContinue);
  }
}
