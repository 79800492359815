import { Injectable, OnDestroy } from '@angular/core';
import { VuCommunicationService } from '../vu/vu-communication.service';

@Injectable()
export class PingStateService implements OnDestroy {
  private readonly interval = 90000;
  private state = true;
  private intervalId: any;

  constructor(
    private vuCommunicationService: VuCommunicationService,
  ) {
  }

  ngOnDestroy(): void {
    this.stopTimer();
  }

  initialize() {
    this.updatePing();
    this.startTimer();
  }

  private updatePing() {
    this.vuCommunicationService.vuHttp.updateAngularPingState()
      .then(value => {
        this.state = value;
      })
      .catch(value => {
        this.state = false;
      });
  }

  get pingState() {
    return this.state;
  }

  private startTimer() {
    this.intervalId = setInterval(() => {
      this.updatePing();
    }, this.interval);
  }

  private stopTimer() {
    clearInterval(this.intervalId);
  }
}
