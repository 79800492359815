import { Component, OnInit } from '@angular/core';
import { MoneyExchangeStateService } from '../../../services/money-exchange/money-exchange-state.service';
import { VuCommunicationService } from '../../../services/vu/vu-communication.service';
import { Money } from '../../../lib/lib';
import { MoneyExchangeSimulatorService } from '../../../services/money-exchange/money-exchange-simulator.service';

@Component({
  selector: 'app-money-exchange-simulator',
  templateUrl: './money-exchange-simulator.component.html',
  styleUrls: ['./money-exchange-simulator.component.css']
})
export class MoneyExchangeSimulatorComponent implements OnInit {

  _acceptedCoins: Money[];
  _acceptedBankotes: Money[];

  constructor(
    private moneyExchangeSimulatorService: MoneyExchangeSimulatorService,
    private vuCommunicationService: VuCommunicationService,
  ) {
  }

  ngOnInit() {
    var acceptedCash = this.moneyExchangeSimulatorService.acceptedCash;
    this._acceptedCoins = acceptedCash.coins;
    this._acceptedBankotes = acceptedCash.banknotes;
  }

  get isEnable(): boolean {
    return this.moneyExchangeSimulatorService.isEnabled;
  }

  set isEnable(value: boolean) {
    this.moneyExchangeSimulatorService.isEnabled = value;
    this.vuCommunicationService.vuHttp.getVuConfiguration().then(
      result => {
        this.vuCommunicationService.vuConnectionSimulator.vuConfigurationChanged(result);
      }
    )
  }

  get acceptedCoins(): Money[] {
    return this._acceptedCoins;
  }

  get acceptedBanknotes(): Money[] {
    return this._acceptedBankotes;
  }

  onMoneyEnableChange(event: any, money: Money) {
    this.moneyExchangeSimulatorService.setAcceptMoney(money, event.currentValue);
  }

  onReadyToMoneyExchange() {
    this.moneyExchangeSimulatorService.readyToMoneyExchange();
  }
}
