import { Component, Input, Output, EventEmitter } from '@angular/core';

export enum KeyboardType {
  All,
  CharsOnly,
  NumbersOnly,
  NonZeroNumbersOnly,
  None,
  Disabled,
  CharsOnlyWithSpace,
}

@Component({
  selector: 'on-screen-keyboard',
  templateUrl: './on-screen-keyboard.component.html',
  styleUrls: ['./on-screen-keyboard.component.scss']
})
export class OnScreenKeyboardComponent {

  @Input()
  customLayout: any[];

  @Input()
  keyboardType = KeyboardType.All;

  @Output()
  keyPress = new EventEmitter();

  get layout(): any[] {
    if (this.customLayout) {
      return this.customLayout;
    }
    return this.defaultLayout;
  }

  getKeyCssClass(key): string {
    let result = '';
    if (key.action=='space') {
      result +=' white-space';
    }

    if (key['key-class']) {
      result +=' ' + key['key-class'];
    }

    return result
  }

  liClicked(key) {
    if (this.isDisabled(key)) {
      return;
    }
    this.keyPress.emit(key);
  }

  isDisabled(line) {

    if (this.keyboardType === KeyboardType.None) {
      return line.action !== "del";
    }


    if (this.keyboardType === KeyboardType.Disabled) {
      return TextTrackCueList;
    }

    if (this.keyboardType === KeyboardType.CharsOnly) {
      if (line.action === "number" || line.action === "zero" || line.action === "space" || line.action === "enter") {
        return true;
      }
    }

    if (this.keyboardType === KeyboardType.CharsOnlyWithSpace) {
      if (line.action === "number" || line.action === "zero" || line.action === "enter") {
        return true;
      }
    }

    if (this.keyboardType === KeyboardType.NumbersOnly) {
      if (line.action === "char" || line.action === "space" || line.action === "enter") {
        return true;
      }
    }

    if (this.keyboardType === KeyboardType.NonZeroNumbersOnly) {
      if (line.action === "char" || line.action === "space" || line.action === "enter" || line.action === "zero") {
        return true;
      }
    }

    return false;
  }

  defaultLayout = [
    {
      "row": [
        { "value": "1", "action": "number" },
        { "value": "2", "action": "number" },
        { "value": "3", "action": "number" },
        { "value": "4", "action": "number" },
        { "value": "5", "action": "number" },
        { "value": "6", "action": "number" },
        { "value": "7", "action": "number" },
        { "value": "8", "action": "number" },
        { "value": "9", "action": "number" },
        { "value": "0", "action": "zero" },
        { "value": "ß", "action": "char" },
        { "value": "←", "action": "del" },
      ]
    },
    {
      "row": [
        { "value": "Q", "action": "char" },
        { "value": "W", "action": "char" },
        { "value": "E", "action": "char" },
        { "value": "R", "action": "char" },
        { "value": "T", "action": "char" },
        { "value": "Z", "action": "char" },
        { "value": "U", "action": "char" },
        { "value": "I", "action": "char" },
        { "value": "O", "action": "char" },
        { "value": "P", "action": "char" },
        { "value": "Ü", "action": "char" },
      ]
    },
    {
      "row": [
        { "value": "A", "action": "char" },
        { "value": "S", "action": "char" },
        { "value": "D", "action": "char" },
        { "value": "F", "action": "char" },
        { "value": "G", "action": "char" },
        { "value": "H", "action": "char" },
        { "value": "J", "action": "char" },
        { "value": "K", "action": "char" },
        { "value": "L", "action": "char" },
        { "value": "Ö", "action": "char" },
        { "value": "Ä", "action": "char" },
      ]
    },
    {
      "row": [
        { "value": "Y", "action": "char" },
        { "value": "X", "action": "char" },
        { "value": "C", "action": "char" },
        { "value": "V", "action": "char" },
        { "value": "B", "action": "char" },
        { "value": "N", "action": "char" },
        { "value": "M", "action": "char" },
      ]
    },
    {
      "row": [
        { "value": " ", "action": "space" },
      ]
    },
    {
      "enter": [
        { "value": "Enter", "action": "enter" }
      ]
    },
  ];
}
