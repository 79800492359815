<div class="payment-cash normal-text">
  <div class="block">
    <div *ngIf="minAmount || maxAmount" class="payment-cash-info">{{ 'Amount' | translate }}:
      <app-min-max-money
        [min]="minAmount"
        [max]="maxAmount">
      </app-min-max-money>
    </div>
    <div class="payment-cash-info">{{ 'The following coins and banknotes are accepted.' | translate }}</div>
    <accepted-cash [text]="'Coins'" [acceptedCash]="acceptedCoins" [cashType]="cashTypeCoin"
      [disabled]="!canCashBeAccepted" (onDenominationClick)="onDenominationClick()"></accepted-cash>
    <accepted-cash [text]="'Banknotes'" [acceptedCash]="acceptedBanknotes" [cashType]="cashTypeBanknote"
      [disabled]="!canCashBeAccepted" (onDenominationClick)="onDenominationClick()"></accepted-cash>
  </div>
  <div class="block">
    <table>
      <tr *ngIf='!isFloatingPaymentModeEnabled'>
        <td [ngClass]="labelCellStyle">{{ 'Amount to pay' | translate}}</td>
        <td [ngClass]="valueCellStyle">{{ amountToPay }}</td>
      </tr>
      <tr>
        <td [ngClass]="labelCellStyle">{{ 'Paid amount' | translate}}</td>
        <td [ngClass]="valueCellStyle">{{ amountPaid }}</td>
      </tr>
      <tr *ngIf='!isFloatingPaymentModeEnabled'>
        <td [ngClass]="labelCellStyle">{{ 'Remaining amount' | translate}}</td>
        <td [ngClass]="valueCellStyle">{{ amountRemainingToPay }}</td>
      </tr>
      <tr *ngIf='viewChangeRow'>
        <td [ngClass]="labelCellStyle">{{ 'Change' | translate}}</td>
        <td [ngClass]="valueCellStyle">{{ changeAmount }}</td>
      </tr>
    </table>
  </div>
  <receipt-switcher [cssClass]="receiptSwitcherStyle"></receipt-switcher>
</div>
