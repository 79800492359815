import { Component, OnInit } from '@angular/core';
import { VuCommunicationService } from '../../../services/vu/vu-communication.service';
import { IVuConnectionSimulator } from '../../../services/vu/connection/vu-connection.interfaces';
import { CardDispenserActionResult } from '../../../lib/rfid-card/card-dispenser-action-result';

@Component({
  selector: 'app-card-dispenser-simulator',
  templateUrl: './card-dispenser-simulator.component.html',
  styleUrls: ['./card-dispenser-simulator.component.css']
})
export class CardDispenserSimulatorComponent implements OnInit {
  private vuSimulator: IVuConnectionSimulator;
  selectedCardId: string;
  rfidCards: Array<string>;

  constructor(
    private vuCommunicationService: VuCommunicationService,
  ) {
    this.vuSimulator = vuCommunicationService.vuConnectionSimulator;
  }

  ngOnInit() {
    this.rfidCards = ['RFID0001', 'RFID0002', 'RFID0003', 'RFID0004', 'RFID0005'];
    this.selectedCardId = '0';
  }

  startTakingRfidCard() {

  }

  stopTakingRfidCard() {

  }

  onProduceCardClick(isFailed: boolean) {
    this.vuSimulator.rfidCardProduced(this.createCardDispenserActionResult(isFailed));
  }

  onReleaseCardClick(isFailed: boolean) {
    this.vuSimulator.rfidCardReleased(this.createCardDispenserActionResult(isFailed));
  }

  onCaptureCardClick(isFailed: boolean) {
    this.vuSimulator.rfidCardCaptured(this.createCardDispenserActionResult(isFailed));
  }

  onCardRemovedClick() {
    this.vuSimulator.rfidCardRemoved(this.createCardDispenserActionResult(false));
  }

  onCardInsertedClick() {
    let result = this.createCardDispenserActionResult(false);
  }

  createCardDispenserActionResult(isFailed: boolean): CardDispenserActionResult {
    return new CardDispenserActionResult(isFailed, this.rfidCards[this.selectedCardId], true);
  }
}
