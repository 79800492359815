<bSwitch switch-on-color='warning' switch-on-text="{{'Enable'| translate }}" switch-off-text="{{'Disabled'| translate }}"
  [(ngModel)]="isEnable" switch-handle-width="100">
</bSwitch>
<ng-container *ngFor="let x of acceptedBanknotes">
  <bSwitch switch-on-color='success' switch-on-text="{{ x }}" switch-off-text="{{ x }}"
    [ngModel]="true" (onChangeState)="onMoneyEnableChange($event, x)" switch-handle-width="90">
  </bSwitch>
</ng-container>
<ng-container *ngFor="let x of acceptedCoins">
  <bSwitch switch-on-color='success' switch-on-text="{{ x }}" switch-off-text="{{ x }}"
    [ngModel]="true" (onChangeState)="onMoneyEnableChange($event, x)" switch-handle-width="90">
  </bSwitch>
</ng-container>
<a type="button" class="btn btn-default" (click)="onReadyToMoneyExchange()">{{
  'Ready to Money Exchange'| translate }}</a>
