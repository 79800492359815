import { Injectable } from '@angular/core';
import { DisplayItemTypeEnum } from '../../../../../lib/display/display-item/display-item-type.enum';
import { BaseGridWorkflowService } from '../../base/base-grid-workflow.service';
import { DisplayItem } from '../../../../../lib/display/display-item/display-item';
import { Money } from '../../../../../lib/lib';
import { from, Subscription } from 'rxjs';
import { CardDispenserBaseGridWorkflowService } from './card-dispenser-base-grid-workflow.service';

@Injectable()
export class CardDispenserRefundGridWorkflowService extends CardDispenserBaseGridWorkflowService {

  private _subscription: Subscription;
  private readonly _returnCardConfirmationUniqueName = 'return_card_confirmation';

  get displayItemType(): DisplayItemTypeEnum {
    return DisplayItemTypeEnum.CardDispenserRefund;
  }

  show(displayItem: DisplayItem, context: any) {
    super.show(displayItem, context);

    if (
      !displayItem || !displayItem.product ||
      !context || !context.odooContext || !context.odooContext.externalContext ||
      context.odooContext.externalContext.MoneyCardBalance == null
    ) {
      return;
    }

    const amount = context.odooContext.externalContext.MoneyCardBalance;
    const refundAmount = new Money(amount, Money.getCurrencyCode(displayItem.product.price))
    let totalAmount = refundAmount;
    if (totalAmount && displayItem.product) {
      totalAmount = totalAmount.add(displayItem.product.subProductsPrice);
    }

    this.displayGridWorkflowService.updateContext({
      refundAmount,
      refundProduct: displayItem.product,
      totalRefundAmount: Money.empty,
    });

    this.cardDispenserService.canCaptureCard = true;

    this.displayGridWorkflowService.changeIsDisabledState(this._returnCardConfirmationUniqueName, true);

    if (context.odooContext.externalContext.RefundNotAllowed) {
      return;
    }

    this._subscription = from(this.vuCommunicationService.vuHttp.howMuchCanPayoutAmount(totalAmount)).subscribe(
      result => {
        const canBePaidOut = result;
        let refundBalanceExcludingDeposit = Money.empty;
        if (canBePaidOut && displayItem.product) {
          refundBalanceExcludingDeposit = canBePaidOut.distract(displayItem.product.subProductsPrice);
          if (refundBalanceExcludingDeposit.isNegative) {
            return;
          }
        }
        this.displayGridWorkflowService.updateContext({
          refundAmount: refundBalanceExcludingDeposit,
          totalRefundAmount: result
        });
        this.displayGridWorkflowService.changeIsDisabledState(this._returnCardConfirmationUniqueName, false);
      }
    );
  }

  hide(displayItem: DisplayItem, context: any) {
    super.hide(displayItem, context);

    if (this._subscription) {
      this._subscription.unsubscribe();
      this._subscription = null;
    }
  }

  fillDisplayItemRenderData(displayItem: DisplayItem, data: Map<string, any>, context: any) {
    super.fillDisplayItemRenderData(displayItem, data, context);

    if (!displayItem || displayItem.type !== DisplayItemTypeEnum.CardDispenserRefund) {
      return;
    }

    data.set('@ReturnProducts', this._getSubProductDisplayInformation(displayItem));
    data.set('@TotalRefundAmount', context.totalRefundAmount);
  }

  private _getSubProductDisplayInformation(displayItem: DisplayItem) {
    let result = '';

    if (displayItem != null && displayItem.product && displayItem.product.subProducts) {
      displayItem.product.subProducts.forEach(
        product => {
          result += `<p>${product.name}: ${product.price}</p>`;
        }
      );
    }

    return result;
  }
}
