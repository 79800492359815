import { CardDispenserActionResult } from './card-dispenser-action-result';

export class CardDispenserCompleteActionResult extends CardDispenserActionResult {
  captured: boolean;

  constructor(
    failed: boolean,
    barcode: string,
    isAvailable: boolean,
    captured: boolean,
  ) {
    super(failed, barcode, isAvailable);

    this.captured = captured;
  }

  public toString(): string {
    let s = super.toString();
    s += `captured: '${this.captured}'. `;
    return s;
  }
}
