import { PaymentMethod } from './payment-method';

export class PaymentMethodUtils {
  public static journalNameToPaymentMethod(journalName: string): PaymentMethod {
    if (!journalName) {
      return null;
    }

    if (journalName.startsWith('CSH')) {
      return PaymentMethod.Cash;
    }
    if (journalName.startsWith('CRD')) {
      return PaymentMethod.PaymentCard;
    }
    if (journalName.startsWith('BC')) {
      return PaymentMethod.Bluecode;
    }
    if (journalName.startsWith('GS')) {
      return PaymentMethod.GiftCard;
    }

    return null;
  }
}
