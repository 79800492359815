<div class="modal-body">
  <h1 class="notification-text">{{ notificationText | translate }}</h1>
  <div *ngIf="timeout" class="timer">
    <span>{{ timeLeft }}</span>
  </div>
  <div class="row">
    <div *ngIf="!isGateOnTheLeftSide" class="col-md-6 text-right">
      <i *ngIf="isAccessAllowed" class="fa fa-long-arrow-right arrow-style"></i>
      <i *ngIf="isAccessDenied" class="fa fa-times cross-style"></i>
      <i *ngIf="isWaiting" class="fa fa-refresh wait-style"></i>
    </div>
    <div [ngClass]="{'col-md-6 text-left': !isGateOnTheLeftSide, 'col-md-6 text-right': isGateOnTheLeftSide}"
      class=" image-div">
      <img class="gate-image" src="./../../../../static/images/galaxy_gate.jpg">
    </div>
    <div *ngIf="isGateOnTheLeftSide" class="col-md-6 text-left">
      <i *ngIf="isAccessAllowed" class="fa fa-long-arrow-left arrow-style"></i>
      <i *ngIf="isAccessDenied" class="fa fa-times cross-style"></i>
      <i *ngIf="isWaiting" class="fa fa-refresh wait-style"></i>
    </div>
  </div>
</div>
