import { Component, Input, Output, EventEmitter } from '@angular/core';
import { Money, CashType } from '../../../lib/lib';

@Component({
  moduleId: module.id,
  selector: 'accepted-cash',
  templateUrl: 'accepted-cash.component.html',
  styleUrls: ['accepted-cash.component.scss']
})
export class AcceptedCashComponent {
  _acceptedCash: Money[] = [];

  @Input()
  text: string;

  @Input()
  cashType: CashType;

  @Input()
  disabled = false;

  @Output()
  onDenominationClick = new EventEmitter<Money>();

  get acceptedCash(): Money[] {
    return this._acceptedCash;
  }

  @Input() set acceptedCash(value: Money[]) {
    this._acceptedCash = value == null ? [] : value;
  }

  get cssClass(): string {
    return `accepted-cash-denomination ${this.disabled ? ' disabled' : ''}`;
  }

  denominationClick(x: Money) {
    this.onDenominationClick.emit(x);
  }

  get showAcceptedCashLabel(): boolean {
    return !!this.text;
  }
}
