export class CustomShopInformation {
  url: string;
  returnUrl: string;
  skipBasket: boolean;

  constructor(url: string, returnUrl = '', skipBasket = false) {
    this.url = url;
    this.returnUrl = returnUrl;
    this.skipBasket = skipBasket;
  }
}
