import { TranslatePropertyHelper } from '../languages/translation-properties-helper';
import { ProductVariant } from './product-variant';

export class ProductWithVariants {
  name: string;
  productTmplId: number;
  variants: ProductVariant[];
  isRequired: boolean;
  private translatePropertyHelper: TranslatePropertyHelper;

  constructor(
    name: string,
    productTmplId: number,
    isRequired: boolean,
    variants: ProductVariant[],
    translations: any,
  ) {
    this.name = name;
    this.productTmplId = productTmplId;
    this.isRequired = isRequired;
    this.variants = variants;
    this.translatePropertyHelper = new TranslatePropertyHelper(translations);
  }

  static createFromAny(data: any): ProductWithVariants {
    if (data == null || data.id === -1) {
      return null;
    }
    const obj = data;
    return new ProductWithVariants(
      obj.name,
      obj.product_tmpl_id,
      obj.is_required,
      ProductVariant.createFromAnyArray(obj.variants),
      obj.translations,
    );
  }

  static createFromAnyArray(array: any): ProductWithVariants[] {
    if (!array) {
      return [];
    }
    return array.map(this.createFromAny).filter(item => item != null);
  }

  translateName(language: string, defaultValue: string = ''): string {
    return this.translatePropertyHelper.getTranslationByFieldName(language, 'name', defaultValue);
  }
}
