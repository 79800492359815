<header *ngIf="isShowHeader || !isProduction" class="header-content">
  <div *ngIf="!isProduction" class="simulator-control">
    <i class="fa fa-cog" (click)="onShowSimulator()"></i>
  </div>
  <simulator *ngIf="showSimulator" [ngClass]="simulatorClassName"></simulator>
  <page-header></page-header>
</header>
<div [ngClass]="mainClassName">
  <div [ngClass]="containerClassName">
    <ngx-spinner bdColor="rgb(255,255,255)" color="#949393" size="large" type="ball-spin-clockwise-fade"></ngx-spinner>
    <router-outlet></router-outlet>
  </div>
  <dummy></dummy>
</div>
<footer *ngIf="isShowFooter" class="footer">
  <div class="footer-content">
    <div class="container" style="width: 100%;">
      <div class="row">
        <div class="col-lg-12" class="browser-warning" *ngIf="detectIE()">
          {{ 'Internet Explorer Not Supported!' | translate }}
        </div>
      </div>
      <div class="row">
        <app-scan-gift-card-qr-code></app-scan-gift-card-qr-code>
      </div>
      <div class="row">
        <div class="col-lg-6 buttons footer-left-bottons">
          <back-button>Back button...</back-button>
          <abort-button>Abort button...</abort-button>
        </div>
        <div class="col-lg-6 buttons">
          <div class="pull-right" style="display:none">
            <simple-button [enabled]="true" [text]="'Call the staff'" (onClick)="onCallStaffClick()"></simple-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</footer>
