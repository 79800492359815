import { Component, Input, ViewEncapsulation } from '@angular/core';
import { WorkflowStepState } from '../../../lib/lib';

@Component({
  moduleId: module.id,
  selector: 'workflow-step-state',
  templateUrl: 'workflow-step-state.component.html',
  styleUrls: ['workflow-step-state.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class WorkflowStepStateComponent {
  @Input() state: WorkflowStepState;

  get cssClass(): string {
    switch (this.state) {
      case WorkflowStepState.Wait:
        return this.buildCssClass('fa-hourglass-half green');
      case WorkflowStepState.CompleteSuccess:
        return this.buildCssClass('fa-check green');
      default:
        break;
    }
    return this.buildCssClass('fa-frown-o red');
  }

  private buildCssClass(iconClass: string): string {
    return `fa ${iconClass}`;
  }
}
