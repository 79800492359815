import { Subscription } from 'rxjs';
import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { Money, CashType, GlobalSettings, Order, PaymentSession, NonOperationalState } from '../../../lib/lib';
import { ConfigurationService } from '../../../services/configuration/configuration.service';
import { DispatcherService } from '../../../services/dispatcher.service';
import { SaleService } from '../../../services/sale.service';
import { VuCommunicationService } from '../../../services/vu/vu-communication.service';
import { NonOperationalService } from '../../../services/non-operational.service';
import { DemoPaymentService } from '../../../services/payment/demo-payment.service';
import { PrintReceiptEnum } from '../../../lib/printer/print-receipt-enum';
import { ExternalPaymentService } from '../../../modules/external-payment/services/external-payment.service';
import { RefundPaymentActionEnum } from 'src/app/lib/payment/refund-payment-action-enum';

@Component({
  moduleId: module.id,
  selector: 'payment-cash',
  templateUrl: 'payment-cash.component.html',
  styleUrls: ['payment-cash.component.scss']
})
export class PaymentCashComponent implements OnInit, OnDestroy {
  order: Order;
  paymentSession: PaymentSession;
  acceptedCoins: Money[] = [];
  acceptedBanknotes: Money[] = [];
  canCashBeAccepted = true;
  nonOperationalSubscription: Subscription;
  private isBackButtonVisible: boolean;

  @Input() isCompactModeEnabled: boolean;

  @Input()
  hideBackButton: boolean;

  constructor(
    public configurationService: ConfigurationService,
    private dispatcherService: DispatcherService,
    private saleService: SaleService,
    private vuCommunicationService: VuCommunicationService,
    private nonOperationalService: NonOperationalService,
    private demoPaymentService: DemoPaymentService,
    private externalPaymentService: ExternalPaymentService,
  ) {
    this.order = saleService.order;
    this.paymentSession = saleService.paymentSession;
  }

  ngOnInit(): void {
    this.isBackButtonVisible = this.dispatcherService.isBackButtonVisible;
    this.dispatcherService.isBackButtonVisible = !this.hideBackButton && this.allowReturnMoney;
    this.dispatcherService.pageHeaderSet('Payment in cash');
    this.dispatcherService.backButtonTextSet('Cancel');
    this.nonOperationalSubscription = this.nonOperationalService
      .eventStateChanged
      .subscribe((x: NonOperationalState) => this.canCashBeAccepted = !x.isNonOperational);
    const amountToCalculateAcceptedCash =
      this.paymentSession.isFloatingAmount ? this.paymentSession.maxAmount : this.paymentSession.amountToPay;
    this.vuCommunicationService.vuHttp.getAcceptedCash(amountToCalculateAcceptedCash).then(x => {
      if (x) {
        this.acceptedCoins = x.coins;
        this.acceptedBanknotes = x.banknotes;
      }
    });
    this.externalPaymentService.activateComplete();
  }

  ngOnDestroy(): void {
    this.dispatcherService.isBackButtonVisible = this.isBackButtonVisible;
    this.dispatcherService.pageHeaderSet();
    this.dispatcherService.backButtonTextReset();
    this.nonOperationalSubscription.unsubscribe();
  }

  get currencyCode() {
    return GlobalSettings.getCurrencyCode();
  }

  get cashTypeCoin(): CashType {
    return CashType.Coin;
  }

  get cashTypeBanknote(): CashType {
    return CashType.Banknote;
  }

  get amountToPay(): string {
    return this.paymentSession ? this.paymentSession.amountToPay.toStringCurrencySign() : 'ERROR';
  }

  get amountPaid(): string {
    return this.paymentSession ? this.paymentSession.amountPaidIn.toStringCurrencySign() : 'ERROR';
  }

  get amountRemainingToPay(): string {
    if (this.isCompactModeEnabled) {
      return this.paymentSession && this.paymentSession.amountRemainingToPay.value > 0 ?
        this.paymentSession.amountRemainingToPay.toStringCurrencySign()
        :
        Money.empty.toString();
    }
    return this.paymentSession ? this.paymentSession.amountRemainingToPay.toStringCurrencySign() : 'ERROR';
  }

  onDenominationClick() {
    this.demoPaymentService.onDenominationClick();
  }

  get labelCellStyle(): string {
    return this.isCompactModeEnabled ? 'compact-mode-label-td' : 'standart-mode-value-td';
  }

  get valueCellStyle(): string {
    return this.isCompactModeEnabled ? 'compact-mode-value-td' : 'standart-mode-value-td';
  }

  get minAmount(): Money {
    return this.paymentSession ? this.paymentSession.minAmount : null;
  }

  get maxAmount(): Money {
    return this.paymentSession ? this.paymentSession.maxAmount : null;
  }

  get isFloatingPaymentModeEnabled(): boolean {
    return this.minAmount && this.minAmount.isPositive || this.maxAmount && this.maxAmount.isPositive;
  }

  get viewChangeRow(): boolean {
    return this.isCompactModeEnabled && this.paymentSession && this.paymentSession.amountChange.value > 0;
  }

  get changeAmount(): string {
    return this.paymentSession ? this.paymentSession.amountPaidOut.toStringCurrencySign() : 'ERROR';
  }

  get receiptSwitcherStyle(): string {
    return this.isCompactModeEnabled ? 'center-receipt-switcher' : 'left-mode-receipt-switcher';
  }

  get allowReturnMoney(): boolean {
    return this.configurationService?.configuration?.refundPaymentAction !== RefundPaymentActionEnum.WithoutRefund ||
    this.configurationService?.configuration?.originalConfigurationHelper?.getBoolean('hide_back_button_on_payment_screen') != true;
  }
}
