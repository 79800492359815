<div class='money-exchange normal-text'>
  <div class="normal-text">{{ 'Accepted money for exchange' | translate }} ({{ 'Only one nominal can be exchanged per transaction' | translate  }}):</div>
  <div class="money" *ngIf="acceptedBanknotes && acceptedBanknotes.length > 0">
    <accepted-cash [text]="'Banknotes'" [acceptedCash]="acceptedBanknotes" [cashType]="cashTypeBanknote" [disabled]="!canCashBeAccepted"></accepted-cash>
  </div>
  <div class="money" *ngIf="acceptedCoins && acceptedCoins.length > 0">
    <accepted-cash [text]="'Coins'" [acceptedCash]="acceptedCoins" [cashType]="cashTypeCoin" [disabled]="!canCashBeAccepted"></accepted-cash>
  </div>
  <div class="money" *ngIf="(!acceptedCoins || acceptedCoins.length == 0) && (!acceptedBanknotes || acceptedBanknotes.length == 0)">
    <div class="normal-text">{{ 'Sorry, no money for exchange' | translate }}</div>
  </div>
</div>
