import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { BaseModalComponent } from 'src/app/components/general/base/base-modal-component';
import { NumberScanModalResult } from 'src/app/lib/scan/number-scan-modal-result';
import { RechargeCardService } from 'src/app/modules/recharge-card/services/recharge-card.service';
import { DispatcherService } from 'src/app/services/dispatcher.service';
import { RfidCardDialogueService } from '../../services/rfid-card-dialogue.service';

@Component({
  selector: 'app-rfid-card-scan-modal',
  templateUrl: './rfid-card-scan-modal.component.html',
  styleUrls: ['./rfid-card-scan-modal.component.css']
})
export class RfidCardScanModalComponent extends BaseModalComponent implements OnInit, OnDestroy {

  private loadCardInformationSubscription: Subscription;

  constructor(
    private rechargeCardService: RechargeCardService,
    private rfidCardDialogueService: RfidCardDialogueService,
    private dispatcherService: DispatcherService,
  ) {
    super();
  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    this.usubscribeLoadCardInformationSubscription();
  }

  onRfidCardScanned(barcode: string): void {

    this.dispatcherService.onUserActivity();

    this.usubscribeLoadCardInformationSubscription();

    let productId = 0;
    if (this.data && this.data.product) {
      productId = this.data.product.id;
    }

    this.loadCardInformationSubscription = this.rechargeCardService.loadCardInformation(barcode, true, productId)
      .subscribe(cardInformation => {
        this.rfidCardDialogueService.closeMessageDialogue();
        if (cardInformation && cardInformation.name) {
          this.close(new NumberScanModalResult(barcode, cardInformation ));
        } else {
          this.rfidCardDialogueService.showFailedScanRfidCardDialogue();
        }
      });
  }

  usubscribeLoadCardInformationSubscription(): void {
    if (this.loadCardInformationSubscription) {
      this.loadCardInformationSubscription.unsubscribe();
      this.loadCardInformationSubscription = null;
    }
  }

}
