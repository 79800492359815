<ng-container *ngIf="variants && variants.length > 0">
  <div class="col-lg-3 product-variant" *ngFor="let variant of variants">
    <a type="button" class="btn btn-item button-base button-medium" (click)="onProductSelect(variant.productId)">
      <div>
        <div class="row">
          <div class="name-div">
            <p>{{ getTranslateVariantName(variant) }}</p>
          </div>
        </div>
      </div>
    </a>
  </div>
</ng-container>
<ng-container *ngIf="!variants || variants.length === 0">
  <div class="not-available-products text-center">
    {{ "Not have available products" | translate }}
  </div>
</ng-container>
