<a type="button" class="btn btn-default" (click)="onTicketValidEnterClick()">{{
    'Valid Ticket: Enter' | translate }}</a>
  <a type="button" class="btn btn-default" (click)="onTicketValidExitClick()">{{
    'Valid Ticket: Exit' | translate }}</a>
  <a type="button" class="btn btn-default" (click)="onTicketValidTodayUsedClick()">{{
    'Valid Ticket: Today used' | translate }}</a>
  <a type="button" class="btn btn-default" (click)="onTicketInvalidUsedClick()">{{
    'Invalid Ticket: Used' | translate }}</a>
  <a type="button" class="btn btn-default" (click)="onTicketInvalidClosedClick()">{{
    'Invalid Ticket: Closed' | translate }}</a>
  <a type="button" class="btn btn-default" (click)="onTicketInvalidOvertimeClick()">{{
    'Invalid Ticket: Overtime' | translate }}</a>
  <a type="button" class="btn btn-default" (click)="onTicketUnknownClick()">{{
    'Unknown Ticket' | translate }}</a>
  <a type="button" class="btn btn-default" (click)="onTicketStaffClick()">{{
    'Staff Ticket' | translate }}</a>
  <a type="button" class="btn btn-default" (click)="onTicketValidTimeClick()">{{
    'Valid Ticket: Time' | translate }}</a>
  <a type="button" class="btn btn-default" (click)="onTicketInvalidTimeClick()">{{
    'Invalid Ticket: Time' | translate }}</a>
  <a type="button" class="btn btn-default" (click)="onTicketValidOutOfScheduleClick()">{{
    'Valid Ticket: Out of schedule' | translate }}</a>
  <a type="button" class="btn btn-default" (click)="onTicketInvalidRandomProductTicketClick()">{{
    'Invalid Ticket: Random Product ticket' | translate }}</a>
  <a type="button" class="btn btn-default" (click)="onVirtualTicketValidClick()">{{
    'Virtual ticket: Valid' | translate }}</a>
  <a type="button" class="btn btn-default" (click)="onVirtualTicketInvalidClick()">{{
    'Virtual ticket: Invalid' | translate }}</a>
  <a type="button" class="btn btn-default" (click)="onTicketFromToValidClick()">{{
    'From/To ticket: Valid' | translate }}</a>
  <a type="button" class="btn btn-default" (click)="onTicketFromToInvalidClick()">{{
    'From/To ticket: Invalid' | translate }}</a>
  <a type="button" class="btn btn-default" (click)="onTicketFromToDisabledClick()">{{
    'From/To ticket: Disabled' | translate }}</a>
