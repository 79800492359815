import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { combineLatest, Subscription } from 'rxjs';
import { LoadingSpinnerService } from 'src/app/services/loading-spinner/loading-spinner.service';
import { ThemeService } from 'src/app/services/theme.service';
import { ExternalUseService } from '../../services/external-use.service';

@Component({
  selector: 'app-external-use-base',
  templateUrl: './external-use-base.component.html',
  styleUrls: ['./external-use-base.component.css']
})
export class ExternalUseBaseComponent implements OnInit, OnDestroy {

  private _activatedRouteParamsSubscription: Subscription;

  constructor(
    private activatedRoute: ActivatedRoute,
    private loadingSpinnerService: LoadingSpinnerService,
    private themeService: ThemeService,
    private externalUseService: ExternalUseService,
  ) {
  }

  ngOnInit(): void {
    this.loadingSpinnerService.show();
    this.themeService.updateCustomCss();

    this._activatedRouteParamsSubscription = combineLatest([this.activatedRoute.params, this.activatedRoute.queryParams])
      .subscribe(([params, queryParams]) => {
        this.externalUseService.name = params?.name;
        this.externalUseService.initialize(queryParams);
        this.externalUseService.activate();
        this.loadingSpinnerService.hide();
      });
  }

  ngOnDestroy(): void {
    if (this._activatedRouteParamsSubscription) {
      this._activatedRouteParamsSubscription.unsubscribe();
      this._activatedRouteParamsSubscription = null;
    }
  }
}
