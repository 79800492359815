import { GlobalSettings, Money } from 'src/app/lib/lib';
import { BaseInfo } from './base-info';
import { TransactionTypeEnum } from './transaction-type.enum';

export class PaymentInfo extends BaseInfo {
  date: Date;
  amount: Money;
  type: TransactionTypeEnum;
  description: string;

  static createFromAny(object: any): PaymentInfo | null {
    if (object === null || object === undefined) {
      return null;
    }
    const paymentInfo = new PaymentInfo();
    paymentInfo.type = TransactionTypeEnum.Debit === object.type
      ? TransactionTypeEnum.Debit : TransactionTypeEnum.Credit;
    paymentInfo.amount = object.amount != null ?
      new Money(object.amount, GlobalSettings.getCurrencyCode()) : Money.empty;
    paymentInfo.date = object.date ? new Date(object.date) : null;
    paymentInfo.description = object.description || '';
    return paymentInfo;
  }
}
