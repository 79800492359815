import { Guid, Money, Product, GlobalSettings } from '../lib';
import { TicketParameters } from '../ticket/ticket-parameters';
import { PaymentMethod } from '../payment/payment-method';

export class OrderLine {

  static readonly originalLineIdOdooFieldName = 'original_line_id';

  private iUid: string = Guid.newGuid();
  private iPrice: Money;
  private iQuantity: number;
  private iAmount: Money;
  private iAmountTax: Money;
  private iBarcode: string;
  private iTicketParameters: TicketParameters;
  private iRfidCard: boolean;
  paymentMethod = PaymentMethod.Cash;

  private iProduct: Product;

  required: boolean;
  minQuantity: number;
  maxQuantity: number;
  data: any;

  id: number;

  properties: Map<string, object> = new Map<string, object>();

  constructor(
    product: Product,
    quantity = 1,
    customPrice: Money = null,
    barcode: string = null,
    ticketParameters: TicketParameters = null,
  ) {
    this.iProduct = product;
    this.iPrice = customPrice == null ? product.price : customPrice;
    this.iQuantity = quantity > 0 ? (product.qtyMin > 0 && quantity < product.qtyMin ? product.qtyMin : quantity) : 0;
    this.iBarcode = barcode;
    this.iTicketParameters = ticketParameters;
    this.iRfidCard = product.rfidCard;
    this.minQuantity = product.qtyMin;
    this.maxQuantity = product.qtyMax;
    this.updateState();
  }

  get uid(): string {
    return this.iUid;
  }

  get name(): string {
    return this.iProduct.name;
  }

  get description(): string {
    return this.iProduct?.description;
  }

  translateName(language: string): string {
    return this.iProduct.translateName(language) || this.iProduct.name;
  }

  get productId(): number {
    return this.iProduct.id;
  }

  get product(): Product {
    return this.iProduct;
  }

  get price(): Money {
    return this.iPrice;
  }

  set price(newPrice: Money) {
    this.iPrice = newPrice;
    this.updateState();
  }

  setCustomPriceValue(value: number): void {
    this.iPrice = new Money(value, this.iPrice ? this.iPrice.currencyCode : 'EUR');
    this.updateState();
  }

  get quantity(): number {
    return this.iQuantity;
  }

  set quantity(count: number) {
    this.iQuantity = count;
    this.updateState();
  }

  get amount(): Money {
    return this.iAmount;
  }

  get amountTax(): Money {
    return this.iAmountTax;
  }

  get taxRate(): number {
    return this.iProduct.tax;
  }

  set barcode(value: string) {
    this.iBarcode = value;
  }

  get barcode(): string {
    return this.iBarcode;
  }

  get ticketParameters(): TicketParameters {
    return this.iTicketParameters;
  }

  get rfidCard(): boolean {
    return this.iRfidCard;
  }

  private updateState(): void {
    const price = this.iPrice;
    this.iAmount = new Money(price.value * this.iQuantity, price.currencyCode);
    this.iAmountTax = new Money(GlobalSettings.numberRound(this.amount.value * (1 - 1 / (1 + this.taxRate)), 4), price.currencyCode);
  }

  public convertToRefundType(): void {
    this.iQuantity = -this.quantity;
    if (this.id && this.properties && !this.properties.has(OrderLine.originalLineIdOdooFieldName)) {
      this.updateProperties(OrderLine.originalLineIdOdooFieldName, this.id);
    }
    this.updateState();
  }

  get additionalProductIds(): number[] {
    if (!this.iProduct) {
      return null;
    }

    return this.iProduct.additionalProductIds;
  }

  containAdditionalProductWithId(productId: number): boolean {
    const additionalProductIds = this.additionalProductIds;
    if (additionalProductIds && additionalProductIds.length > 0) {
      return additionalProductIds.includes(productId);
    }

    return false;
  }

  public toString(): string {
    return `uid: '${this.uid}'. '${this.name}'. price: '${this
      .price}'. quantity: '${this.quantity}'. amount: '${this
        .amount}'. amountTax: '${this.amountTax}'  taxRate: '${this.taxRate}' productId: '${this.
          productId}' barcode: '${this.barcode}' ticketParameters: '${this.ticketParameters}'`;
  }

  updateProperties(key: string, value: any): void {
    if (!key || !this.properties) {
      return;
    }

    this.properties.set(key, value);
  }
}
