import { Injectable } from '@angular/core';
import { PaymentBaseGridWorkflowService } from '../payment-base-workflow.service';
import { DisplayItemTypeEnum } from '../../../../../lib/display/display-item/display-item-type.enum';
import { DisplayItem } from '../../../../../lib/display/display-item/display-item';

@Injectable()
export class PaymentCancellationGridWorkflowService extends PaymentBaseGridWorkflowService {

  get displayItemType(): DisplayItemTypeEnum {
    return DisplayItemTypeEnum.PaymentCancellation;
  }

  show(displayItem: DisplayItem, context: any) {
    super.show(displayItem, context);

    this.startPaymentOperation(true);
  }

  get hardResetProccessDisplayItem(): DisplayItem {
    if (
      (this.saleService.paymentSession.isFloatingAmount && !this.isPaymentConfirmationAvalible)
      || !this.saleService.paymentSession.isFloatingAmount
      ) {
      return this.findDisplayItemByUniqueName('payment_cancellation');
    }
    return null;
  }
}
