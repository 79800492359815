import { Injectable } from '@angular/core';
import { PrintTask } from 'src/app/lib/printer/print-task';
import { PrintTaskType } from 'src/app/lib/printer/print-task-type';
import { ConfigurationService } from '../configuration/configuration.service';
import { LoggingService } from '../logging/logging.service';
import { VuCommunicationService } from '../vu/vu-communication.service';

@Injectable()
export class PrintService {

  readonly ContentTemplateUniqueName = 'custom_template';

  constructor(
    private loggingService: LoggingService,
    private vuCommunicationService: VuCommunicationService,
    private configurationService: ConfigurationService,
  ) {
  }

  printOrderTicket(orderId: number): Promise<any> {
    return this._print(new PrintTask(orderId, PrintTaskType.Ticket));
  }

  printOrderReceipt(orderId: number): Promise<any> {
    return this._print(new PrintTask(orderId, PrintTaskType.Receipt));
  }

  printCreditCardReceipt(orderId: number, terminalReceipt: string): Promise<any> {
    return this._print(new PrintTask(orderId, PrintTaskType.CreditCardReceipt, terminalReceipt));
  }

  printRFIDCardReceipt(printData: any, debug: boolean = false): Promise<any> {
    if (debug) {
      this._appendDebug(printData);
    }

    return this._print(
      new PrintTask(0, PrintTaskType.RFIDCardReceipt, JSON.stringify(printData))
    );
  }

  printMessage(message: string, debug: boolean = false): Promise<any> {
    var printData = {
      message,
    };

    return this.printCustomTemplate(this.ContentTemplateUniqueName, printData, debug);
  }

  printHtmlMessage(message: string, debug: boolean = false): Promise<any> {
    var printData = {
      htmlMessage: message,
    };

    return this.printCustomTemplate(this.ContentTemplateUniqueName, printData, debug);
  }

  printCustomTemplate(templateUniqueName: string, printData: any, debug: boolean = false): Promise<any> {
    if (debug) {
      this._appendDebug(printData);
    }

    const printTask = new PrintTask(
      0,
      PrintTaskType.Custom,
      JSON.stringify(printData),
    );

    printTask.templateUniqueName = templateUniqueName;

    return this._print(printTask);
  }

  private _print(printTask: PrintTask): Promise<any> {
    this.loggingService.info(`PrintService. _print. ${printTask}`);
    return this.vuCommunicationService.vuHttp.print(printTask);
  }

  private _appendDebug(printData: any): void {
    if (!printData
      || !this.configurationService.configuration
      || !this.configurationService.configuration.propertiesHelper) {
      return;
    }

    printData['debug'] = {
      device_name: this.configurationService.configuration.propertiesHelper.getString('name'),
      device_serial: this.configurationService.configuration.propertiesHelper.getString('serial'),
    };
  }
}
