import { MachineBaseService } from '../machine-base.service';
import { SaleService } from '../../sale.service';
import {
  PaymentSession,
  Money,
  OrderSaveResult,
  PrintTaskResult,
  PrintTask,
  PrintTaskType,
  MachineInactivitySettings,
  Ticket,
  PaymentMethod,
  TeaserType
} from '../../../lib/lib';
import { MessageType, Message } from '../../message.service';
import { LiteDisplayService } from '../../lite/lite-display.service';
import { PrinterHelper } from '../../../lib/printer/printer-helper';
import { LiteTouchTileService } from '../../lite/lite-touch-tile.service';
import { TurnstileService } from '../../turnstile/turnstile.service';
import { Injectable } from '@angular/core';
import { PrintService } from '../../print/print.service';
import { ModalService } from '../../gui/modal/modal-service';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { TimeoutSimpleModalComponent } from 'src/app/components/modal/timeout-simple-modal/timeout-simple-modal.component';
import { OpenGateTypeEnum } from 'src/app/lib/gate/open-gate-type.enum';
import { VuAutomaticActionType } from 'src/app/lib/ticket/ticket-vu-automatic-action-type';
import { Subscription } from 'rxjs';
import { PostSaleLiteTypeEnum } from 'src/app/modules/lite-mode/models/post-sale-lite-step';
import { RefundPaymentActionEnum } from 'src/app/lib/payment/refund-payment-action-enum';
import { PrinterActionsEnum } from 'src/app/lib/printer/printer-actions-enum';

@Injectable()
export class MachineLitePostSaleService extends MachineBaseService {
  private saleService: SaleService;
  private paymentSession: PaymentSession;
  private isSessionClosed = false;
  private liteDisplayService: LiteDisplayService;
  private liteTouchTileService: LiteTouchTileService;
  private turnstileService: TurnstileService;
  private printService: PrintService;
  private modalService: ModalService;
  private modalRef: BsModalRef;
  private createdTicket: Ticket;
  private isCashlessPayment = false;
  private gateSubscription: Subscription;
  private postSaleStepIndex = 0;
  private postSaleSteps = [
    PostSaleLiteTypeEnum.WaitingForMoney,
    PostSaleLiteTypeEnum.SaveOrder,
    PostSaleLiteTypeEnum.CheckExitRole,
    PostSaleLiteTypeEnum.PrintTickets,
    PostSaleLiteTypeEnum.PrintReceipt,
    PostSaleLiteTypeEnum.OpenGate,
    PostSaleLiteTypeEnum.WaitingForTurnstileEnter,
    PostSaleLiteTypeEnum.TakePrintedStuff,
  ];

  private isReceiptRemoved = false;
  private isTicketRemoved = false;
  private isReceiptPrinted = false;
  private isTicketPrinted = false;


  init(): void {
    super.init();
    this.saleService = this.injector.get(SaleService);
    this.modalService = this.injector.get(ModalService);
    this.saleService.eventMoneyChanged.subscribe((x: Money) => this.onMoneyChanged(x));
    this.vuConnection.eventReturnChangeFinished.subscribe(() => this.onReturnChangeFinished());
    this.vuConnection.eventOrderSaveResultReceived.subscribe((x: OrderSaveResult) => this.onOrderSaveResult(x));
    this.vuConnection.eventPrintTaskResultReceived.subscribe((x: PrintTaskResult) => this.onPrintTaskResultReceived(x));
    this.vuConnection.eventPrintTicketRemoved.subscribe((x: PrintTaskResult) => this.onPrintTicketRemoved(x));
    this.vuConnection.eventCashlessEvent.subscribe(
      (x: boolean) => this.onEventCashlessEvent(x)
    );
    this.liteDisplayService = this.injector.get(LiteDisplayService);
    this.liteTouchTileService = this.injector.get(LiteTouchTileService);
    this.turnstileService = this.injector.get(TurnstileService);
    this.turnstileService.eventTurnstileEnter.subscribe(() => this.onTurnstileEnter());
    this.printService = this.injector.get(PrintService);
  }

  get isLiteTouchTileMode(): boolean {
    return this.additionalPropertiesConfigurationService.isLiteTouchTileMode;
  }

  get machineName(): string {
    return 'Lite Post Sale Machine';
  }

  protected getTransitions(): any[] {
    return super.getTransitions(
      { name: 'toFlowBegin', from: ['off'], to: 'flowBegin' },
      { name: 'toWaitingForMoney', from: ['flowBegin'], to: 'waitingForMoney' },
      { name: 'toWaitingForMoneyTimeout', from: ['waitingForMoney'], to: 'waitingForMoneyTimeout' },
      { name: 'toCancelled', from: ['waitingForMoneyTimeout'], to: 'cancelled' },
      {
        name: 'toReturningAmountPaid', from: [
          'cancelled',
          'returningAmountChange',
          'saveOrder',
          'printTickets',
          'printReceipt',
          'waitingForTurnstileEnter',
          'gateReadBarcode',
        ], to: 'returningAmountPaid'
      },
      { name: 'toCheckForChange', from: ['waitingForMoneyTimeout'], to: 'checkForChange' },
      { name: 'toRevertingCashlessTransaction', from: ['returningAmountPaid'], to: 'revertingCashlessTransaction' },
      { name: 'toReturningAmountChange', from: ['checkForChange'], to: 'returningAmountChange' },
      { name: 'toSaveOrder', from: ['checkForChange', 'returningAmountChange', 'flowBegin'], to: 'saveOrder' },
      { name: 'toPrintTickets', from: ['saveOrder'], to: 'printTickets' },
      { name: 'toPrintReceipt', from: ['saveOrder', 'printTickets'], to: 'printReceipt' },
      { name: 'toWaitingForTurnstileEnter', from: ['saveOrder', 'printTickets'], to: 'waitingForTurnstileEnter' },
      { name: 'toGateReadBarcode', from: ['saveOrder', 'printTickets', 'printReceipt'], to: 'gateReadBarcode' },
      { name: 'toWaitingTakePrintedStuff', from: ['waitingForTurnstileEnter', 'gateReadBarcode'], to: 'waitingTakePrintedStuff' },
      { name: 'toFlowEnd', from: ['*'], to: 'flowEnd' },
    );
  }

  protected getMethods(): any {
    const scope = this;
    return super.getMethods({
      onToOff: (event: any, isHardReset: false) => {
        scope.paymentSession = null;
        scope.dispatcherService.isBackButtonEnabled = true;
        if (scope.modalRef) {
          scope.modalService.close(scope.modalRef);
          scope.modalRef = null;
        }
      },
      onToFlowBegin: () => {
        scope.resetStepsData();
        scope.isSessionClosed = false;
        scope.dispatcherService.isBackButtonEnabled = false;
        scope.paymentSession = scope.saleService.paymentSession;
        scope.isCashlessPayment = scope.saleService.order.paymentMethod !== PaymentMethod.Cash;
        scope.log.info(`MachineLitePostSaleService. onToFlowBegin. isCashlessPayment: ${scope.isCashlessPayment}, paymentMethod: ${scope.saleService.order.paymentMethod} ${scope.paymentSession}`);

        scope.doAsync(() => {
          scope.nextStep();
        }, 'onToFlowBegin');
      },
      onToWaitingForMoney: () => {
        const toWaitingForMoneyTimeout = () =>
          scope.doAsync(() => scope.machine.toWaitingForMoneyTimeout(), 'onToWaitingForMoney');

        if (scope.paymentSession.isCancelled) {
          const amountPaidIn = scope.paymentSession.amountPaidIn;
          if (!amountPaidIn.isZero) {
            this.liteDisplayService.changeCoins();
            if (scope.isLiteTouchTileMode) {
              this.liteTouchTileService.returningChange();
            }
          }
          scope.closeRemoteTransaction(false, 'Canceled.', toWaitingForMoneyTimeout);
        } else {
          toWaitingForMoneyTimeout();
        }
      },
      onToWaitingForMoneyTimeout: () => {
        if (scope.paymentSession.isCancelled) {
          scope.doAsync(() => scope.machine.toCancelled(), 'onToWaitingForMoneyTimeout. toCancelled');
        } else {
          scope.doAsync(() => scope.machine.toCheckForChange(), 'onToWaitingForMoneyTimeout. toCheckForChange');
        }
      },
      onToCancelled: () => {
        scope.dispatcherService.workflowDeleteLastStep();
        if (scope.paymentSession.amountPaidIn.isZero) {
          scope.dispatcherService.isBackButtonEnabled = true;
          scope.doAsync(() => scope.machine.toOff(), 'onToCancelled. amountPaid.isZero');
        } else {
          scope.doAsync(() => scope.machine.toReturningAmountPaid(),
            'onToCancelled. toReturningAmountPaid');
        }
      },
      onToReturningAmountPaid: () => {
        if (scope.isCashlessPayment) {
          scope.doAsync(
            () => scope.machine.toRevertingCashlessTransaction(),
            'onToReturningAmountPaid. isCashlessPayment');
          return;
        }
        scope.liteDisplayService.changeCoins();
        scope.do(() => {
          if (scope.isLiteTouchTileMode) {
            scope.liteTouchTileService.returningAmountPaid();
          }
          scope.paymentSession.amountTempToPayOut = scope.paymentSession.amountPaidIn
            .distract(scope.paymentSession.amountChangePaidOut);

          const amountPaidOut = scope.paymentSession.amountPaidOut;
          const isCancelled = scope.paymentSession.isCancelled;
          scope.dispatcherService.cashDevicesStatePayOut(true);

          if (isCancelled && !amountPaidOut.isZero) {
            scope.doAsync(() => {
              scope.onMoneyChanged(amountPaidOut.negate());
            }, 'onToReturningAmountPaid. isCancelled && !amountPaidOut.isZero');
          }

          if (isCancelled) {
            scope.closeRemoteTransaction(false, 'Canceled with incomming payment.');
          }
        }, 'onToReturningAmountPaid');
      },
      onToRevertingCashlessTransaction(): void {
        scope.vuHttp.revertCashlessTransaction(scope.saleService.order.paymentMethod)
          .then(() => {
            if (scope.saleService.order && scope.saleService.order.id) {
              scope.vuHttp.cancelOrder(scope.saleService.order);
            }
          }).catch(reason => {
            scope.onEventCashlessEvent(false);
          });
      },
      onToCheckForChange: () => {
        if (scope.paymentSession.amountChange.isPositive) {
          scope.doAsync(() => scope.machine.toReturningAmountChange(),
            'onToCheckForChange. toReturningAmountChange');
        } else {
          scope.doAsync(() => scope.nextStep(), 'onToCheckForChange. nextStep');
        }
      },
      onToReturningAmountChange: () => {
        scope.liteDisplayService.changeCoins();
        if (scope.isLiteTouchTileMode) {
          scope.liteTouchTileService.returningChange();
        }
        scope.do(() => {
          scope.paymentSession.amountTempToPayOut = scope.paymentSession.amountChange;
          scope.dispatcherService.cashDevicesStatePayOut(true);
          scope.doAsync(() => scope.checkAmountPaidOut(), 'onToReturningAmountChange');
        }, 'onToReturningAmountPaid');
      },
      onToSaveOrder: () => {
        if (scope.isLiteTouchTileMode) {
          scope.liteTouchTileService.setTicketInPrinter();
          scope.liteTouchTileService.ticketPrinting();
        }
        scope.liteDisplayService.saveOrder();
        if (scope.turnstileService.fastOpen) {
          scope.turnstileService.openEnter();
        }
        const order = scope.saleService.order;
        if (order && scope.paymentSession?.cardTerminalReceipt) {
          order.updateProperties("card_terminal_receipt", scope.paymentSession.cardTerminalReceipt);
        }
        scope.vuHttp.saveOrder(order).catch(reason => {
          scope.onOrderSaveResult(new OrderSaveResult(0,
            scope.saleService.order.uid, false, reason));
        });
      },
      onToPrintTickets: () => {
        if (scope.isLiteTouchTileMode) {
          scope.liteTouchTileService.setTicketInPrinter();
          scope.liteTouchTileService.ticketPrinting();
        }
        scope.liteDisplayService.printTicket();
        scope.printService.printOrderTicket(scope.saleService.order.id)
          .catch((x) => {
            scope.onPrintTicketsResultReceived(false);
          });
      },
      onToPrintReceipt: () => {
        scope.liteDisplayService.printTicket();
        scope.printService.printOrderReceipt(scope.saleService.order.id)
          .catch((x) => {
            scope.onPrintReceiptResultReceived(false);
          });
      },
      onToWaitingForTurnstileEnter: () => {
        scope.liteDisplayService.go();
        if (scope.isLiteTouchTileMode) {
          scope.liteTouchTileService.turnstileEnterAllowed();
        }
        if (!scope.turnstileService.fastOpen) {
          scope.turnstileService.openEnter();
        }
      },
      onToFlowEnd: () => {
        scope.dispatcherService.isBackButtonEnabled = true;
        const callback = () => {
          scope.doAsync(() => scope.machine.toOff(), 'onToFlowEnd');
        }
        scope.closeRemoteTransaction(true, 'Order saved. Role exit.', scope.isTicketEntrance ? null : callback);
      },
      onToGateReadBarcode(): void {
        if (!scope.createdTicket) {
          scope.log.error(`onToGateReadBarcode. Error, ticket is null`);
          scope.machine.toFlowEnd();
          return;
        }
        scope.liteDisplayService.go();
        if (scope.gateSubscription) {
          scope.gateSubscription.unsubscribe();
        }
        scope.gateSubscription = scope.dispatcherService.onGateOpenComplete((message) => {
          scope.gateSubscription.unsubscribe();
          if (message?.info?.openSuccess && message?.info?.entered) {
            scope.machine.toFlowEnd();
          } else {
            if (scope.isInState('gateReadBarcode')) {
              if (scope.isNeedToRefundPaymentAction()) {
                scope.do(() => scope.cancelSessionAndReturnMoney(!this.isCashlessPayment));
              } else {
                scope.machine.toFlowEnd();
              }
            }
          }
        });

        if (scope.gateOpenDelayTimeoutMs) {
          scope.modalRef = scope.modalService.show(
            TimeoutSimpleModalComponent,
            {
              message: 'Time left before turn on',
              autoCloseTimeout: scope.gateOpenDelayTimeoutMs,
            },
            () => {
              scope.modalRef = null;
              scope._openGateByTicket();
            }
          );
          scope._openGateByTicket();
        } else {
          scope._openGateByTicket();
        }
      },
      onToWaitingTakePrintedStuff: () => {
        scope.liteDisplayService.takeAndGo();
        if (scope.isLiteTouchTileMode) {
          scope.liteTouchTileService.ticketPrinting();
        }
        scope.dispatcherService.teaserAddItem(TeaserType.DontForgetTicket);
        scope.doAsync(() => { scope.nextStep(); }, 'waitingTakePrintedStuff')
      },
    });
  }

  private onEventCashlessEvent(isSuccess: boolean): void {
    if (this.state !== 'revertingCashlessTransaction') {
      this.log.info(`onEventCashlessEvent. The machine is not in 'revertingCashlessTransaction' state. Ignore the event.`);
      return;
    }

    if (isSuccess) {
      this.log.info(`onEventCashlessEvent. The machine is complete 'revertingCashlessTransaction'.`);
    } else {
      this.log.error(`onEventCashlessEvent. The machine is complete failure 'revertingCashlessTransaction'.`);
    }
    this.machine.toFlowEnd();
  }

  private resetStepsData(): void {
    this.postSaleStepIndex = 0;
    this.createdTicket = null;
    this.isReceiptRemoved = false;
    this.isTicketRemoved = false;
    this.isTicketPrinted = false;
    this.isReceiptPrinted = false;
  }

  get isReadBarcodeAtGateAfterPayment(): boolean {
    return this.additionalPropertiesConfigurationService.isReadBarcodeAtGateAfterPayment
      || this.isFMCUOpen
      || this.isFMCUOpenWaitForEntrance;
  }

  private _openGateByTicket(): void {
    if (!this.createdTicket) {
      return;
    }
    let timeoutMs = 0;
    if (this.isFMCUOpen) {
      // For Solarium tickets
      timeoutMs = this.createdTicket.accessTimeMinutes * 60 * 1000;
    }
    this.log.info(`openGateByTicket. barcode: ${this.createdTicket.code}}, timeoutMs: ${timeoutMs}`);
    this.dispatcherService.gateOpen(this.createdTicket.code, '', OpenGateTypeEnum.Immediately, timeoutMs);
  }

  get isFMCUOpen(): boolean {
    return this.saleService?.order?.orderLines?.some(
      line => line?.product?.vuAutomaticActionType === VuAutomaticActionType.FMCU);
  }

  get isFMCUOpenWaitForEntrance(): boolean {
    return this.saleService?.order?.orderLines?.some(
      line => line?.product?.vuAutomaticActionType === VuAutomaticActionType.FMCUWaitForEntrance);
  }

  get isTicketEntrance(): boolean {
    return !this.isReadBarcodeAtGateAfterPayment && !this.turnstileService.fastOpen;
  }

  protected getMessages(): MessageType[] {
    return super.getMessages(
      MessageType.ButtonBackClicked,
    );
  }

  protected onMessage(message: Message): boolean {
    if (super.onMessage(message)) {
      return true;
    }

    const state = this.state;
    switch (message.messageType) {
      case MessageType.ButtonBackClicked:
        if (state === 'flowEnd') {
          this.machine.toOff();
        }
        if (state === 'waitingForTurnstileEnter') {
          this.closeTurnstileWithoutReturningMoney();
        }

        if (state === 'gateReadBarcode') {
          if (this.isNeedToRefundPaymentAction()) {
            this.cancelSessionAndReturnMoney(!this.isCashlessPayment);
          } else {
            this.closeGateWithoutRetuningMoney();
          }
        }
        if (state === 'waitingTakePrintedStuff') {
          if (this.isNeedToRefundPaymentAction()) {
            this.cancelSessionAndReturnMoney(!this.isCashlessPayment);
          } else {
            this.closeGateWithoutRetuningMoney();
          }
        }
        break;
      default:
        break;
    }
    return false;
  }

  machineStart(): void {
    if (this.isOff) {
      this.machine.toFlowBegin();
    }
  }

  get gateOpenDelayTimeoutMs(): number {
    return this.additionalPropertiesConfigurationService.openDelayTimeoutMs;
  }

  private onMoneyChanged(money: Money): void {
    switch (this.state) {
      case 'returningAmountPaid':
        this.dispatcherService.workflowLastStepUpdate(... this.getPayoutArgs());
        this.dispatcherService.onUserActivity();
        if (this.paymentSession.isAmountPaidPaidOut) {
          const m = `totalPaidOut: ${this.paymentSession.amountPaidOut}`;
          this.log.info(`MachineLitePostSaleService. returningAmountPaid. ${m}`);
          this.onRequestedPayoutComplete(() => this.machine.toFlowEnd());
        }
        break;
      case 'returningAmountChange':
        const m = `totalPaidOut: ${this.paymentSession.amountPaidOut}. amountChange: ${this.paymentSession.amountChange}`;
        this.log.info(`MachineLitePostSaleService. ${m}`);
        this.checkAmountPaidOut();
        break;
      default:
        break;
    }
  }

  private onReturnChangeFinished(): void {
    const scope = this;
    setTimeout(() => {
      scope.onReturnChangeFinishedWithDelay();
    }, 1000); // to avoid the racing with the last eventMoneyChanged event
  }

  private onReturnChangeFinishedWithDelay(): void {
    if (this.state !== 'returningAmountChange') {
      this.log.info(`onReturnChangeFinished. Skip becase ${this.state} != 'returningAmountChange'`);
      return;
    }

    if (this.paymentSession.isAmountChangePaidOut) {
      this.log.info(`onReturnChangeFinished. Skip becase isAmountChangePaidOut`);
      return;
    }

    this.log.warning(`onReturnChangeFinished. Change not paid out!`);
    this.cancelSessionAndReturnMoney();
  }

  private checkAmountPaidOut(): void {
    if (this.paymentSession.isAmountChangePaidOut
      && this.paymentSession.amountChangePaidOut.toString() !== this.paymentSession.amountPaidOut.toString()) {
      this.paymentSession.amountChangePaidOut = this.paymentSession.amountPaidOut;
      this.paymentSession.resetAmountTempPayout();

      this.onRequestedPayoutComplete(() => this.nextStep());
    }
  }

  private onRequestedPayoutComplete(machineSwitch: () => void): void {
    this.dispatcherService.cashDevicesStatePayOut(false);
    setTimeout(() => {
      machineSwitch();
    }, 1000);
  }

  private onOrderSaveResult(result: OrderSaveResult): void {
    if (this.state !== 'saveOrder') {
      return;
    }
    result = OrderSaveResult.fromOther(result);
    this.saleService.order.applyOrderSaveResult(result);
    const m = `MachinePostSaleService. onOrderSaveResult: ${result}`;
    if (result.isSuccess) {
      this.log.info(m);
    } else {
      this.log.error(m);
    }
    const order = this.saleService.order;
    if (result.isSuccess
      && result.orderUid === order.uid
      && result.orderId != null) {
      let ticket = result.tickets != null &&
        result.tickets.length !== 0 ? result.tickets[0] : null;
      this.createdTicket = ticket;
      if (this.isReadBarcodeAtGateAfterPayment) {
        if (ticket == null) {
          this.log.warning('onOrderSaveResult. Order save result has no barcode. Generate fictional ticket');
          ticket = new Ticket();
          ticket.code = "vu-access";
          this.createdTicket = ticket;
        }
      }
      this.nextStep();
    } else {
      if (this.isNeedToRefundPaymentAction()){
        this.cancelSessionAndReturnMoney();
      }
      else {
        this.nextStep();
      }
    }
  }

  private onPrintTaskResultReceived(result: PrintTaskResult): void {
    if (this.state !== 'printReceipt' && this.state !== 'printTickets') {
      return;
    }

    if (!result.isSuccess) {
      this.completePrintTask(result);
    }

    const waitForTicketRemoved = this.waitForTicketRemoved(result);
    if (!waitForTicketRemoved) {
      this.completePrintTask(result);
    }
  }

  private onPrintTicketRemoved(result: PrintTaskResult): void {
    const printTaskType = result?.printTask?.printTaskType;
    const printerAction = result?.printerAction;
    if (printTaskType === PrintTaskType.Ticket && printerAction !== PrinterActionsEnum.Retract) {
      this.onTicketRemoved();
    }
    if (printTaskType === PrintTaskType.Receipt && printerAction !== PrinterActionsEnum.Retract) {
      this.onReceiptRemoved();
    }
    if (this.isLiteTouchTileMode) {
      this.liteTouchTileService.resetTicketInPrinter();
    }
    if (this.isInState('waitingForTurnstileEnter')) {
      if (this.isLiteTouchTileMode) {
        this.liteTouchTileService.turnstileEnterAllowed();
      }
    }
    if (this.additionalPropertiesConfigurationService.waitForTicketRemoved) {
      this.completePrintTask(result);
    }
  }

  private onTicketRemoved(): void {
    this.isTicketRemoved = true;
  }

  private onReceiptRemoved(): void {
    this.isReceiptRemoved = true;
  }

  // Print NOK -> No Pass -> Money back
  // Print OK -> No Pass -> Do not return Money back
  // Print OK -> Pass -> Do not return Money back
  // Print OK -> Bon was taken -> No Pass -> Do not return Money back!
  // Print OK -> Bon was not taken -> No Pass -> Money back
  private isNeedToRefundPaymentAction(): boolean {
    return (this.isTicketRefundPaymentAction && !this.isTicketPrinted)
      || (this.isReceiptRefundPaymentAction && !this.isReceiptPrinted)
      || (this.isTicketTakenRefundPaymentTicket && !this.isTicketRemoved)
      || (this.isReceiptTakenRefundPaymentAction && !this.isReceiptRemoved)
      || (this.refundPaymentAction === RefundPaymentActionEnum.WithRefund)
      || (this.state === 'saveOrder' && this.refundPaymentAction === RefundPaymentActionEnum.WithoutRefundIfOrderWasSaved);
  }

  private get isTicketRefundPaymentAction(): boolean {
    return this.refundPaymentAction === RefundPaymentActionEnum.NoRefundOnTicketPrint;
  }

  private get isTicketTakenRefundPaymentTicket(): boolean {
    return this.refundPaymentAction === RefundPaymentActionEnum.NoRefundIfTicketTaken;
  }

  private get isReceiptTakenRefundPaymentAction(): boolean {
    return this.refundPaymentAction === RefundPaymentActionEnum.NoRefundIfReceiptTaken;
  }

  private get isReceiptRefundPaymentAction(): boolean {
    return this.refundPaymentAction === RefundPaymentActionEnum.NoRefundOnReceiptPrint;
  }

  get refundPaymentAction(): RefundPaymentActionEnum {
    return this.configurationService.configuration.refundPaymentAction;
  }

  private waitForTicketRemoved(result: PrintTaskResult): boolean {
    return this.additionalPropertiesConfigurationService.waitForTicketRemoved
      && this.isPresenter(result);
  }

  private isPresenter(result: PrintTaskResult): boolean {
    const currentReceiptPrinterType = this.configurationService.configuration.receiptPrinterType;
    const currentTicketPrinterType = this.configurationService.configuration.ticketPrinterType;
    return result
      && result.printTask
      &&
      (
        result.printTask.printTaskType === PrintTaskType.Receipt && PrinterHelper.isPrinterPrisenter(currentReceiptPrinterType)
        ||
        result.printTask.printTaskType === PrintTaskType.Ticket && PrinterHelper.isPrinterPrisenter(currentTicketPrinterType)
      );
  }

  private completePrintTask(result: PrintTaskResult): void {
    const printTaskType = result?.printTask?.printTaskType;
    if (printTaskType === PrintTaskType.Ticket) {
      this.onPrintTicketsResultReceived(result.isSuccess);
    } else if (printTaskType === PrintTaskType.Receipt) {
      this.onPrintReceiptResultReceived(result.isSuccess);
    } else {
      this.log.error(`onPrintTaskResultReceived. Unsupported PrintTaskType: ${printTaskType}`);
    }
  }

  private onPrintTicketsResultReceived(result: boolean): void {
    if (this.state !== 'printTickets') {
      return;
    }
    this.log.info(`MachinePostSaleService. onPrintTicketsResultReceived. result: ${result}`);
    if (result) {
      this.isTicketPrinted = true;
      this.nextStep();
    } else {
      if (this.isLiteTouchTileMode) {
        this.liteTouchTileService.resetTicketInPrinter();
      }
      if (this.isNeedToRefundPaymentAction()) {
        this.cancelSessionAndReturnMoney(!this.isCashlessPayment);
        return;
      }
      this.nextStep();
    }
  }

  private onPrintReceiptResultReceived(result: boolean): void {
    if (this.state !== 'printReceipt') {
      return;
    }
    this.log.info(`MachinePostSaleService. onPrintReceiptResultReceived. result: ${result}`);
    if (!result) {

      if (this.isNeedToRefundPaymentAction()) {
        this.cancelSessionAndReturnMoney(!this.isCashlessPayment);
        return;
      }

    } else {
      this.isReceiptPrinted = true;
    }
    this.nextStep();
  }

  protected get timeoutTrackingStates(): string[] {
    return this.getsAllStatesExceptFor('off');
  }

  private getPayoutArgs(): string[] {
    try {
      const result = [
        this.paymentSession.amountTempToPayOut.toStringCurrencySign(),
        this.paymentSession.amountTempPaidOut.toStringCurrencySign()
      ];
      return result;
    } catch (e) {
      this.log.error(this.paymentSession);
    }
    return ['?', '?'];
  }

  closeRemoteTransaction(isToCommit: boolean, context: string, callback: any = null): void {
    this.saleService.remoteTransaction.closeRemoteTransaction(isToCommit, context, callback == null ? null : callback.bind(this), false, this.canRefund);
  }

  get orderExist(): boolean {
    return this.saleService.order && this.saleService.order.id > 0;
  }

  get isOrderSaving(): boolean {
    return this.state === 'saveOrder';
  }

  get canRefund(): boolean {
    if (!this.orderExist) {
      if (!this.isOrderSaving) {
        if (this.configurationService?.configuration?.refundPaymentAction === RefundPaymentActionEnum.WithoutRefundIfOrderWasSaved) {
          return true;
        }
      }

      if (this.isOrderSaving && this.configurationService?.configuration?.refundPaymentAction === RefundPaymentActionEnum.WithoutRefund) {
        return false;
      }
    } else {
      if (this.configurationService?.configuration?.refundPaymentAction === RefundPaymentActionEnum.WithoutRefundIfOrderWasSaved) {
        return false;
      }
    }

    return this.configurationService.configuration == null ||
      this.configurationService.configuration.refundPaymentAction == null ||
      this.configurationService.configuration.refundPaymentAction === RefundPaymentActionEnum.WithRefund;
  }

  protected onMachineTimeoutModalCancel(machineName: string): void {
    if (machineName === this.machineName) {
      if (this.isInState(
        'waitingForMoney',
        'returningAmountChange',
        'saveOrder',
        'printTickets',
        'printReceipt',
      )) {
        if (!this.isReadBarcodeAtGateAfterPayment) {
          this.machine.toWaitingForTurnstileEnter();
          return;
        }
        const cancelOrder = this.saleService.order && this.saleService.order.id !== 0;
        this.cancelSessionAndReturnMoney(cancelOrder && !this.isCashlessPayment);
        return;
      }

      if (this.isInState('gateReadBarcode')) {
        if (this.isNeedToRefundPaymentAction()) {
          this.cancelSessionAndReturnMoney(!this.isCashlessPayment);
        } else {
          this.machine.toFlowEnd();
        }
        return;
      }

      if (this.isInState(
        'waitingForTurnstileEnter',
      )) {
        this.closeTurnstileWithoutReturningMoney();
        return;
      }
    }

    this.closeRemoteTransaction(false, 'timeout');
    super.onMachineTimeoutModalCancel(machineName);
  }

  private cancelSessionAndReturnMoney(cancelOrder: boolean = false): void {
    if (this.turnstileService.fastOpen) {
      this.turnstileService.closeEnter();
    }
    if (this.isReadBarcodeAtGateAfterPayment && this.createdTicket) {
      this.dispatcherService.gateOpenStop(this.createdTicket?.code);
    }
    this.paymentSession.isCancelled = true;
    if (cancelOrder) {
      this.vuHttp.cancelOrder(this.saleService.order);
    }
    this.machine.toReturningAmountPaid();
  }


  private closeTurnstileWithoutReturningMoney(): void {
    this.turnstileService.closeEnter();
    this.machine.toFlowEnd();
  }

  protected getMachineInactivitySettings(state: string): MachineInactivitySettings {
    switch (state) {
      case 'flowEnd':
        return this.getTimeoutSettings(this.additionalPropertiesConfigurationService.timeoutPostSaleFlowEndSec);
      case 'returningAmountChange':
      case 'returningAmountPaid':
        return this.getTimeoutSettings(this.additionalPropertiesConfigurationService.timeoutReturnAmountSec);
      case 'printTickets':
      case 'printReceipt':
        return this.getTimeoutSettings(this.additionalPropertiesConfigurationService.timeoutPrinterSec);
      case 'waitingForTurnstileEnter':
        return this.getTimeoutSettings(this.additionalPropertiesConfigurationService.timeoutEntranceSec);
      case 'gateReadBarcode':
        if (this.gateOpenDelayTimeoutMs && this.configurationService.configuration) {
          const timeout = this.configurationService.configuration.timeIntervalBeforeTimeoutModal + this.gateOpenDelayTimeoutMs;
          return new MachineInactivitySettings(timeout);
        }
        return super.getMachineInactivitySettings(state);
      default:
        return super.getMachineInactivitySettings(state);
    }
  }

  private getTimeoutSettings(timeoutInSeconds: number): MachineInactivitySettings {
    return new MachineInactivitySettings(timeoutInSeconds * 1000, true);
  }

  onTurnstileEnter(): void {
    if (this.isInState('waitingForTurnstileEnter')) {
      this.machine.toFlowEnd();
    }
  }

  private nextStep(): void {
    const order = this.saleService.order;
    if (!order) {
      this.machine.toFlowEnd();
      return;
    }

    while (this.postSaleSteps.length > this.postSaleStepIndex) {
      const nextStep = this.postSaleSteps[this.postSaleStepIndex];
      this.postSaleStepIndex++;
      switch (nextStep) {
        case PostSaleLiteTypeEnum.WaitingForMoney:
          if (!this.isCashlessPayment) {
            this.machine.toWaitingForMoney();
            return;
          }
          break;
        case PostSaleLiteTypeEnum.SaveOrder:
          this.machine.toSaveOrder();
          return;
        case PostSaleLiteTypeEnum.CheckExitRole:
          if (this.isExitRole) {
            this.machine.toFlowEnd();
            return;
          }
          break;
        case PostSaleLiteTypeEnum.PrintTickets:
          if (order.hasTicketsToPrint && !this.isReadBarcodeAtGateAfterPayment) {
            this.machine.toPrintTickets();
            return;
          }
          break;
        case PostSaleLiteTypeEnum.PrintReceipt:
          if (order.isReceipt) {
            this.machine.toPrintReceipt();
            return;
          }
          break;
        case PostSaleLiteTypeEnum.OpenGate:
          if (this.isReadBarcodeAtGateAfterPayment && this.createdTicket) {
            this.machine.toGateReadBarcode(this.createdTicket);
            return;
          }
          break;
        case PostSaleLiteTypeEnum.WaitingForTurnstileEnter:
          if (this.turnstileService.isEnterAllowed && !this.turnstileService.fastOpen) {
            this.machine.toWaitingForTurnstileEnter();
            return;
          }
          break;
        case PostSaleLiteTypeEnum.TakePrintedStuff:
          if (!this.isReadBarcodeAtGateAfterPayment) {
            this.machine.onToWaitingTakePrintedStuff();
            return;
          }
          break;
      }
    }
    this.machine.toFlowEnd();
  }

  private closeGateWithoutRetuningMoney(): void {
    if (this.isReadBarcodeAtGateAfterPayment && this.createdTicket?.code) {
      this.dispatcherService.gateOpenStop(this.createdTicket.code);
    }
    this.machine.toFlowEnd();
  }
}
