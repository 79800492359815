import { Injectable } from '@angular/core';
import { Money } from 'src/app/lib/lib';
import { VuCommunicationService } from 'src/app/services/vu/vu-communication.service';

@Injectable()
export class CashRefundService {

  constructor(
    private vuCommunicationService: VuCommunicationService,
  ) { }

  canPayoutAmount(amount: Money): Promise<boolean> {
    return this.vuCommunicationService.vuHttp.canPayoutAmount(amount);
  }

  howMuchCanPayoutAmount(amount: Money): Promise<Money> {
    return this.vuCommunicationService.vuHttp.howMuchCanPayoutAmount(amount);
  }
}
