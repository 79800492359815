import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../shared/shared.module';
import { VUTranslateModule } from '../vu-translate/vu-translate.module';
import { ComponentsModule } from '../components/components.module';
import { PaymentButtonsComponent } from './components/payment-buttons/payment-buttons.component';

@NgModule({
  declarations: [
    PaymentButtonsComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    VUTranslateModule,
    ComponentsModule,
  ],
  exports: [
    PaymentButtonsComponent,
  ]
})
export class PaymentModule { }
