<label [attr.for]="'card'">{{ ('Selected card' | translate) + ':' }}</label>
<select id="card" [(ngModel)]="selectedCardId">
  <option *ngFor="let r of rfidCards; let idx = index" [value]="idx">{{ r }}</option>
</select>
<a type="button" class="btn btn-default" (click)="onProduceCardClick(false)">{{'Producing a card OK'| translate }}</a>
<a type="button" class="btn btn-default" (click)="onProduceCardClick(true)">{{'Producing a card Not OK'| translate }}</a>
<a type="button" class="btn btn-default" (click)="onReleaseCardClick(false)">{{'Release card OK'| translate }}</a>
<a type="button" class="btn btn-default" (click)="onReleaseCardClick(true)">{{'Release card Not OK'| translate }}</a>
<a type="button" class="btn btn-default" (click)="onCardRemovedClick()">{{'Remove card'| translate }}</a>
<a type="button" class="btn btn-default" (click)="onCardInsertedClick()">{{'Insert card'| translate }}</a>
<a type="button" class="btn btn-default" (click)="onCaptureCardClick(false)">{{'Capture card OK'| translate }}</a>
<a type="button" class="btn btn-default" (click)="onCaptureCardClick(true)">{{'Capture card Not OK'| translate }}</a>


