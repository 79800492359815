<div *ngIf="uiService && uiService.headerState && uiService.headerState.visible" class="pageHeader">
  <div>
    <img *ngIf="imgSrc" [src]="imgSrc" class="logo">
    <page-header-title class="header-title header1"></page-header-title>
    <languages-button *ngIf="showLanguagesButton" class="lang {{languageButtonActivate}}">
    </languages-button>
    <div class="time-container">
      <p class="time centered-content">{{currentTime}}</p>
    </div>
    <app-basket-button class="basket-button"></app-basket-button>
    <app-return-card class="restore-card-button"></app-return-card>
    <app-change-locker class="change-locker-button"></app-change-locker>
    <app-restore-card class="restore-card-button"></app-restore-card>
    <app-card-detail-information *ngIf="isShowCardDetailInformationButton" class="restore-card-button"></app-card-detail-information>
    <app-notification-message *ngIf="notificationMessage" [message]="notificationMessage?.message"
      [type]="notificationMessage?.type"></app-notification-message>
  </div>
  <div style="clear:both;"></div>
</div>
