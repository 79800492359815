
<label [attr.for]="'customMessage'">{{ ('Message' | translate) + ':' }}</label>
<input id="customMessage" type="text" size="20" [(ngModel)]="customMessage">

<label [attr.for]="'notificationType'">{{ ('Type' | translate) + ':' }}</label>
<select id="notificationType" [(ngModel)]="selectedType">
  <option *ngFor="let value of keys(type)" [ngValue]="type[value]">{{ type[value] }}</option>
</select>

<label [attr.for]="'customTimeout'">{{ ('Timeout' | translate) + ':' }}</label>
<input id="customTimeout" type="number" [(ngModel)]="customTimeout">

<a type="button" class="btn btn-default" (click)="showNotification()">{{'Show Notification' | translate }}</a>
<a type="button" class="btn btn-default" (click)="hideNotification()">{{'Hide Notification' | translate }}</a>
