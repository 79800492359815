export class BaseButton {
  enabled = true;
  visible = false;
  text: string;

  constructor(
  ) {
    this.resetText();
  }

  resetText(): void {
    this.text = '';
  }
}
