import { Component, OnInit, OnDestroy } from '@angular/core';
import { ShopState, PrintTaskType, PrintTask, PrintTaskResult, Configuration } from '../../../lib/lib';
import { Subscription } from 'rxjs';
import { DispatcherService } from '../../../services/dispatcher.service';
import { SaleService } from '../../../services/sale.service';
import { IVuConnectionSimulator } from '../../../services/vu/connection/vu-connection.interfaces';
import { VuCommunicationService } from '../../../services/vu/vu-communication.service';
import { ConfigurationService } from '../../../services/configuration/configuration.service';
import { PrinterHelper } from '../../../lib/printer/printer-helper';
import { PrintReceiptEnum } from '../../../lib/printer/print-receipt-enum';

@Component({
  selector: 'app-printer-simulator',
  templateUrl: './printer-simulator.component.html',
  styleUrls: ['./printer-simulator.component.css']
})
export class PrinterSimulatorComponent implements OnInit, OnDestroy {
  private vuSimulator: IVuConnectionSimulator;
  private subscriptionShopState: Subscription;
  private subscriptionConfigurationChanged: Subscription;
  shopState = new ShopState();
  configuration = new Configuration();
  private ticketPrinted: boolean;
  private receiptPrinted: boolean;

  constructor(
    private vuCommunicationService: VuCommunicationService,
    private dispatcherService: DispatcherService,
    private saleService: SaleService,
    private configurationService: ConfigurationService,
  ) {
    this.vuSimulator = vuCommunicationService.vuConnectionSimulator;
  }

  ngOnInit() {
    this.subscriptionShopState = this.dispatcherService.onShopStateChangedSubscribe(x => this.onShopStateChanged(x));
    this.subscriptionConfigurationChanged = this.dispatcherService
      .onConfigurationChangedSubscribe((x: Configuration) => this.configuration = Configuration.fromOther(x));
  }

  ngOnDestroy() {
    this.subscriptionShopState.unsubscribe();
    this.subscriptionConfigurationChanged.unsubscribe();
  }

  private onShopStateChanged(shopState: ShopState): void {
    this.shopState = shopState;
  };

  onTicketPrintedClick(isOk: boolean) {
    this.ticketPrinted = true;
    this.updatePrint(isOk, PrintTaskType.Ticket);
  }

  onReceiptPrintedClick(isOk: boolean) {
    this.receiptPrinted = true;
    this.updatePrint(isOk, PrintTaskType.Receipt);
  }

  onCreditCardReceiptPrintedClick(isOk: boolean) {
    this.receiptPrinted = true;
    this.updatePrint(isOk, PrintTaskType.CreditCardReceipt);
  }

  updatePrint(isOk: boolean, printTaskType: PrintTaskType) {
    this.dispatcherService.shopStatePrinting(printTaskType, false);
    let printTask = new PrintTask(this.saleService.orderId, printTaskType);
    let result = new PrintTaskResult(printTask, isOk, '');
    this.vuSimulator.printTaskResultReceived(result);
  }

  onTicketRemovedClick() {
    this.ticketPrinted = false;
    this.removed(PrintTaskType.Ticket);
  }

  onReceiptRemovedClick() {
    this.receiptPrinted = false;
    this.removed(PrintTaskType.Receipt);
  }

  removed(printTaskType: PrintTaskType) {
    this.dispatcherService.shopStatePrinting(printTaskType, false);
    let printTask = new PrintTask(this.saleService.orderId, printTaskType);
    let result = new PrintTaskResult(printTask, true, '');
    this.vuSimulator.printTicketRemoved(result);
  }

  get printerTypes(): string[] {
    return PrinterHelper.supporedPrinterTypes;
  }

  onTicketPrinterTypeChange() {
    this.ticketPrinted = false;
    this.vuCommunicationService.vuConnectionSimulator.configurationChanged(this.configuration);
  }

  onReciptPrinterTypeChange() {
    this.receiptPrinted = false;
    this.vuCommunicationService.vuConnectionSimulator.configurationChanged(this.configuration);
  }

  get isTicketPrinterPrisenter(): boolean {
    return PrinterHelper.isPrinterPrisenter(this.configuration.ticketPrinterType);
  }

  get isReceiptPrinterPrisenter(): boolean {
    return PrinterHelper.isPrinterPrisenter(this.configuration.receiptPrinterType);
  }

  get ticketRemovedButtonEnabled(): boolean {
    return this.isTicketPrinterPrisenter && this.ticketPrinted;
  }

  get receiptRemovedButtonEnabled(): boolean {
    return this.isReceiptPrinterPrisenter && this.receiptPrinted;
  }

  onPrintOrderReceiptChange() {
    this.dispatcherService.configurationPrintOrderReceipt(this.configuration.printOrderReceipt);
  }

  get receiptPrinterEnum(): string[] {
    return [
      PrintReceiptEnum.Disabled,
      PrintReceiptEnum.UserChoice,
      PrintReceiptEnum.UserChoiceActive,
      PrintReceiptEnum.ForcePrint,
    ];
  }
}
