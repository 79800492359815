import { Injectable } from '@angular/core';
import 'rxjs';
import { HttpClient } from '@angular/common/http';

import {
  VisualItem,
  AcceptedCash,
  Order,
  Product,
  Money,
  VuState,
  DtoOrder,
  PrintTask,
  DtoVuConfiguration,
  TicketUse,
  CreditCard,
  OrderType,
  OrderSaveResult,
  PrintTaskType,
  PrintTaskResult,
  Ticket,
  PaymentMethod
} from '../../../lib/lib';
import { VuHttpBaseService } from './vu-http-base.service';
import { API_PRINT, API_SAVE_ORDER, API_CANCEL_ORDER, IVuHttpSimulator, API_PRINT_TEMPLATE } from './vu-http.interface';
import { InMemoryDataService } from '../../in-memory-data.service';
import { ConfigurationService } from '../../configuration/configuration.service';
import { LanguageService } from '../../language.service';
import { DispatcherService } from '../../dispatcher.service';
import { VuCommunicationService } from '../vu-communication.service';
import { IVuConnectionSimulator } from '../connection/vu-connection.interfaces';
import { ScreenSaverConfiguration } from '../../../lib/screen-saver-configuration';
import { TurnstileSimulatorService } from '../../turnstile/turnstile-simulator.service';
import { Observable, Subscriber, of } from 'rxjs';
import { TouchTileSimulatorService } from '../../touch-tile/touch-tile-simulator.service';
import { TouchTileColor } from '../../../lib/touch-tile/touch-tile-color';
import { TouchTileSwitch } from '../../../lib/touch-tile/touch-tile-switch';
import { RunningLightScenario } from '../../../lib/touch-tile/running-light-scenario';
import { CreditCardTerminalState } from '../../../lib/credit-card/credit-card-terminal-state';
import { doAsync } from '../../../lib/executor';
import { TicketSimulatorService } from '../../ticket/ticket-simulator.service';
import { DisplayConfigurationSimulatorService } from '../../display/configuration/display-configuration-simulator.service';
import { DisplayConfiguration } from '../../../lib/display/configuration/display-configuration';
import { CIOBoardPinEvent } from '../../../lib/cioboard/cioboard-pin-event';
import { MoneyExchangeRules } from '../../../lib/money-exchange/money-exchange-rules';
import { MoneyExchangeSimulatorService } from '../../money-exchange/money-exchange-simulator.service';
import { Issue } from '../../../lib/issue/issue';

@Injectable()
export class VuHttpSimulatorService extends VuHttpBaseService {
  private http: HttpClient;
  private dataService: InMemoryDataService;
  private configurationService: ConfigurationService;
  private languageService: LanguageService;
  private dispatcherService: DispatcherService;
  private inMemoryDataService: InMemoryDataService;
  private vuCommunicationService: VuCommunicationService;
  private vuConnectionSimulator: IVuConnectionSimulator;
  private vuState: VuState = new VuState();
  private screenSaverConfiguration: ScreenSaverConfiguration;
  private turnstileSimulatorService: TurnstileSimulatorService;
  private touchTileSimulatorService: TouchTileSimulatorService;
  private ticketSimulatorService: TicketSimulatorService;
  private displayConfigurationSimulatorService: DisplayConfigurationSimulatorService;
  private moneyExchangeSimulatorService: MoneyExchangeSimulatorService;

  protected init(): void {
    this.dataService = this.injector.get(InMemoryDataService);
    this.dispatcherService = this.injector.get(DispatcherService);
    this.configurationService = this.injector.get(ConfigurationService);
    this.inMemoryDataService = this.injector.get(InMemoryDataService);
    this.languageService = this.injector.get(LanguageService);
    this.doAsync(() => {
      this.vuCommunicationService = this.injector.get(VuCommunicationService);
      this.vuConnectionSimulator = this.vuCommunicationService.vuConnectionSimulator;
    });
    this.http = this.injector.get(HttpClient);
    this._useProductionApi = false;
    this.dispatcherService.onVuStateChangedSubscribe(x => this.vuState = x);
    this.moneyExchangeSimulatorService = this.injector.get(MoneyExchangeSimulatorService);
    this.initScreenSaverConfiguration();
    this.turnstileSimulatorService = this.injector.get(TurnstileSimulatorService);
    this.touchTileSimulatorService = this.injector.get(TouchTileSimulatorService);
    this.ticketSimulatorService = this.injector.get(TicketSimulatorService);
    this.displayConfigurationSimulatorService = this.injector.get(DisplayConfigurationSimulatorService);
    this.initMoneyExchangeConfiguration();
  }

  protected doAsync(f: any): void {
    const scope = this;
    setTimeout(() => {
      f();
    }, 1);
  }

  getProducts(): Promise<Product[]> {
    return new Promise((resolve, reject) => {
      resolve(this.dataService.products);
    });
  }

  printApi(): Promise<any> {
    return new Promise((resolve, reject) => {
      resolve(null);
    });
  }

  getPrintXmlTemplate(task: PrintTask): Promise<string> {
    return new Promise((resolve, reject) => {
      resolve('');
    });
  }

  getProductsByIds(productIds: number[]): Promise<Product[]> {
    return new Promise((resolve, reject) => {
      const products = this.dataService.products;
      const foundProducts = products.filter(item => productIds.includes(item.id));
      resolve(foundProducts);
    });
  }

  getProduct(productId: number): Promise<Product> {
    return new Promise((resolve, reject) => {
      const products = this.dataService.products;
      const foundProducts = products.filter(y => y.id === productId);
      resolve(foundProducts[0]);
    });
  }

  getSubProduct(productId: number): Promise<Product> {
    return new Promise((resolve, reject) => {
      const products = this.dataService.products;
      const foundProducts = products.filter(y => y.id === productId);
      if (!foundProducts.length) {
        resolve(null);
        return;
      }
      const foundSubProducts = products.filter(y => y.id === foundProducts[0].subProductId);
      if (!foundSubProducts.length) {
        resolve(null);
        return;
      }
      resolve(foundSubProducts[0]);
    });
  }

  getLiteModeProduct(): Promise<Product> {
    return new Promise((resolve, reject) => {
      const products = this.dataService.products;
      resolve(products[0]);
    });
  }

  calculateProductsPrice(productsInfo: any, priceListId: number): Promise<number[]> {
    return Promise.resolve(null);
  }

  getVisualItems(type: string): Promise<VisualItem[]> {
    const res = this.parseVisualItems(this.dataService.getVisualItems(type), type);
    return new Promise((resolve, reject) => {
      resolve(res);
    });
  }

  getDisplayConfiguration(configurationId: number): Promise<DisplayConfiguration> {
    let displayConfigurationData: any;
    switch (configurationId) {
      case 1:
        displayConfigurationData = this.dataService.createDisplayConfiguration();
        break;
      case 2:
        displayConfigurationData = this.dataService.createDisplayConfiguration2();
        break;
      default:
        break;
    }

    let displayConfiguration: DisplayConfiguration = null;
    if (displayConfigurationData) {
      displayConfiguration = this.parseDisplayConfiguration(displayConfigurationData);
      if (this.displayConfigurationSimulatorService.configurationId === configurationId
        && this.displayConfigurationSimulatorService.displayConfiguration) {
        const currentDisplayConfiguration = this.displayConfigurationSimulatorService.displayConfiguration;
        displayConfiguration.rowCount = currentDisplayConfiguration.rowCount;
        displayConfiguration.columnsCount = currentDisplayConfiguration.columnsCount;
        displayConfiguration.extendedDisplay = currentDisplayConfiguration.extendedDisplay;
      }
      this.displayConfigurationSimulatorService.displayConfiguration = displayConfiguration;
    }
    return new Promise((resolve, reject) => {
      resolve(displayConfiguration);
    });
  }

  getVuConfiguration(): Promise<DtoVuConfiguration> {
    return new Promise((resolve, reject) => {
      const c = this.configurationService.configuration;
      const dtoVuConfiguration = DtoVuConfiguration.fromOther(c);
      dtoVuConfiguration.displayConfigurationId = this.displayConfigurationSimulatorService.configurationId;
      dtoVuConfiguration.changeDate = new Date();
      resolve(dtoVuConfiguration);
    });
  }

  getTicketUse(code: string): Promise<TicketUse[]> {
    return new Promise((resolve, reject) => {
      resolve(this.parseTicketUse(this.dataService.getTicketUse(code)));
    });
  }

  getAcceptedCash(amount: Money): Promise<AcceptedCash> {
    const res = AcceptedCash.fromJson(this.dataService.acceptedCash);
    return new Promise((resolve, reject) => {
      resolve(res);
    });
  }

  canPayoutAmount(amount: Money): Promise<boolean> {
    return new Promise((resolve, reject) => {
      resolve(true);
    });
  }

  howMuchCanPayoutAmount(amount: Money): Promise<Money> {
    return new Promise((resolve, reject) => {
      resolve(Money.empty);
    });
  }

  getAcceptedCreditCards(): Promise<CreditCard[]> {
    const res = this.parseAcceptedCreditCards(this.dataService.acceptedCreditCards);
    return new Promise((resolve, reject) => {
      resolve(res);
    });
  }

  getVuState(): Promise<VuState> {
    return new Promise((resolve, reject) => {
      resolve(this.vuState);
    });
  }

  updateAngularPingState(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      resolve(true);
    });
  }

  saveOrder(order: Order): Promise<any> {
    this.dispatcherService.shopStateIsSavingOrder(true);
    if (this.isUseProductionApi) {
      const dtoOrder = DtoOrder.fromOrder(order);
      const url = `http://localhost:8080/${API_SAVE_ORDER}`;
      return this.http.post(url, JSON.stringify(dtoOrder))
        .toPromise()
        .catch(this.handleError);
    } else {
      return super.saveOrder(order);
    }
  }

  cancelOrder(order: Order): Promise<any> {
    this.dispatcherService.shopStateIsSavingOrder(true);
    if (this.isUseProductionApi) {
      order.convertToRefundType();
      const dtoOrder = DtoOrder.fromOrder(order);
      const url = `http://localhost:8080/${API_CANCEL_ORDER}`;
      return this.http.post(url, JSON.stringify(dtoOrder))
        .toPromise()
        .catch(this.handleError);
    } else {
      return super.cancelOrder(order);
    }
  }

  print(task: PrintTask): Promise<any> {
    this.dispatcherService.shopStatePrinting(task.printTaskType, true);
    const promise = super.print(task);
    const url = `http://localhost:8080/${API_PRINT}`;
    if (this.isUseProductionApi) {
      return this.http.post(url, JSON.stringify(task))
        .toPromise()
        .catch(this.handleError);
    } else {
      return promise;
    }
  }

  printByTemplateTypeUniqueName(templateTypeUniqueName: string, language: string): Promise<any> {
    this.dispatcherService.shopStatePrinting(PrintTaskType.Receipt, true);
    const promise = super.printByTemplateTypeUniqueName(templateTypeUniqueName, language);
    const url = `http://localhost:8080/${API_PRINT_TEMPLATE}`;
    if (this.isUseProductionApi) {
      return this.http.post(url, { StringValue: templateTypeUniqueName })
        .toPromise()
        .catch(this.handleError);
    } else {
      return promise;
    }
  }

  openFmcuApi(url: string = 'http://localhost:8888/', body: object = {}): Promise<any> {
    const promise = super.openFmcuApi(url, body);
    if (this.isUseProductionApi) {
      return this.http.post(url, body)
        .toPromise()
        .catch(this.handleError);
    } else {
      return promise;
    }
  }

  beginPaymentTransaction(amount: Money, paymentMethod: PaymentMethod, giftName: string, giftAmount: Money): Promise<void> {
    return super.beginPaymentTransaction(amount, paymentMethod, giftName, giftAmount).then(
      () => {
        this.dispatcherService.cashDevicesStatePayIn(true);
      }
    );
  }

  commitPaymentTransaction(force: boolean): Promise<void> {
    return super.commitPaymentTransaction(force);
  }

  appendTransactionInfo(info: string): Promise<void> {
    return super.appendTransactionInfo(info);
  }

  revertPaymentTransaction(allowRefundAngular: boolean): Promise<any> {
    return super.revertPaymentTransaction(allowRefundAngular);
  }

  revertCashlessTransaction(paymentMethod: PaymentMethod): Promise<any> {
    const scope = this;
    doAsync(() => scope.dispatcherService.creditCardTerminalSimulatorService.revertTransaction(), this.log);
    return super.revertCashlessTransaction(paymentMethod);
  }

  resetRemoteTransaction(): Observable<boolean> {
    return super.resetRemoteTransaction();
  }

  abortCashlessTransaction(paymentMethod: PaymentMethod): Promise<any> {
    const scope = this;
    return super.revertCashlessTransaction(paymentMethod);
  }


  returnAmount(amount: Money): Promise<any> {
    return super.returnAmount(amount);
  }

  callStaff(): void {
    super.callStaff();
  }

  scanTicket(barcode: string): void {
    super.scanTicket(barcode);
  }

  getTicketInfo(barcode: string): Observable<Ticket> {
    this.log.info(`VuHttpBaseService. getTicketInfo: ${barcode}`);
    return of(this.ticketSimulatorService.getTicketInfo(barcode));
  }

  activateOneDayTicket(ticketCode: string): Observable<Ticket> {
    this.log.info(`VuHttpBaseService. activateOneDayTicket: ${ticketCode}`);
    return of(this.ticketSimulatorService.activateOneDayTicket(ticketCode));
  }

  printTicket(ticketCode: string): Promise<any> {
    this.dispatcherService.shopStatePrinting(PrintTaskType.Ticket, true);
    const promise = super.printTicket(ticketCode);
    return promise;
  }

  initScreenSaverConfiguration(): void {
    this.screenSaverConfiguration = new ScreenSaverConfiguration();
    this.screenSaverConfiguration.timeout = 0;
    // this.screenSaverConfiguration.mode = 'text';
    this.screenSaverConfiguration.mode = 'images';
    this.screenSaverConfiguration.message = 'Screen Saver';
    this.screenSaverConfiguration.imagesIds = ['demo1', 'demo2', 'demo3', 'demo4'];
    this.screenSaverConfiguration.imageShowTimeout = 15;
    this.screenSaverConfiguration.timeFrom = 21.00;
    this.screenSaverConfiguration.timeTo = 6.00;
  }

  getScreenSaverConfiguration(): Promise<ScreenSaverConfiguration> {
    return new Promise((resolve, reject) => {
      resolve(this.screenSaverConfiguration);
    });
  }

  setScreenSaverConfiguration(screenSaverConfiguration: ScreenSaverConfiguration): void {
    this.screenSaverConfiguration = screenSaverConfiguration;
  }

  openEnter(): Observable<boolean> {
    return Observable.create(
      (observable: Subscriber<boolean>) => {
        super.openEnter().subscribe(
          result => {
            this.turnstileSimulatorService.openEnter();
            observable.next(true);
          }
        );
      });
  }

  closeEnter(): Observable<boolean> {
    return Observable.create(
      (observable: Subscriber<boolean>) => {
        super.closeEnter().subscribe(
          result => {
            this.turnstileSimulatorService.closeEnter();
            observable.next(true);
          }
        );
      });
  }

  changeTouchTileColor(touchTileColor: TouchTileColor[]): Observable<boolean> {
    return Observable.create(
      (observable: Subscriber<boolean>) => {
        super.changeTouchTileColor(touchTileColor).subscribe(
          result => {
            this.touchTileSimulatorService.changeColors(touchTileColor);
            observable.next(true);
          }
        );
      });
  }

  changeTouchTileSwitch(touchTileSwitch: TouchTileSwitch[]): Observable<boolean> {
    return Observable.create(
      (observable: Subscriber<boolean>) => {
        super.changeTouchTileSwitch(touchTileSwitch).subscribe(
          result => {
            this.touchTileSimulatorService.changeSwitches(touchTileSwitch);
            observable.next(true);
          }
        );
      });
  }

  setRunningLight(runningLightScenario: RunningLightScenario): Observable<boolean> {
    return Observable.create(
      (observable: Subscriber<boolean>) => {
        super.setRunningLight(runningLightScenario).subscribe(
          result => {
            this.touchTileSimulatorService.setRunningLight(runningLightScenario);
            observable.next(true);
          }
        );
      });
  }

  getCreditCardTerminalState(): Observable<CreditCardTerminalState> {
    return Observable.create(
      (observable: Subscriber<CreditCardTerminalState>) => {
        super.getCreditCardTerminalState().subscribe(
          () => {
            observable.next(this.dispatcherService.creditCardTerminalSimulatorService.creditCardTerminalState);
          }
        );
      });
  }

  cioBoardAction(pins: CIOBoardPinEvent[]): Promise<any> {
    const promise = super.cioBoardAction(pins);
    return new Promise((resolve, reject) => {
      resolve(promise);
    });
  }

  writeLogMessages(messages: string[]): Promise<any> {

    const isSent = Math.floor(Math.random() * 10) === 0;

    // if (isSent) {
    //     messages.forEach(message => {
    //         console.log('Simlog: %c' + message, 'color:gray');
    //     });
    // }

    return new Promise<any>((resolve, reject) => {
      if (isSent) {
        resolve(null);
      } else {
        reject();
      }
    });
  }

  initMoneyExchangeConfiguration(): void {
    this.moneyExchangeSimulatorService.isEnabled = true;
    this.moneyExchangeSimulatorService.banknoteRules = this.dataService.moneyBanknotesExchangeRules;
    this.moneyExchangeSimulatorService.coinsRules = this.dataService.moneyCoinsExchangeRules;
  }

  getMoneyExchangeRules(): Observable<MoneyExchangeRules> {
    return of(new MoneyExchangeRules(
      this.moneyExchangeSimulatorService.acceptedCoinsRules,
      this.moneyExchangeSimulatorService.acceptedBanknotesRules));
  }

  beginMoneyExchangeTransaction(acceptedCash: AcceptedCash): Observable<boolean> {
    const result = this.moneyExchangeSimulatorService.beginTransaction(acceptedCash);
    return of(result);
  }

  commitMoneyExchangeTransaction(): Observable<boolean> {
    const result = this.moneyExchangeSimulatorService.commitTransaction();
    return of(result);
  }

  moneyExchangePayoutMoney(acceptedCash: AcceptedCash): Observable<boolean> {
    const result = this.moneyExchangeSimulatorService.payoutMoney(acceptedCash);
    return of(result);
  }

  rollbackMoneyExchangeTransaction(): Observable<boolean> {
    const result = this.moneyExchangeSimulatorService.rollbackTransaction();
    return of(result);
  }

  createIssue(issue: Issue): Observable<number> {
    return of(0);
  }

  beginUnrestrictedCashPaymentTransaction(): Observable<boolean> {
    return of(true);
  }

  commitUnrestrictedCashPaymentTransaction(): Observable<boolean> {
    return of(true);
  }

  resetUnrestrictedCashPaymentTransaction(): Observable<boolean> {
    return of(true);
  }

  getAcceptedCashWithoutRestrictions(): Observable<AcceptedCash> {
    const res = AcceptedCash.fromJson(this.dataService.acceptedCash);
    return of(res);
  }

  getOrderCache(): Observable<AcceptedCash> {
    return of(null);
  }

  gateClose(baseUrl: string): Observable<any> {
    return of(null);
  }
}
