<label [attr.for]="'In0'">{{ ('In0' | translate) + ':' }}</label>
<bSwitch switch-on-color='danger' switch-on-text="{{ 'On' | translate }}" switch-off-text="{{ 'Off' | translate }}"
  [(ngModel)]="In0" switch-handle-width="50">
</bSwitch>
<label [attr.for]="'In1'">{{ ('In1' | translate) + ':' }}</label>
<bSwitch switch-on-color='danger' switch-on-text="{{ 'On' | translate }}" switch-off-text="{{ 'Off' | translate }}"
  [(ngModel)]="In1" switch-handle-width="50">
</bSwitch>
<label [attr.for]="'In2'">{{ ('In2' | translate) + ':' }}</label>
<bSwitch switch-on-color='danger' switch-on-text="{{ 'On' | translate }}" switch-off-text="{{ 'Off' | translate }}"
  [(ngModel)]="In2" switch-handle-width="50">
</bSwitch>
<label [attr.for]="'In3'">{{ ('In3' | translate) + ':' }}</label>
<bSwitch switch-on-color='danger' switch-on-text="{{ 'On' | translate }}" switch-off-text="{{ 'Off' | translate }}"
  [(ngModel)]="In3" switch-handle-width="50">
</bSwitch>
<label [attr.for]="'In4'">{{ ('In4' | translate) + ':' }}</label>
<bSwitch switch-on-color='danger' switch-on-text="{{ 'On' | translate }}" switch-off-text="{{ 'Off' | translate }}"
  [(ngModel)]="In4" switch-handle-width="50">
</bSwitch>
<label [attr.for]="'In5'">{{ ('In5' | translate) + ':' }}</label>
<bSwitch switch-on-color='danger' switch-on-text="{{ 'On' | translate }}" switch-off-text="{{ 'Off' | translate }}"
  [(ngModel)]="In5" switch-handle-width="50">
</bSwitch>
<button class="btn btn-default" (click)="onSendEvent()">{{'Apply' | translate}}</button>
