import { Component, OnDestroy, OnInit } from '@angular/core';
import { ExternalUseService } from 'src/app/modules/external-base/services/external-use.service';

@Component({
  selector: 'app-page-message',
  templateUrl: './page-message.component.html',
  styleUrls: ['./page-message.component.css']
})
export class PageMessageComponent implements OnInit, OnDestroy {

  constructor(
    private externalUseService: ExternalUseService,
  ) {
  }

  ngOnInit(): void {
    if (this.externalUseService.isEnabled) {
      this.externalUseService.pauseActivation();
    }

  }

  ngOnDestroy(): void {
    if (this.externalUseService.isEnabled) {
      this.externalUseService.unpauseActivation();
    }
  }

}
