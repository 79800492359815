// import { BasketUpdateItem } from './basket-update-item';
import { OrderInfo } from './order-info';
import { PaymentInfo } from './payment-info';
import { TotalPurchasedInformation } from './total-purchased-information';
export class CardInformation {
  name: string;
  balance: number;
  rechargeProductId: number;
  payoutProductId: number;
  orders: OrderInfo[];
  payments: PaymentInfo[];
  disabled: boolean;
  allowCredit: boolean;
  ean13: string;
  allowPayment: boolean;
  isService: boolean;
  lockerNumber: string;
  totalPurchasedInformation: TotalPurchasedInformation;
  allowPayout: boolean;
  priceListId: number;
  isAllowedCaptureCard: boolean;
  // basketUpdate: Array<BasketUpdateItem>;
  lastTicketProductId: number;
  validUntil: Date;
  remainingPassages: number;
  isCardActivate: boolean;
  checkinErrorMessage: string;
  excludedProductIds: number[];
  excludedProductTemplateIds: number[];
  restoreProductId: number;
  depositOrderLineId: number;
  depositProductId: number;
  refundWithoutConfirmation: boolean;

  static createFromAny(data: any): CardInformation {
    const cardInformation = new CardInformation();
    if (data) {
      cardInformation.name = data.name || '';
      cardInformation.balance = data.balance || 0;
      cardInformation.rechargeProductId = data.recharge_product_id || data.rechargeProductId || 0;
      cardInformation.disabled = data.disabled || false;
      cardInformation.allowCredit = data.allow_credit || data.allowCredit || false;
      cardInformation.allowPayment = data.allow_payment || data.allowPayment || false;
      cardInformation.ean13 = data.ean13 || '';
      cardInformation.orders = OrderInfo.createFromAnyArray(data.orders);
      cardInformation.payments = PaymentInfo.createFromAnyArray(data.payments);
      cardInformation.isService = data.is_service || data.isService || false;
      cardInformation.lockerNumber = String(data.locker_number || data.lockerNumber || '');
      cardInformation.totalPurchasedInformation = TotalPurchasedInformation.createFromAny(data.total);
      cardInformation.allowPayout = data.allow_payout;
      cardInformation.payoutProductId = data.payout_product_id || 0;
      cardInformation.priceListId = data.pricelist_id || 0;
      cardInformation.isAllowedCaptureCard = data.is_allowed_capture_card || false;
      //  cardInformation.basketUpdate = BasketUpdateItem.createFromAnyArray(data.basket_update || data.basketUpdate || []);
      cardInformation.lastTicketProductId = data.last_ticket_product_id || 0;
      cardInformation.remainingPassages = data.remaining_passages || 0;
      if (data.valid_until) {
        cardInformation.validUntil = new Date(data.valid_until);
      }
      cardInformation.isCardActivate = data.is_card_activate || false;
      cardInformation.checkinErrorMessage = data.checkin_error_message || '';
      cardInformation.excludedProductIds = data.excluded_product_ids || [];
      cardInformation.excludedProductTemplateIds = data.excluded_product_template_ids || [];
      cardInformation.restoreProductId = data.restore_product_id || data.restoreProductId || 0;
      cardInformation.depositOrderLineId = data.deposit_order_line_id || data.depositOrderLineId || 0;
      cardInformation.depositProductId = data.deposit_product_id || data.depositProductId || 0;
      cardInformation.refundWithoutConfirmation = data.refund_without_confirmation || false;
    }

    return cardInformation;
  }

  allowPaymentAmount(amount: number): boolean {
    return !this.disabled && this.allowPayment && (this.allowCredit || this.balance >= amount);
  }

  allowPartialPayment(): boolean {
    return !this.disabled && this.allowPayment && this.balance > 0;
  }

  get allowAutoPayment(): boolean {
    return !this.disabled  && this.balance > 0;
  }
}
