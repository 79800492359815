import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ProductVariant } from 'src/app/lib/product/product-variant';
import { LanguageService } from 'src/app/services/language.service';

@Component({
  selector: 'app-select-product-variant',
  templateUrl: './select-product-variant.component.html',
  styleUrls: ['./select-product-variant.component.scss']
})
export class SelectProductVariantComponent implements OnInit {

  constructor(
    private languageService: LanguageService,
  ) { }

  @Input()
  variants: ProductVariant[];

  @Input()
  selectedProductId: number;

  @Output() selectedProductIdChange = new EventEmitter<number>();

  ngOnInit(): void {
    this.selectedProductId = -1;
  }

  onProductSelect(productId: number): void {
    if (!productId) {
      return;
    }

    if (productId === this.selectedProductId) {
      this.selectedProductId = -1;
      this.selectedProductIdChange.emit(this.selectedProductId);
      return;
    }

    this.selectedProductId = productId;
    this.selectedProductIdChange.emit(this.selectedProductId);
  }

  get language(): string {
    return this.languageService.odooLanguageName;
  }

  getTranslateVariantName(variant): string {
    if (!variant) {
      return '';
    }

    return variant.translateName(this.language) || variant.name;
  }

}
