import { Injectable, Injector } from '@angular/core';
import { EventEmitter } from '@angular/core';
import { Money, Ticket, OrderSaveResult, VuState, PrintTaskResult, DtoVuConfiguration, Configuration } from '../../../lib/lib';
import { IVuConnection, IVuConnectionSimulator } from './vu-connection.interfaces';
import { LoggingService } from '../../logging/logging.service';
import { ILog } from '../../logging/log.interface';
import { CardDispenserActionResult } from '../../../lib/rfid-card/card-dispenser-action-result';
import { LanguageService } from '../../language.service';
import { CreditCardTerminalEvent } from '../../../lib/credit-card/credit-card-terminal-event';
import { CIOBoardEvent } from '../../../lib/cioboard/coiboard-event';
import { BluecodeEvent } from '../../../lib/bluecode/bluecode-event';
import { GiftCardEvent } from 'src/app/lib/gift-card/gift-card-event';

@Injectable()
export abstract class VuConnectionBase implements IVuConnection, IVuConnectionSimulator {
  navigationUrlChanged: EventEmitter<string> = new EventEmitter(true);
  eventConnectionChanged: EventEmitter<boolean> = new EventEmitter(true);
  eventBarcodeRead = new EventEmitter<string>(true);
  eventBarcodeReadStarted = new EventEmitter<any>();
  eventRfidCardRead = new EventEmitter<string>(true);
  eventMoneyChanged = new EventEmitter<Money>(true);
  eventReturnAmountFinished = new EventEmitter<any>();
  eventReturnChangeFinished = new EventEmitter<any>();
  eventOrderSaveResultReceived = new EventEmitter<OrderSaveResult>(true);
  eventResetOrderRfidCardAccessDataResultReceived = new EventEmitter<OrderSaveResult>(true);
  eventPrintTaskResultReceived = new EventEmitter<PrintTaskResult>(true);
  eventPrintTicketRemoved = new EventEmitter<PrintTaskResult>(true);
  eventVuStateChanged = new EventEmitter<VuState>(true);
  eventVuConfigurationChanged = new EventEmitter<DtoVuConfiguration>(true);
  eventOnTurnstileEnter = new EventEmitter<any>();
  eventOnTicketRemoved = new EventEmitter<any>();
  eventOnInvalidRfidCardInserted = new EventEmitter<any>();
  eventCreditCardTerminalEvent = new EventEmitter<CreditCardTerminalEvent>();
  eventCreditCardTerminalAvailabilityChanged = new EventEmitter<boolean>();
  eventBillAcceptorAvailabilityChanged = new EventEmitter<boolean>();
  eventSmartHopperAvailabilityChanged = new EventEmitter<boolean>();
  eventBluecodeEvent = new EventEmitter<BluecodeEvent>();
  eventGiftCardEvent = new EventEmitter<GiftCardEvent>();
  eventCashlessEvent = new EventEmitter<boolean>();
  eventGateTransactionBegin = new EventEmitter<boolean>();
  eventGateTransactionEnd = new EventEmitter<boolean>();
  eventBnaValidationStarted = new EventEmitter<any>();
  eventBnaValidationFinished = new EventEmitter<any>();
  eventCIOBoardStateChanged = new EventEmitter<CIOBoardEvent>();
  eventRfidCardProduced = new EventEmitter<CardDispenserActionResult>(true);
  eventRfidCardReleased = new EventEmitter<CardDispenserActionResult>(true);
  eventRfidCardTaken = new EventEmitter<CardDispenserActionResult>(true);
  eventRfidCardCaptured = new EventEmitter<CardDispenserActionResult>(true);
  eventRfidCardRemoved = new EventEmitter<CardDispenserActionResult>(true);
  eventWriteCardDataCompleted = new EventEmitter<CardDispenserActionResult>(true);
  eventReadCardDataCompleted = new EventEmitter<CardDispenserActionResult>(true);
  eventRfidCardTransactionStarted = new EventEmitter<CardDispenserActionResult>(true);
  eventRfidCardAvailabilityChanged = new EventEmitter<CardDispenserActionResult>(true);
  eventMoneyExchangePayin: EventEmitter<Money> = new EventEmitter<Money>(true);
  eventMoneyExchangePayout: EventEmitter<Money> = new EventEmitter<Money>(true);
  eventMoneyExchangeReadyToMoneyExchange: EventEmitter<any> = new EventEmitter<any>(true);
  eventMoneyExchangePayoutFinish: EventEmitter<any> = new EventEmitter<any>(true);
  eventSvgInvalidMoneyInserted: EventEmitter<boolean> = new EventEmitter<boolean>();
  eventAllCoinsNotAccepted: EventEmitter<boolean> = new EventEmitter<boolean>();
  eventAllBillsNotAccepted: EventEmitter<boolean> = new EventEmitter<boolean>();

  isConneсted: boolean;
  private _loggingService: LoggingService;
  private languageService: LanguageService;

  constructor(
    protected injector: Injector,
  ) {
    this.init();
  }

  protected init(): void {
    this.languageService = this.injector.get(LanguageService);
  }

  protected get log(): ILog {
    if (this._loggingService == null) {
      this._loggingService = this.injector.get(LoggingService);
    }
    return this._loggingService;
  }

  barcodeRead(barcode: string): void {
    this.log.info(`${this.constructor.name}. readBarcode. ${barcode}`);
    this.eventBarcodeRead.emit(barcode);
  }

  barcodeReadStarted(): void {
    this.log.info(`${this.constructor.name}. barcodeReadStarted.`);
    this.eventBarcodeReadStarted.emit();
  }

  rfidCardRead(rfidCardCode: string): void {
    this.log.info(`${this.constructor.name}. readRfidCard. ${rfidCardCode}`);
    this.eventRfidCardRead.emit(rfidCardCode);
  }

  scanTicket(ticket: Ticket): void {
    this.log.info(`${this.constructor.name}. scanTicket. ${ticket}`);
  }

  moneyChanged(cash: Money): void {
    this.log.info(`${this.constructor.name}. moneyChanged. ${cash}`);
  }

  returnAmountFinished(): void {
    this.log.info(`${this.constructor.name}. returnAmountFinished.`);
    this.eventReturnAmountFinished.emit();
  }

  returnChangeFinished(): void {
    this.log.info(`${this.constructor.name}. returnChangeFinished.`);
    this.eventReturnChangeFinished.emit();
  }

  orderSaveResultReceived(result: OrderSaveResult): void {
    this.log.info(`${this.constructor.name}. orderSaveResultReceived. ${result}`);
  }

  printTaskResultReceived(result: PrintTaskResult): void {
    this.log.info(`${this.constructor.name}. printTaskResultReceived. ${result}`);
  }

  printTicketRemoved(result: PrintTaskResult): void {
    this.log.info(`${this.constructor.name}. printTicketRemoved. ${result}`);
  }

  printReceiptResultReceived(result: boolean): void {
    this.log.info(`${this.constructor.name}. printTicketsResultReceived. ${result}`);
  }

  connectionChanged(isConnected: boolean): void {
    this.eventConnectionChanged.emit(isConnected);
    this.log.info(`${this.constructor.name}. connectionChanged. ${isConnected}`);
  }

  vuConfigurationChanged(): void {
    this.log.info(`${this.constructor.name}. vuConfigurationChanged.`);
    this.eventVuConfigurationChanged.emit();
  }

  configurationChanged(configuration: Configuration): void {
    const dtoVuConfiguration = DtoVuConfiguration.fromOther(configuration);
    dtoVuConfiguration.locale = this.languageService.defaultLanguageId;
    this.vuConfigurationChanged();
  }

  isUseProductionApi(x: boolean): void {
  }

  onTurnstileEnter(): void {
    this.log.info(`${this.constructor.name}. onTurnstileEnter.`);
    this.eventOnTurnstileEnter.emit();
  }

  rfidCardProduced(result: CardDispenserActionResult): void {
    const actionResult = CardDispenserActionResult.fromJson(result);
    this.log.info(`${this.constructor.name}. rfidCardProduced. ${actionResult}`);
    this.eventRfidCardProduced.emit(actionResult);
  }

  rfidCardReleased(result: CardDispenserActionResult): void {
    const actionResult = CardDispenserActionResult.fromJson(result);
    this.log.info(`${this.constructor.name}. rfidCardReleased. ${actionResult}`);
    this.eventRfidCardReleased.emit(actionResult);
  }

  rfidCardTaken(result: CardDispenserActionResult): void {
    const actionResult = CardDispenserActionResult.fromJson(result);
    this.log.info(`${this.constructor.name}. rfidCardTaken. ${actionResult}`);
    this.eventRfidCardTaken.emit(actionResult);
  }

  rfidCardCaptured(result: CardDispenserActionResult): void {
    const actionResult = CardDispenserActionResult.fromJson(result);
    this.log.info(`${this.constructor.name}. rfidCardCaptured. ${actionResult}`);
    this.eventRfidCardCaptured.emit(actionResult);
  }

  rfidCardRemoved(result: CardDispenserActionResult): void {
    const actionResult = CardDispenserActionResult.fromJson(result);
    this.log.info(`${this.constructor.name}. rfidCardRemoved. ${actionResult}`);
    this.eventRfidCardRemoved.emit(actionResult);
  }

  writeCardDataCompleted(result: CardDispenserActionResult): void {
    const actionResult = CardDispenserActionResult.fromJson(result);
    this.log.info(`${this.constructor.name}. writeCardDataCompleted. ${actionResult}`);
    this.eventWriteCardDataCompleted.emit(actionResult);
  }

  readCardDataCompleted(result: CardDispenserActionResult): void {
    const actionResult = CardDispenserActionResult.fromJson(result);
    this.log.info(`${this.constructor.name}. readCardDataCompleted. ${actionResult}`);
    this.eventReadCardDataCompleted.emit(actionResult);
  }

  rfidCardTransactionStarted(result: CardDispenserActionResult): void {
    const actionResult = CardDispenserActionResult.fromJson(result);
    this.log.info(`${this.constructor.name}. rfidCardTransactionStarted. ${actionResult}`);
    this.eventRfidCardTransactionStarted.emit(actionResult);
  }

  rfidCardAvailabilityChanged(result: CardDispenserActionResult): void {
    const actionResult = CardDispenserActionResult.fromJson(result);
    this.log.info(`${this.constructor.name}. rfidCardAvailabilityChanged. ${actionResult}`);
    this.eventRfidCardAvailabilityChanged.emit(actionResult);
  }

  onTicketRemoved(): void {
    this.log.info(`${this.constructor.name}. onTicketRemoved.`);
    this.eventOnTicketRemoved.emit();
  }

  onInvalidRfidCardInserted(): void {
    this.log.info(`${this.constructor.name}. onInvalidRfidCardInserted.`);
    this.eventOnInvalidRfidCardInserted.emit();
  }

  onCreditCardTerminalEvent(x: CreditCardTerminalEvent): void {
    this.log.info(`${this.constructor.name}. onCreditCardTerminalEvent(). ${x}`);
    this.eventCreditCardTerminalEvent.emit(x);
  }

  onCreditCardTerminalAvailabilityChanged(isAvailable: boolean): void {
    this.log.info(`${this.constructor.name}. onCreditCardTerminalAvailabilityChanged().isAvailable: ${isAvailable}`);
    this.eventCreditCardTerminalAvailabilityChanged.emit(isAvailable);
  }

  onBillAcceptorAvailabilityChanged(isAvailable: boolean): void {
    this.log.info(`${this.constructor.name}. onBillAcceptorAvailabilityChanged().isAvailable: ${isAvailable}`);
    this.eventBillAcceptorAvailabilityChanged.emit(isAvailable);
  }

  onSmartHopperAvailabilityChanged(isAvailable: boolean): void {
    this.log.info(`${this.constructor.name}. onSmartHopperAvailabilityChanged().isAvailable: ${isAvailable}`);
    this.eventSmartHopperAvailabilityChanged.emit(isAvailable);
  }

  onBluecodeEvent(x: BluecodeEvent): void {
    this.log.info(`${this.constructor.name}. onBluecodeEvent(). ${x}`);
    this.eventBluecodeEvent.emit(x);
  }

  onGiftCardEvent(x: GiftCardEvent): void {
    this.log.info(`${this.constructor.name}. onGiftCardEvent(). ${x}`);
    this.eventGiftCardEvent.emit(x);
  }

  onCashlessEvent(x: boolean): void {
    this.log.info(`${this.constructor.name}. onCashlessEvent(). ${x}`);
    this.eventCashlessEvent.emit(x);
  }

  onGateTransactionBegin(x: boolean): void {
    this.log.info(`${this.constructor.name}. onEventGateTransactionBegin(). ${x}`);
    this.eventGateTransactionBegin.emit(x);
  }

  onGateTransactionEnd(x: boolean): void {
    this.log.info(`${this.constructor.name}. onEventGateTransactionEnd(). ${x}`);
    this.eventGateTransactionEnd.emit(x);
  }

  onBnaValidationStarted(): void {
    this.log.info(`${this.constructor.name}. onBnaValidationStarted().`);
    this.eventBnaValidationStarted.emit();
  }

  onBnaValidationFinished(): void {
    this.log.info(`${this.constructor.name}. onBnaValidationFinished().`);
    this.eventBnaValidationFinished.emit();
  }

  onCIOBoardStateChanged(result: CIOBoardEvent): void {
    this.log.info(`${this.constructor.name}. onCIOBoardStateChanged().`);
    this.eventCIOBoardStateChanged.emit(result);
  }

  moneyExchangeReadyToMoneyExchange(): void {
    this.log.info(`moneyExchangeReadyToMoneyExchange event`);
    this.eventMoneyExchangeReadyToMoneyExchange.emit();
  }

  moneyExchangePayoutFinish() {
    this.log.info(`moneyExchangePayoutNotesFinish event`);
    this.eventMoneyExchangePayoutFinish.emit();
  }

  onSvgInvalidMoneyInsertedEvent(x: any): void {
    this.log.info(`${this.constructor.name}. onSvgInvalidMoneyInsertedEvent(). ${x}`);
    this.eventSvgInvalidMoneyInserted.emit(true);
  }

  onAllCoinsNotAcceptedEvent(x: any): void {
    this.log.info(`${this.constructor.name}. onAllCoinsNotAcceptedEvent(). ${x}`);
    this.eventAllCoinsNotAccepted.emit(true);
  }

  onAllBillsNotAcceptedEvent(x: any): void {
    this.log.info(`${this.constructor.name}. onAllBillsNotAcceptedEvent(). ${x}`);
    this.eventAllBillsNotAccepted.emit(true);
  }
}
