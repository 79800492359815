import { Injectable, Injector } from "@angular/core";
import { Observable } from "rxjs";
import { Issue } from "../../lib/issue/issue";
import { VuCommunicationService } from "../vu/vu-communication.service";


@Injectable()
export class IssueService {

  private vuCommunicationService: VuCommunicationService;

  constructor(
    private injector: Injector,
  ) {
    this.vuCommunicationService = this.injector.get(VuCommunicationService);
  }

  public createIssue(description: string, category?: string, data?: any, log?: string): Observable<number> {
    const issue = new Issue();
    issue.date = new Date();
    if (description) {
      issue.description = description;
    }
    if (category) {
      issue.category = category;
    }
    if (data) {
      issue.json_data = JSON.stringify(data);
    }
    if (log) {
      issue.log= log;
    }
    return this.vuCommunicationService.vuHttp.createIssue(issue);
  }
}
