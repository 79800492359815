import { Component } from '@angular/core';
import { MachineSaleShopService } from '../../../services/machines/machine-sale.service';
import { ConfigurationService } from '../../../services/configuration/configuration.service';
import { StoreHistoryService } from 'src/app/services/store-history.service';
import { DispatcherService } from 'src/app/services/dispatcher.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-card-detail-information',
  templateUrl: './card-detail-information.component.html',
  styleUrls: ['./card-detail-information.component.css'],
})
export class CardDetailInformationButtonComponent {
  constructor(
    private dispatcherService: DispatcherService,
    private configurationService: ConfigurationService,
    private machineSaleShopService: MachineSaleShopService,
    private storeHistoryService: StoreHistoryService,
    private router: Router,
  ) {
  }

  get visible(): boolean {
    const state = this.machineSaleShopService.state;
    return state && state === 'displayMode'
      && this.storeHistoryService.isEmpty;
  }

  onClick(): void {
    this.router.navigateByUrl('/card-detail-information');
    // this.dispatcherService.onButtonCustomSaleShopClick({
    //   shopUrl: '/card-dispenser-restore-card',
    // });
  }
}
