export enum PostSaleLiteTypeEnum {
  PrintCreditCardReceipt = 'PrintCreditCardReceipt',
  WaitingForMoney = 'WaitingForMoney',
  SaveOrder = 'SaveOrder',
  CheckExitRole = 'CheckExitRole',
  PrintTickets = 'PrintTickets',
  PrintReceipt = 'PrintReceipt',
  WaitingForTurnstileEnter = 'WaitingForTurnstileEnter',
  OpenGate = 'OpenGate',
  TakePrintedStuff = 'TakePrintedStuff',
}
