<div class="parking-date-container">
  <input type="text" placeholder="Datepicker" class="form-control" [bsValue]="value"
    (bsValueChange)="onBsValueChange($event)" placement="left" [minDate]="minDate"
    [maxDate]="maxDate" bsDatepicker>
  <timepicker #timepicker [(ngModel)]="timeValue" [showMeridian]="isShowMeridian" [minuteStep]="minuteStep" (mousedown)="onMouseDown($event)"
    (touchstart)="onTouchStart($event)"
    (touchend)="onMouseUp($event)"
    (mouseup)="onMouseUp($event)" (mouseleave)="onMouseUp($event)"
    [max]="maxTimeValue"
    appAutofocus>
  </timepicker>
</div>
