export class LostCardConfiguration {

  restoreCardAllowed: boolean;
  informationMessage: string;

  static createFromAny(data: any): LostCardConfiguration {
    if (!data) {
      return null;
    }

    const lostCardConfiguration = new LostCardConfiguration();
    lostCardConfiguration.restoreCardAllowed = data.restore_card_allowed;
    lostCardConfiguration.informationMessage = data.information_message;
    return lostCardConfiguration;
  }

  toString(): string {
    let m = `restoreCardAllowed: ${this.restoreCardAllowed} `;
    m += `informationMessage: ${this.informationMessage}. `;
    return m;
  }
}
