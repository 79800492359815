import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { DisplayItem } from '../../../../lib/display/display-item/display-item';
import { LanguageService } from '../../../../services/language.service';
import { DisplayGridWorkflowService } from '../../../../services/display/grid/display-grid-workflow.service';
import { Money, GlobalSettings, Product } from '../../../../lib/lib';

@Component({
  selector: 'app-payment-amount-input-base',
  templateUrl: './payment-amount-input-base.component.html',
  styleUrls: ['./payment-amount-input-base.component.css']
})
export class PaymentAmountInputBaseComponent implements OnInit {

  @Input()
  displayItem: DisplayItem;

  @Input()
  title: string;

  @Output()
  componentClick: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    protected languageService: LanguageService,
    protected displayGridWorkflowService: DisplayGridWorkflowService,
  ) {
  }

  ngOnInit() {
  }

  get language(): string {
    return this.languageService.odooLanguageName;
  }

  get minAmount(): Money {
    return this.displayItem && this.displayItem.minAmount ? new Money(this.displayItem.minAmount, GlobalSettings.getCurrencyCode()) : null;
  }

  get maxAmount(): Money {
    return this.displayItem && this.displayItem.maxAmount ? new Money(this.displayItem.maxAmount, GlobalSettings.getCurrencyCode()) : null;
  }

  get product(): Product {
    return this.displayItem ? this.displayItem.product : null;
  }

  onItemClick() {
    if (this.displayItem != null && this.displayItem != null) {
      this.componentClick.emit(this.displayItem);
    }
  }

  setName(item: DisplayItem) {
    return item.name + ` ${item.sizeHorizontal - item.posX}x` + `${item.sizeVertical - item.posY}`;
  }

  get isVirtualKeyboardVisible(): boolean {
    return true;
  }

  onEnteredText(text: string) {
    this.onAmountChanged(text);
  }

  onTextChanged(text: string) {
    this.onAmountChanged(text);
  }

  protected onAmountChanged(text: string) {
  }

  validateAmount(amount: number) {
    if (this.minAmount != null && amount < this.minAmount.value) {
      return false;
    }
    if (this.maxAmount != null && amount > this.maxAmount.value) {
      return false;
    }
    return true;
  }

  renderHtmlTemplate(displayItem: DisplayItem): string {
    return this.displayGridWorkflowService.getformattedHtmlTemplate(displayItem, this.language);
  }
}
