import { Component, OnDestroy, OnInit } from '@angular/core';
import { BaseModalComponent } from '../../general/base/base-modal-component';

@Component({
  selector: 'app-timeout-simple-modal',
  templateUrl: './timeout-simple-modal.component.html',
  styleUrls: ['./timeout-simple-modal.component.css']
})
export class TimeoutSimpleModalComponent extends BaseModalComponent implements OnInit, OnDestroy {

  private interval: any;

  title = '';

  timeoutLess = 0;

  ngOnInit(): void {
    this.timeoutLess = 0;
    this.startTimer();
  }

  ngOnDestroy(): void {
    this.stopTimer();
  }

  get message(): string {
    if (this.internalData && this.internalData.message) {
      return this.internalData.message;
    }
    return 'Time left';
  }

  get timeout(): number {
    if (!this.internalData || !this.internalData.autoCloseTimeout) {
      return 0;
    }
    const autoCloseTimeoutSec = this.internalData.autoCloseTimeout / 1000;
    return autoCloseTimeoutSec - this.timeoutLess;
  }

  private startTimer(): void {
    this.stopTimer();
    this.interval = setInterval(() => {
      this.timeoutLess += 1;
      if (this.timeout < 0) {
        this.stopTimer();
      }
    }, 1000);
  }

  private stopTimer(): void {
    if (this.interval) {
      clearInterval(this.interval);
      this.interval = null;
    }
  }

}
