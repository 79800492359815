<div class="modal-header">
  <div class="modal-title normal-text pull-left">{{ 'The screen inactivity is detected' | translate }}</div>
</div>
<div class="modal-body">
  <div class="normal-text">{{ 'Please press "Continue" if you want to continue.' | translate }}</div>
  <h1 *ngIf="timeout > 0">{{ timeout }}</h1>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-success pull-left button-base button-medium" (click)="close(true)">
    {{ 'Continue' | translate }}
  </button>
  <button type="button" class="btn btn-warning pull-right button-base button-medium" (click)="close(false)">
    {{ 'Cancel and go to back to main page' | translate }}
  </button>
</div>
