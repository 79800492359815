import { Injectable, Injector } from '@angular/core';
import { Subject } from 'rxjs';
import { VuState, NonOperationalState } from '../lib/lib';
import { DispatcherService } from './dispatcher.service';
import { LoggingService } from './logging/logging.service';
import { ScreenSaverService } from './screen-saver.service';


@Injectable()
export class NonOperationalService {
  private iState: NonOperationalState;
  protected dispatcherService: DispatcherService;
  protected log: LoggingService;
  eventStateChanged: Subject<NonOperationalState> = new Subject();

  private screenSaverService: ScreenSaverService;

  private isInitialized: boolean;

  private reconnectTimeoutRef: any;

  constructor(
    protected injector: Injector,
  ) {
    this.dispatcherService = this.injector.get(DispatcherService);
    this.log = this.injector.get(LoggingService);
    this.dispatcherService.eventServiceInitialized.subscribe(() => this.onDispatcherServiceInitialized());
    this.screenSaverService = this.injector.get(ScreenSaverService);
  }

  private onDispatcherServiceInitialized(): void {
    this.dispatcherService.onVuStateChangedSubscribe(x => this.onVuStateChanged(x));
    this.dispatcherService.vuConnection.eventConnectionChanged.subscribe((isConnected: boolean) => {
      this.checkVuState(isConnected);
    });

    this.checkVuState(this.dispatcherService.vuConnection.isConneсted);
  }

  private checkVuState(isConnected: boolean): void {
    if (this.reconnectTimeoutRef) {
      clearTimeout(this.reconnectTimeoutRef);
      this.reconnectTimeoutRef = null;
    }
    const prevIsConnected = this.state.isConnected;
    this.state = this.state.fromConnected(isConnected);
    if (isConnected) {
      if (prevIsConnected !== isConnected || !this.isInitialized) {
        this.state.vuState.isLoadingMode = true;
        this.dispatcherService.vuHttp.getVuState()
          .then((state: VuState) => {
            this.isInitialized = true;
            state.isLoadingMode = false;
            this.dispatcherService.vuStateSet(state);
          })
          .catch(err => {
            this.log.info(`checkVuState. ${err}`);
            this.reconnectTimeoutRef = setTimeout(() => {
              this.checkVuState(isConnected);
            }, 10 * 1000);
          });
      }
    } else {
      this.isInitialized = false;
    }
  }

  get state(): NonOperationalState {
    if (this.iState == null) {
      this.iState = new NonOperationalState(new VuState());
    }
    return this.iState;
  }

  set state(value: NonOperationalState) {
    if (this.iState === value) {
      return;
    }

    if (this.screenSaverService.configuration?.hideScreenSaverInOutOfOrderMode) {
      this.screenSaverService.forceStopShow();
    }

    this.iState = value;
    this.log.info(`NonOperationalService. ${value}`);
    this.eventStateChanged.next(value);
  }

  private onVuStateChanged(vuState: VuState): void {
    this.state = this.state.fromVuState(vuState);
  }
}
