<div class="workflow normal-text">
  <div class="scroll scrollbar">
    <table>
      <tbody>
        <tr *ngFor="let x of workflow.steps">
          <td width="95%">{{x.text}}</td>
          <td width="5%">
            <workflow-step-state [state]="x.state"></workflow-step-state>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <teaser>
  </teaser>
</div>
