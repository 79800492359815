import { Component, OnInit, OnDestroy, Injector } from '@angular/core';
import { MachineSaleCashlessService } from 'src/app/services/machines/machine-sale-cashless.service';
import { PaymentMethod } from 'src/app/lib/lib';

@Component({
  moduleId: module.id,
  selector: 'app-payment-cashless',
  templateUrl: 'payment-cashless.component.html',
  styleUrls: ['payment-cashless.component.scss']
})
export class PaymentCashlessComponent {

  constructor(
    private machineSaleCashlessService: MachineSaleCashlessService,
  ) {
  }

  get showPaymentCardComponent(): boolean {
    return this.machineSaleCashlessService.paymentMethod === PaymentMethod.PaymentCard;
  }

  get showPaymentBluecodeComponent(): boolean {
    return this.machineSaleCashlessService.paymentMethod === PaymentMethod.Bluecode;
  }

  get showGiftCardPaymentComponent(): boolean {
    return this.machineSaleCashlessService.paymentMethod === PaymentMethod.GiftCard;
  }
}
