<div class="grid-cell-container-middle container-pinpad">
  <div class="grid-cell-container-inner">
      <div class="normal-text min-max-caption" style="color:black; font-weight:bold">{{ title | translate}} (
          <app-min-max-money *ngIf="minAmount || maxAmount"
          [min]="minAmount"
          [max]="maxAmount"
          [minName]="'Min: '"
          [maxName]="'Max: '">
        </app-min-max-money> ):
      </div>
      <div class="normal-text caption-pinpad" [innerHTML]="renderHtmlTemplate(displayItem) | safeHtml"></div>
      <cash-keyboard (onEnteredText)="onEnteredText($event)" (onTextChanged)="onTextChanged($event)" [hidden]="!isVirtualKeyboardVisible"
      [isDisabled]="false" [textLength]="displayItem.pinLength" [limitLength]="true" [min]="minAmount" [max]="maxAmount"></cash-keyboard>
  </div>
</div>

