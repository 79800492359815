import { Component } from '@angular/core';
import { MessageModalComponent } from '../message-modal/message-modal.component';

@Component({
  selector: 'app-confirmation-modal',
  templateUrl: './confirmation-modal.component.html',
  styleUrls: ['./confirmation-modal.component.css']
})
export class ConfirmationModalComponent extends MessageModalComponent {
  get titles(): string[] {
    return this.data && this.data.titles ? this.data.titles : [];
  }
}
